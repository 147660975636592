import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {HomestateService} from '../../../services/homestate.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {
    ConsumptionAlertChartComponent
} from '../../../charts/consumption-alert-chart/consumption-alert-chart.component';
import {of} from 'rxjs';
import {mergeMap, skip} from 'rxjs/operators';
import * as moment from 'moment';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {InstantaneousService} from '../../../services/instantaneous.service';
import {ConsumptionAlertTileTexts} from '../../../shared/texts/tiles/consumption-alert-tile.texts';
import {PowerValue} from '../../live/live-tile/live-tile.component';
import {HttpErrorResponse} from '@angular/common/http';
import {MetaResponse} from '../../../shared/interfaces/meta-response';
import {ApplicationService} from '../../../services/application.service';
import {AnimationOptions} from 'ngx-lottie';

@Component({
    selector: 'app-consumption-alert-tile',
    templateUrl: './consumption-alert-tile.component.html',
    styleUrls: ['./consumption-alert-tile.component.scss'],
    providers: [Globals]
})

export class ConsumptionAlertTileComponent extends BaseComponent implements OnInit, AfterViewInit {
    private readonly type: TILE_TYPE = TILE_TYPE.CONSUMPTION_ALERT;
    private alertChart: ConsumptionAlertChartComponent = null;
    private powerValue: number = null;
    private chartInitialized = false;
    private lastFetchedDataset: PowerValue[] = null;

    readonly TEXTS = ConsumptionAlertTileTexts;

    currentZone: 'lower' | 'higher' = 'lower';
    showPowerInfo = false;
    powerText = '';
    currentMaxValue = 0;

    showEmptyState = true;

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/empty-states/consumption-alert.json',
        renderer: 'svg',
        autoplay: true,
        // autoplaySegments: false,
        loop: true,
        name: 'Verbrauchsalarm '
    };

    constructor(private globals: Globals,
                private homestate: HomestateService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private instantaneous: InstantaneousService,
                private application: ApplicationService) {
        super();
    }


    ngOnInit() {
    }


    ngAfterViewInit() {
        this.getSevenDayHistoricalData();
        this.initializeHomeStateUpdate();
    }


    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * Chart loaded callback
     * @param chart chart instance
     */
    onChartLoaded(chart: ConsumptionAlertChartComponent): void {
        this.alertChart = chart;
        this.chartInitialized = true;
        this.updateChart();
    }


    /**
     * Request historical data for the last seven days
     */
    private getSevenDayHistoricalData(): void {
        this.instantaneous.onConsumptionAlertDataUpdate.pipe(
            skip(1),
            mergeMap((results: MetaResponse) => {
                if (results.status === 'error') {
                    this.handleInstantaneousErrors(results.data);
                    return of(null);
                } else {
                    return of(results.data.results);
                }
            })
        ).subscribe({
            next: (result) => {
                try {
                    this.extractSeriesFromDataset(result);
                    if (this.chartInitialized) {
                        this.updateChart();
                    }
                    this.showEmptyState = false;
                } catch (error) {
                    if (this.chartInitialized) {
                        this.alertChart.showLoadingState();
                    }
                }
            }
        });
        this.instantaneous.startConsumptionAlertValueLiveUpdate(6);
    }


    /**
     * Extract current series data from cached dataset
     */
    private extractSeriesFromDataset(dataset: any): void {
        const start = moment().subtract(1, 'days').toDate();
        const end = moment().subtract(0, 'days').toDate();
        this.lastFetchedDataset = dataset.filter(el => {
            const ts = moment(el.timestamp).toDate();
            return ts >= start && ts <= end;
        });
    }


    /**
     * Update the chart with the last stored data
     */
    private updateChart(): void {
        this.alertChart.showLoadingState(false);
        this.alertChart.addNewSeries(
            this.lastFetchedDataset,
            'power',
            1,
            0
        );
        // generate day change plotlines
        const time = moment(this.lastFetchedDataset.first().timestamp).endOf('day');
        this.alertChart.insertVerticalLine(time.toDate());

        // insert max value line
        this.alertChart.updateMaxValueLine(this.currentMaxValue, false);
    }


    /**
     * Handle instantaneous data error
     * @param error
     */
    private handleInstantaneousErrors(error: HttpErrorResponse): void {
        try {
            if (error.error.error.code === 290) {
                this.showEmptyState = true;
            }
            this.chartInitialized = false;
        } catch (error) {
            console.log('Error seems to be empty');
        }
    }


    /**
     * Initialize homestate-status updates
     */
    private initializeHomeStateUpdate(): void {
        const s = this.homestate.onHomestateInfo.subscribe({
            next: (result) => {
                if (!result) {
                    return;
                }
                this.currentMaxValue = result.config.thresholds.sort((a, b) => a - b).last();
                this.handleHomeStateConfig(result.config);
                if (this.chartInitialized) {
                    this.alertChart.updateMaxValueLine(this.currentMaxValue, false);
                }
            },
        });
        this.addSub(s);
        this.homestate.startLiveUpdateForBundledInfo();
    }


    /**
     * Handle homestate-config data
     * @param config
     */
    private handleHomeStateConfig(config: any): void {
        try {
            if (config.thresholds.length < 1) {
                this.powerText = `${0} Watt`;
                this.powerValue = 0;
            } else {
                this.powerText = `${Math.round(config.thresholds.reduce(
                    (a, b) => Math.max(a, b))).toLocaleString('de')} Watt`;
                this.powerValue = Math.round(config.thresholds
                    .reduce((a, b) => Math.max(a, b)));
            }
            this.showPowerInfo = true;
        } catch (e) {
            console.log('Error:', e);
        }
    }


    /**
     * Determine the current consumption zone
     * @param currentValue
     */
    private updateComparison(currentValue): void {
        if (this.powerText) {
            this.currentZone = currentValue < this.powerValue ? 'lower' : 'higher';
        }
    }


    /**
     * On detail entered
     */
    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    /*
     * TRACKING
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Consumption-Alert'
            }
        });
    }

    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Consumption-Details'
            }
        });
    }

}
