<div class="overlay-minimal">
    <div class="overlay-container-cdk">
        <h1 class="font-medium">Firmware Update</h1>
        <p class="font-regular">
            Ihr Optischer Ausleser benötigt ein Update. Dieses ist notwendig, um eine stabile Verbindung zu Ihrem Zähler
            zu gewährleisten und kann bis zu 60 Minuten in Anspruch nehmen. Bitte lassen Sie in dieser Zeit sowohl den
            Stecker als auch den Optischen Ausleser verbunden.
        </p>
        <div class="center-contents">
            <div class="eon-logo-animated"></div>
        </div>
        <div class="center-contents font-regular">
            {{currentProgress}} / 100
        </div>
        <div *ngIf="env.isStaging" class="center-contents m-t-x">
            <button class="iona-button orange"
                    (click)="onLogoutButtonClick()">
                Abmelden
            </button>
        </div>
    </div>
</div>
