import {Injectable} from '@angular/core';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';

@Injectable({
    providedIn: 'root'
})
export class AccountRewriteService {
    readonly userToRewrite = 'eonstele02a@energy-i.com';
    private readonly verbose = false;

    constructor() {
    }

    setAccountRewriteState(state: boolean): void {
        if (state) {
            localStorage.setItem(
                StorageAttributes.ACCOUNT_REWRITE_ENABLED,
                state ? '1' : '0'
            );
            return;
        }
        localStorage.removeItem(StorageAttributes.ACCOUNT_REWRITE_ENABLED);
    }


    accountRewriteEnabled(): boolean {
        try {
            const storedAttribute = localStorage.getItem(
                StorageAttributes.ACCOUNT_REWRITE_ENABLED
            );
            if (this.verbose) {
                console.log('Account rewriting enabled');
            }
            return storedAttribute === '1';
        } catch (e) {
            return false;
        }
    }
}
