import {PopoverRef} from '../popovers/popover/popover-ref';
import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

// TODO: Add Angular decorator.
@Component({
    selector: 'base-popover',
    template: ''
})
export class BasePopover implements OnDestroy {
    protected subscriptions: Subscription[] = [];
    public headerColor = '#fff';

    constructor(protected popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnDestroy(): void {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    addSub(s: Subscription): void {
        this.subscriptions.push(s);
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    private initializePopoverData(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
        if (this.popoverRef.data.color) {
            this.headerColor = this.popoverRef.data.color;
        }
    }

    protected unsubscribeAndDelete(s: Subscription): null {
        if (s) {
            s.unsubscribe();
        }
        return null;
    }
}
