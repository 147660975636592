import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {NguCarousel, NguCarouselConfig} from '@ngu/carousel';
import {UserService} from '../../../services/user.service';
import {PowercheckerStateService} from '../../../services/powerchecker-state.service';
import {PowerCheckerService} from '../../../services/powerchecker.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';

@Component({
    selector: 'app-powerchecker-details',
    templateUrl: './powerchecker-details.component.html',
    styleUrls: ['./powerchecker-details.component.scss'],
})

export class PowerCheckerDetailsComponent extends BasePopover implements OnInit {

    title = '';
    location = '';
    editModeEnabled = false;
    links = [
        'https://play.google.com/store/apps/details?id=com.innogy.smarthome&hl=de',
        'https://apps.apple.com/de/app/innogy-smarthome/id1133163302'
    ];

    plugAttributes = {
        name: '',
        room: ''
    };

    plugName = 'Mein Gerät';
    plugRoom = 'Mein Zimmer';

    state = false;

    slideshowAnimations = [
        {
            path: 'assets/anim/powerchecker/powerchecker_1.json',
            renderer: 'svg',
            autoplay: true,
            // autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/powerchecker/powerchecker_2.json',
            renderer: 'svg',
            autoplay: true,
            // autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/powerchecker/powerchecker_3.json',
            renderer: 'svg',
            autoplay: true,
            // autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/powerchecker/powerchecker_4.json',
            renderer: 'svg',
            autoplay: true,
            // autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
    ];

    carouselTileConfig: NguCarouselConfig = {
        grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
        speed: 300,
        point: {visible: true},
        touch: true,
        loop: true,
        animation: 'lazy',
        interval: {
            timing: 10000,
            initialDelay: 10000
        },
        load: 4
    };

    infoVisible = false;

    @ViewChild('carousel', {static: true}) carousel: NguCarousel<any>;

    @ViewChild('room') roomInputField: ElementRef;
    @ViewChild('name') nameInputField: ElementRef;

    constructor(public _apiService: ApiService,
                private _mockData: MockDataService,
                private _userService: UserService,
                private powercheckerState: PowercheckerStateService,
                private powerchecker: PowerCheckerService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef) {
        super(popoverRef);
    }

    ngOnInit() {
        this.getPlugAttributes();

        const stateS = this.powercheckerState.stateChange.subscribe(
            (value) => {
                this.state = value;
            }
        );
        this.addSub(stateS);

        if (!this.application.isDemoMode()) {
            this.initializePlugStatus();
            return;
        }

        const s = this._mockData.getPlugStatus().subscribe(
            (response: any) => {
                this.state = response.data.on_off === 1;
                s.unsubscribe();
            }
        );
    }

    /**
     * Initialize the plugs attributes
     */
    private initPlugAttributes(): void {
        this.plugAttributes = this._userService.getPlugAttributes();
        if (this.plugAttributes === null || this.plugAttributes === undefined) {
            this.getPlugAttributes();
            return;
        }

        this.plugName = this.plugAttributes.name;
        this.plugRoom = this.plugAttributes.room;
    }

    /**
     * Get the plugs current set status
     */
    private initializePlugStatus(): void {
        const sub = this.powerchecker.getState().subscribe(
            // const sub = this._apiService.getPlugStatus().subscribe(
            (res) => {
                if (res) {
                    if ('relay' in res) {
                        if ('on_off' in res.relay) {
                            const value = res.relay.on_off;
                            this.state = value === 1;
                        }
                    }
                }
                sub.unsubscribe();
            }
        );
    }

    /**
     * Get plug attributes from user storage
     */
    private getPlugAttributes(): void {
        if (!this.application.isDemoMode()) {
            const s = this._apiService.getStoredUserSettings().subscribe(
                (response) => {
                    if (response) {
                        if ('power_checker' in response) {
                            this._userService.updatePlugAttributes(response.power_checker);
                            this.plugAttributes = response.power_checker;
                            if (this.plugAttributes.name !== '') {
                                this.plugName = this.plugAttributes.name;
                            }
                            if (this.plugAttributes.room !== '') {
                                this.plugRoom = this.plugAttributes.room;
                            }
                        }
                    }
                    s.unsubscribe();
                }
            );
        }
    }

    /**
     * On plug stat changed
     * @param state
     */
    onPlugStateChange(state: boolean): void {
        this.state = state;
        this.powercheckerState.stateChange.next(state);
        if (!this.application.isDemoMode()) {
            const s = this._apiService.setPlugStatus(state ? 1 : 0).subscribe(
                (response) => {
                    if ('data' in response) {
                        if ('relay' in response.data) {
                            if ('on_off' in response.data) {
                                const value = response.data.relay.on_off;
                                this.state = value === 1;
                            }
                        }
                    }
                    s.unsubscribe();
                },
                (error) => {
                    s.unsubscribe();
                }
            );
        }
    }

    /**
     * On saved change
     * @param new_name
     * @param new_room
     */
    onSaveChanges(): void {
        if (!this.editModeEnabled) {
            this.editModeEnabled = true;
            return;
        }

        const new_name = this.nameInputField.nativeElement.value;
        const new_room = this.roomInputField.nativeElement.value;
        if (this.editModeEnabled) {
            this._userService.plugAttributesChanged.next({name: new_name, room: new_room});
            if (this.application.isDemoMode()) {
                if (new_name !== this.plugAttributes.name || new_room !== this.plugAttributes.room) {
                    this.plugName = new_name;
                    this.plugRoom = new_room;
                }
                this.editModeEnabled = !this.editModeEnabled;
                return;
            }
            if (new_name !== this.plugAttributes.name || new_room !== this.plugAttributes.room) {
                this._userService.updatePlugAttributes({name: new_name, room: new_room});
                const s = this._apiService.setPlugAttributes().subscribe(
                    (response) => {
                        s.unsubscribe();
                    },
                    (error) => {
                        console.log('Error:', error);
                        s.unsubscribe();
                    }
                );
            }
        }
        this.editModeEnabled = !this.editModeEnabled;
    }

    onInputFocus(evt: FocusEvent): void {
        const target = evt.target as HTMLInputElement;
        target.select();
    }
}
