import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {InstantaneousService} from '../../../services/instantaneous.service';
import {MeterService} from '../../../services/meter.service';
import {InitializationService} from '../../../services/initialization.service';
import {ElectricityService} from '../../../services/electricity.service';
import {MONTHS} from '../../../lib/DateUtil';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import * as moment from 'moment';
import {
    ConnectionIndicatorConfig
} from '../../../components/connection-indicator/connection-indicator.component';
import {MeterConnectionConfig} from '../../../shared/constants/connection.constants';
import {padNumber} from '../../../lib/Utility';
import {AccountRewriteService} from '../../../services/account-rewrite.service';

@Component({
    selector: 'app-meter-details',
    templateUrl: './meter-details.component.html',
    styleUrls: ['./meter-details.component.scss'],
})

export class MeterDetailsComponent extends BasePopover implements OnInit, OnDestroy {

    readonly connectionConfig: ConnectionIndicatorConfig = MeterConnectionConfig;

    months = MONTHS;

    meterStats = {kwh: '000000', id: null};

    connection_quality = 0;
    meter_connected = false;
    meter_status = 'disconnected';

    foundConsumption = 0;
    selectedConsumption = '------';
    specificDate = false;
    today = moment().format('DD.MM.YYYY');
    private searchDate = null;
    searchDateDisplay = 'TT.MM.JJJJ';

    infoVisible = false;

    private dateFormat = 'DD.MM.YYYY';
    private dateFormatDebug = 'DD.MM.YYYY hh:mm:ss';

    constructor(public _apiService: ApiService,
                private _mockData: MockDataService,
                private instantaneous: InstantaneousService,
                private meter: MeterService,
                private initialization: InitializationService,
                private electricity: ElectricityService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef,
                private accountRewrite: AccountRewriteService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.initializeMeterStatusUpdate();
        this.getCurrentMeterValue();
        this.getMeterInfoFromInit();
        this.searchMeter();
    }

    ngOnDestroy() {
    }


    setDate(event: any) {
        const d = moment(event.target.valueAsNumber);
        this.searchDate = d.toDate();
        this.searchDateDisplay = d.format('DD.MM.YYYY');
        if (this.application.isDemoMode()) {
            this.searchMockMeter();
            return;
        }
        this.searchMeter();
    }

    searchMeter() {
        if (!this.searchDate) {
            return;
        }
        this.specificDate = true;
        const date = moment(this.searchDate).format('YYYY-MM-DDT23:59:59');
        const s = this.electricity.getConsumptionForDate(date).subscribe(
            (res: any) => {
                try {
                    if (!this.specificDate) {
                        this.foundConsumption = Math.round(res.current_summation / 1000);
                        this.specificDate = false;
                    } else {
                        const rounded = Math.round(res.current_summation / 1000);
                        this.selectedConsumption = padNumber(rounded, 6);
                    }
                } catch (error) {
                }
            },
            error => null,
            () => s.unsubscribe()
        );
    }

    loop(start: number, times: number) {
        const loop: number[] = [];
        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }
        return loop;
    }

    formatSerialNumber(serialNumber: string) {
        let returnVal = '';
        if ((serialNumber === null || serialNumber === undefined) || serialNumber.length <= 1) {
            return serialNumber;
        }
        for (let i = 0; i < serialNumber.length; i++) {
            if (i == 1 || i == 4 || i == 6 || i == 10) {
                returnVal += ' ';
            }
            returnVal += serialNumber.charAt(i);
        }
        return returnVal;
    }

    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return 'verbunden';
            default:
                return 'nicht verbunden';
        }
    }

    private getCurrentMeterValue(): void {
        this.meter.getCurrentMeterValue().subscribe((res) => {
            const value = Math.round(res / 1000);
            this.meterStats.kwh = padNumber(value, 6);
        });
    }

    private getMeterInfoFromInit() {
        const s = this.initialization.get(
            true,
            this.accountRewrite.accountRewriteEnabled()
        ).subscribe(
            (res: any) => {
                try {
                    const status = res.profile.meter_status_electricity;
                    if (status === 0) {
                        this.meter_status = 'connected';
                    } else if (status === 1) {
                        this.meter_status = 'disconnected';
                    } else {
                        this.meter_status = 'pending';
                    }
                    this.meter_connected = res.profile.meter_status_electricity === 0;
                    this.meterStats.id = this.formatSerialNumber(res.profile.meter_serial_number);
                } catch (error) {
                }
            },
            (error) => null,
            () => s.unsubscribe()
        );
    }

    private searchMockMeter(): void {
        this.foundConsumption = 0;
        if (!this.searchDate) {
            return;
        }
        const date = this.searchDate;
        const s = this._mockData.getConsumptionForDate(date).subscribe(
            (data: any) => {
                try {
                    this.foundConsumption = Math.round(data.data[0].current_summation / 1000);
                } catch (error) {
                }
            },
            error => null,
            () => s.unsubscribe()
        );
    }

    private initializeMeterStatusUpdate(): void {
        this.meter.startLiveUpdate();
        const meterS = this.meter.onMeterStatus.subscribe(
            (res) => {
                try {
                    this.connection_quality = res.meter_txrssi;
                } catch (error) {
                }
            }
        );
        this.addSub(meterS);
    }
}
