import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {Globals} from '../../../services/globals.service';
import {UserService} from '../../../services/user.service';
import {LiveDataService} from '../../../services/live-data.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {LiveChartComponent} from '../../../charts/live-chart/live-chart.component';
import {ElectricityService} from '../../../services/electricity.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {HomestateService} from '../../../services/homestate.service';
import {OpticalReaderService} from '../../../services/optical-reader.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {of, Subscription, throwError, timer} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {
    RemainingTimeIndicatorComponent
} from '../../../components/remaining-time-indicator/remaining-time-indicator.component';
import {HappyHourService} from '../../../services/happy-hour.service';
import {LiveTileTexts} from '../../../shared/texts/tiles/live-tile.texts';

@Component({
    selector: 'app-live-tile',
    templateUrl: './live-tile.component.html',
    styleUrls: ['./live-tile.component.scss'],
    providers: [Globals]
})

export class LiveTileComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly type: TILE_TYPE = TILE_TYPE.LIVE;
    readonly TEXTS = LiveTileTexts;

    private mockDataIntervalSub: Subscription = null;
    private currentDataset: Array<PowerValue> = [];

    private happyHourFrom = null;
    private happyHourTo = null;

    currentConsumption = 0;
    valueUnit: 'Watt' | 'Wh' = 'Watt';
    status = {trend: 0, noZone: true};

    isNotRealtime = false;
    userHasHappyHour = false;

    regularSeriesColor = '#47A7D8';
    feedinSeriesColor = '#ffc300';
    happyHourSeriesColor = '#143C8C';

    @ViewChild('happyHourIndicator') hhIndicator: RemainingTimeIndicatorComponent;
    @ViewChild('liveChart', {static: true}) liveChart: LiveChartComponent;

    constructor(private globals: Globals,
                private mockDataService: MockDataService,
                private userService: UserService,
                private liveData: LiveDataService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private electricity: ElectricityService,
                private tiles: TileService,
                private homeState: HomestateService,
                private opticalReader: OpticalReaderService,
                private happyHour: HappyHourService) {
        super();
    }

    ngOnInit() {
        this.valueUnit = this.userService.isERNAUser() ? 'Wh' : 'Watt';
        this.liveChart.setUnit(this.valueUnit);
    }

    ngAfterViewInit() {
        this.liveChart.reset();
        if (this.userService.isERNAUser()) {
            this.liveChart.updateZoomLevel(60 * 30 * 6 * 1000, '%H:%M');
        } else {
            this.liveChart.updateZoomLevel(60 * 1000, '%H:%M');
        }
        this.initializeComponent();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.mockDataIntervalSub) {
            this.mockDataIntervalSub.unsubscribe();
            this.mockDataIntervalSub = null;
        }
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    currentConsumptionFormatted(): string {
        return Math.abs(this.currentConsumption).toLocaleString('de-DE');
    }


    /**
     * Initializes the Component with requests made
     */
    private initializeComponent(): void {
        // initialize chart for demo mode
        if (this.application.isDemoMode()) {
            // this.initializeHappyHour();
            this.initializeMockData();
            return;
        }

        if (this.userService.isEnviamUser()) {
            if (this.userService.getActiveUserProvider().toLowerCase() !== 'opto') {
                this.initializeHappyHour();
            }
            if (this.userService.isEDGUser()) {
                // const s = this.opticalReader.onMeterReaderStatus.subscribe(
                //     () => null,
                //     (error) => console.log(error),
                //     () => s.unsubscribe()
                // );
                this.initPowerApiConnection(true);
                return;
            }
        }

        if (this.userService.isEDGUser()) {
            // const s = this.opticalReader.onMeterReaderStatus.subscribe((res) => {
            // this.isNotRealtime = res.mode === 'RT_INACTIVE' && res.battery_status > 0;
            // });
            // this.addSub(s);
            this.initConsumptionApiConnection();
            return;
        }

        this.initPowerApiConnection();

        // homestate status live update
        this.initHomeStateStatus();
    }

    private initPowerApiConnection(hourly = false): void {
        this.liveData.startCurrentConsumptionUpdate(hourly);
        const liveS = this.liveData.onLiveConsumptionReceived.pipe(
            mergeMap((values) => !values ? of(null) : of(values)),
            catchError(error => of(null))
        ).subscribe(
            (res) => {
                try {
                    if (!res) {
                        return;
                    }
                    const data = res.results as Array<PowerValue>;
                    if (data.length === 0) {
                        this.liveChart.showLoadingState(true);
                        return;
                    }

                    // filter by the last 5 minutes
                    const filtered = this.filterValuesForTimeframe(data);

                    // update chart
                    this.updateChartWithData(filtered);

                    // find last value
                    const fiveMinCopy = filtered.slice().reverse();
                    let latest = fiveMinCopy.find((el) => 'power' in el);
                    if (latest) {
                        this.currentConsumption = latest.power;
                    } else {
                        const fullHourCopy = data.slice().reverse();
                        latest = fullHourCopy.find((el) => 'power' in el);
                        this.currentConsumption = latest.power;
                    }
                    const currentConsumptionFixed = this.currentConsumption.toFixed(0);
                    this.currentConsumption = parseInt(currentConsumptionFixed, 10);
                } catch (e) {
                    console.log('Error: Initializing power based API connection.', e);
                }
            },
            (error) => null
        );
        this.addSub(liveS);
    }

    private initHomeStateStatus(): void /**/{
        const homeS = this.homeState.onHomestateInfo
            .subscribe((data) => {
                try {
                    if (!data) {
                        return;
                    }
                    if (data.current_zone === 4) {
                        this.status.noZone = true;
                        return;
                    }
                    this.status.noZone = false;
                    this.status.trend = data.current_zone;
                } catch (e) {
                    console.log('Error: Extracting homestate info.', e);
                }
            });
        this.addSub(homeS);
        this.homeState.startLiveUpdateForBundledInfo();
    }

    private updateChartWithData(dataset: Array<PowerValue>): void {
        const fedEnergy = [];
        for (const el of dataset) {
            const element = {
                power: el.power < 0 ? Math.abs(el.power) : null,
                timestamp: el.timestamp
            };
            fedEnergy.push(element);
        }

        const consumedEnergy = [];
        const happyHourEnergy = [];
        for (const el of dataset) {
            const element = {
                power: el.power >= 0 ? el.power : null,
                timestamp: el.timestamp
            };
            if (this.userHasHappyHour) {
                const date = moment(el.timestamp).toDate();
                if (date >= this.happyHourFrom && date <= this.happyHourTo) {
                    happyHourEnergy.push(element);
                } else {
                    consumedEnergy.push(element);
                }
            } else {
                consumedEnergy.push(element);
            }
        }

        this.currentDataset = dataset;
        this.liveChart.showLoadingState(false);
        this.liveChart.addNewSeries(
            consumedEnergy,
            'power',
            3,
            {zindex: 0, isTileChart: true, color: this.regularSeriesColor}
        );
        this.liveChart.addNewSeries(
            fedEnergy,
            'power',
            0,
            {zindex: 1, isTileChart: true, color: this.feedinSeriesColor}
        );

        if (this.userHasHappyHour) {
            this.liveChart.addNewSeries(
                happyHourEnergy,
                'power',
                0,
                {zindex: 2, isTileChart: true, color: this.happyHourSeriesColor}
            );
        }
    }

    private initConsumptionApiConnection(): void {
        this.electricity.startLast24hTimerUpdate();
        const s = this.electricity.onConsumption24hUpdate.pipe(
            mergeMap((response: any[]) => {
                try {
                    const mapped = response.map(element => {
                        return {
                            power: 'measured' in element ? element.measured : 0,
                            timestamp: element.timestamp
                        };
                    });
                    return of(mapped);
                } catch (e) {
                    return of(null);
                }
            })
        ).subscribe((res) => {
            try {
                const filtered = res.filter(element => {
                    const ts = moment(element.timestamp);
                    const boundMin = moment().subtract(1, 'day');
                    const boundMax = moment();
                    return (ts > boundMin && ts <= boundMax); // && 'measured' in element;
                });

                if (filtered.length > 0) {
                    this.liveChart.showLoadingState(false);
                    this.updateChartWithData(filtered);
                    this.currentConsumption = filtered[filtered.length - 1]
                        .power.toLocaleString('de-DE');
                } else {
                    this.liveChart.showLoadingState();
                }
            } catch (e) {
                console.log('Error: Initializing consumption based API connection:', e);
            }
        });
        this.addSub(s);
    }

    private filterValuesForTimeframe(values: Array<PowerValue>): any {
        const start = moment().subtract(5, 'minutes');
        return values.filter((el) => {
            const ts = moment(el.timestamp);
            return ts >= start && ts <= moment();
        });
    }

    private initializeMockData(): void {
        this.mockDataIntervalSub = timer(0, 10000)
            .subscribe(() => this.getMockConsumption());
    }

    private getMockConsumption(): void {
        const offset = 5;
        const level = 1;
        const limit = 0;
        const interval = 1;

        const s = this.mockDataService.getLiveData(offset, level, limit, interval).subscribe(
            (response) => {
                try {
                    this.updateChartWithData(response);

                    // determine current consumption value
                    const currentConsumption = response.last().power;
                    const diff = currentConsumption - Math.floor(currentConsumption);
                    if (diff < 0.5) {
                        this.currentConsumption = Math.floor(currentConsumption);
                        this.currentConsumption = Math.floor(currentConsumption);
                    } else {
                        this.currentConsumption = Math.ceil(currentConsumption);
                    }
                } catch (e) {
                }
            }
        );
        this.addSub(s);
    }


    /**
     * Request whether the user participates in the happy hour program
     */
    private initializeHappyHour(): void {
        const s = this.happyHour.getParticipation().pipe(
            mergeMap(response => {
                if (!response) {
                    return throwError('Error fetching Happy Hour Participation');
                }
                return of(response);
            }),
            mergeMap(res => {
                let filtered = null;
                try {
                    const parsedResponse = res as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    filtered = parsedResponse.filter(
                        (element) =>
                            moment() >= moment(element.from_date, 'DD/MM/YYYY')
                    );
                } catch (e) {
                    return throwError('Error in parsing happyhour response and filtering', e);
                }
                return of(filtered);
            }),
            mergeMap(filteredData => {
                // sort dates
                const sorted = filteredData.sort((a, b) => {
                    const aFrom = moment(a.from_date, 'DD/MM/YYY').unix();
                    const bFrom = moment(b.from_date, 'DD/MM/YYY').unix();
                    return aFrom - bFrom;
                });
                return of(sorted);
            }),
            mergeMap(sortedData => {
                if (sortedData.length > 0) {
                    if ('value' in sortedData.first()) {
                        this.userHasHappyHour = sortedData.first().value === 1;
                        const d = moment();
                        // return this.happyHour.getSchedule(d.year(), d.month() + 1);
                        return this.happyHour.requestSchedule(d);
                    }
                }
                return throwError('Error: User does not have Happy Hour');
            }),
            mergeMap(res => {
                this.happyHourFrom = res.currentStart;
                this.happyHourTo = res.currentEnd;
                return of(true);
            })
        ).subscribe(
            (zones) => {
                if (this.hhIndicator) {
                    this.hhIndicator.setStartTime(this.happyHourFrom, this.happyHourTo);
                }
                this.liveChart.addPlotBand(this.happyHourFrom, this.happyHourTo);
                this.updateChartWithData(this.currentDataset);
            },
            (error) => {
                // console.log('Error on Happy Hour initialization', error);
            },
            () => s.unsubscribe()
        );
    }

    private calculateNewZones(): any {
        return [
            {color: 'rgb(0, 170, 225)'},
            {value: this.happyHourFrom.getTime()},
            {color: 'rgb(20, 60, 140)'},
            {value: this.happyHourTo.getTime()},
            {color: 'rgb(0, 170, 225)'},
        ];
    }


    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Live'
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Live-Details'
            }
        });
    }

    isPowerValue(obj: any): boolean {
        return 'power' in obj && 'timestamp' in obj;
    }

    isConsumptionObject(obj: any): boolean {
        return 'measured' in obj && 'timestamp' in obj;
    }
}


export interface PowerValue {
    power: number;
    timestamp: Date;
}
