import {Injectable} from '@angular/core';
import {Tariff, User} from '../classes/user';
import {Subject} from 'rxjs';
import {MvpConfig} from './mvp.service';
import {constants, ernaProviders} from '../shared/constants/constants';
import * as moment from 'moment';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public plugAttributesChanged = new Subject();

    constructor() {
    }

    setCurrentUser(user: User): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        let users_obj;
        if (users_str === null || users_str === undefined) {
            users_obj = [];
            // set initial value of consumption alert to false
            user.initialConsumptionAlert = true;
            user.tiles = null;
            users_obj.push(user);
            localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
        } else {
            users_obj = JSON.parse(users_str);
            let user_exists = false;
            for (const u of users_obj) {
                if (u.email === user.email) {
                    u.access_token = user.access_token;
                    u.refresh_token = user.refresh_token;
                    u.token_expires = user.tokenExpires;
                    user_exists = true;
                }
            }

            if (!user_exists) {
                users_obj.push(user);
            }

            localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
        }

        this.setActiveUser(user.email);
    }

    getUser(email: string): User {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        return users_obj.find((user: User) => user.email === email);
    }

    getActiveUserName(): string {
        return this.getElementForKey(StorageAttributes.USER_ACTIVE);
    }

    setActiveUserProvider(provider: string): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }

        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.provider = provider;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getActiveUserProvider(): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.provider.toLowerCase();
            }
        }
        return null;
    }

    setActiveUserTiles(tiles: any[]): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.tiles = tiles;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getActiveUserTiles(): any[] {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.tiles;
            }
        }
        return null;
    }

    getActiveUserAccessToken(): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                const token = user.access_token;
                return token;
            }
        }
        return null;
    }

    updateActiveUserAccessToken(access_token: string): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = access_token;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getActiveUserRefreshToken(): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.refresh_token;
            }
        }
        return null;
    }

    updateActiveUserRefreshToken(refresh_token: string): string {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.refresh_token = refresh_token;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getActiveUserTokenExpire(): Date {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.token_expires;
            }
        }
        return null;
    }

    updateActiveUserTokenExpire(new_expire: Date): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let expires;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.token_expires = new_expire;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    logoutActiveUser(): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return;
        }
        const users = JSON.parse(users_str) as any[];
        const userFoundIdx = users.findIndex(el => el.email === this.getActiveUserName());
        if (userFoundIdx >= 0) {
            users.splice(userFoundIdx, 1);
        }
        this.removeActiveUser();
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users));
    }

    getActiveUserNilmStatus(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.nilm_status;
            }
        }
        return null;
    }

    updateActiveUserNilmStatus(nilm_status): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.nilm_status = nilm_status;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    updateActiveUserNilmStatusForAppliance(appliance, model_count): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let nilm_values;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                nilm_values = user.nilm_status;
            }
        }

        nilm_values['timeBasedAppliances'][appliance].models = model_count;

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getLocalKeyValueStore(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.storage;
            }
        }
        return null;
    }

    getPlugAttributes(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    return null;
                }
                if (user.storage.power_checker === null) {
                    return null;
                }
                return user.storage.power_checker;
            }
        }
        return null;
    }

    updatePlugAttributes(attributes: any): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    user.storage = {};
                }
                user.storage.power_checker = attributes;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    deleteAccessToken(): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = null;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    isBoxUser(): boolean {
        const device = this.getUserDevice();
        switch (device) {
            case constants.application.devices.plug:
            case constants.application.devices.plug_optical:
                return false;
            default:
                return true;
        }
    }

    getUserDevice(): string | 'box' | 'plug' {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.device === null || user.device === undefined) {
                    return null;
                }
                return user.device;
            }
        }
        return null;
    }

    updateUserDevice(device: string): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.device = device;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getConsumptionAlertAttribute(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.initial_consumption_alert === null || user.initial_consumption_alert === undefined) {
                    return null;
                }
                return user.initial_consumption_alert;
            }
        }
        return null;
    }

    setConsumptionAlertAttribute(val: boolean): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.initial_consumption_alert = val;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getLastMvpTileConfigNew(): MvpConfig[] {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if ('mvp_config_new' in user) {
                    if (user.mvp_config_new === null || user.mvp_config_new === undefined) {
                        return null;
                    }
                } else {
                    return null;
                }
                return user.mvp_config_new;
            }
        }
        return null;
    }


    setMvpTileConfigNew(configs: MvpConfig[]): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.mvp_config_new = configs;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    hasPhaseChecker(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.phaseCheckerAvailable;
            }
        }
        return null;
    }

    setPhaseCheckerAvailability(status: boolean): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.phaseCheckerAvailable = status;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getPhaseThreshold(): number {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.phaseThreshold;
            }
        }
        return null;
    }

    setPhaseThreshold(value: number): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.phaseThreshold = value;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    addTarrifInfo(info: Tariff): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.tariffs) {
                    user.tariffs.push(info);
                    if (user.tariffs.length > 1) {
                        const end = moment(info.dateStart).subtract(1, 'day').toDate();
                        user.tariffs[user.tariffs.length - 2].dateEnd = end;
                    }
                } else {
                    user.tariffs = [];
                    user.tariffs.push(info);
                }
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getTariffInfo(): any {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.tariffs;
            }
        }
        return null;
    }

    removeTariff(idx: number): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.tariffs) {
                    user.tariffs.reverse();
                    user.tariffs.splice(idx, 1);
                    user.tariffs.reverse();
                    // update all the current dates so that no gaps occur
                    user.tariffs.forEach((el, idx) => {
                        const nextIdx = idx + 1;
                        if (user.tariffs[nextIdx]) {
                            const endDate = moment(user.tariffs[nextIdx].dateStart)
                                .subtract(1, 'day');
                            el.dateEnd = endDate.toDate();
                        } else {
                            el.dateEnd = null;
                        }
                    });
                }
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    updateTariffs(updatedTariffs: Tariff[]): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.tariffs) {
                    user.tariffs = updatedTariffs;
                }
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    _removeAllTariffs(): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.tariffs) {
                    user.tariffs = null;
                }
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getUserType(): { isOR: boolean, isErna: boolean } {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User;

        const data = {isOR: false, isErna: false};
        const device = users_obj.device;
        data.isOR = device === constants.application.devices.plug_optical;
        data.isErna = ernaProviders.findIndex(el => el === users_obj.provider) >= 0;
        return data;
    }

    setEDGUser(is_edg: boolean): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);

        let really = false;
        for (const p of ernaProviders) {
            if (p === this.getActiveUserProvider()) {
                really = true;
            }
        }

        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.edg_user = is_edg && really;
            }
        }

        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    isEDGUser(): boolean {
        const currentProvider = this.getActiveUserProvider().toLowerCase();
        if (currentProvider === 'enviam' || currentProvider === 'opto') {
            if (this.getUserDevice() === constants.application.devices.plug_optical) {
                return true;
            }
        }
        for (const p of ernaProviders) {
            if (p === this.getActiveUserProvider()) {
                return true;
            }
        }
        return false;
    }

    isERNAUser(): boolean {
        for (const p of ernaProviders) {
            if (p === this.getActiveUserProvider()) {
                return true;
            }
        }
        return false;
    }

    isEnviamUser(): boolean {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.provider === 'enviam'
                    || user.provider === 'enviaM'
                    || user.provider === 'EnviaM'
                    || user.provider === 'OPTO'
                    || user.provider === 'opto';
            }
        }
        return false;
    }

    hasUser(): boolean {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email !== 'demo') {
                return true;
            }
        }
        return false;
    }

    private getCurrentUser(): void {
        const users = this.getElementForKey(StorageAttributes.USERS);
        if (users === null || users === undefined) {
            return null;
        }
        const usersParsed = JSON.parse(users);
        for (const user of usersParsed) {
            if (user.email === this.getActiveUserName()) {
                return user;
            }
        }
        return null;
    }

    setLiveDetailZoomLevel(level: number): void {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.liveZoomLevel = level;
            }
        }
        localStorage.setItem(StorageAttributes.USERS, JSON.stringify(users_obj));
    }

    getLiveDetailZoomLevel(): number | null {
        const users_str = this.getElementForKey(StorageAttributes.USERS);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str) as User[];
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.liveZoomLevel ? user.liveZoomLevel : null;
            }
        }
    }


    private setActiveUser(email: string): void {
        localStorage.setItem(StorageAttributes.USER_ACTIVE, email);
    }

    private removeActiveUser(): void {
        localStorage.removeItem(StorageAttributes.USER_ACTIVE);
    }

    private getElementForKey(key: string): any {
        return localStorage.getItem(key);
    }
}
