<div #sliderContainer class="progress-slider">

    <span #sliderKnob class="slider-knob"
          (panstart)="onKnobMouseDown($event)"
          (pan)="onKnobMouseMove($event)"
          (panend)="onKnobMouseUp($event)"> </span>

    <span #sliderProgress
          class="slider-progress"></span>

    <span class="slider-track"></span>
</div>
