import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

// TODO: Add Angular decorator.
@Component({
    selector: 'base-component',
    template: ''
})
export class BaseComponent implements OnDestroy {

    protected subscriptions: Subscription[] = [];

    constructor() {
    }

    ngOnDestroy(): void {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    addSub(s: Subscription): void {
        this.subscriptions.push(s);
    }

    unsubscribeAndDelete(s: Subscription): void {
        if (s) {
            s.unsubscribe();
            return null;
        }
        return null;
    }
}
