<div id="container">
    <app-menu activeElement="profile"></app-menu>
    <div id="inner">
        <main class="page">

            <div class="page-card">

                <h1>{{TEXTS.VIEW_TITLE}}</h1>
                <section>
                    <h2>{{TEXTS.PERSONAL_DATA_HEADING}}</h2>
                    <div class="row">
                        <div>
                            <strong>{{TEXTS.PERSONAL_DATA_REAL_NAME}}</strong><br>
                            <strong>{{TEXTS.PERSONAL_DATA_USERNAME}}</strong>
                        </div>
                        <div>
                            {{contract.name}} <br>
                            {{determineDisplayUsername()}}
                        </div>
                    </div>

                    <div class="m-v-l button-container">
                        <button class="iona-button orange"
                                (click)="openChangeEmailPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{TEXTS.CHANGE_EMAIL_BUTTON}}
                        </button>
                    </div>

                    <div class="m-v-l button-container">
                        <button class="iona-button orange"
                                (click)="openResetPasswordPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{TEXTS.CHANGE_PASSWORD_BUTTON}}
                        </button>
                    </div>

                    <div class="m-v-l button-container" *ngIf="!application.isDemoMode()">
                        <button class="iona-button orange"
                                (click)="enableDisableMFA()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{mfaEnabled ? TEXTS.MFA_DISABLE_BUTTON : TEXTS.MFA_ENABLE_BUTTON}}
                        </button>
                    </div>
                </section>

                <section>
                    <h2>{{TEXTS.PROVIDER_HEADING}}</h2>
                    <!--                    <div class="provider-menu-logo m-b-m" [ngClass]="providerclass"></div>-->
                    <strong>{{providerDisplayName}}</strong>

                    <div class="row">
                        <div>
                            <strong>{{TEXTS.PROVIDER_PRODUCT}}</strong><br>
                            <strong>{{TEXTS.PROVIDER_BASEPRICE}}</strong><br>
                            <strong>{{TEXTS.PROVIDER_WORKPRICE}}</strong>
                        </div>
                        <div>
                            {{getCurrentTariffName()}} <br>
                            {{getCurrentTariffBasePrice()}} <br>
                            {{getCurrentTariffWorkPrice()}}
                        </div>
                        <div>
                            <ng-container *ngIf="tariffs.length > 0 && activeTariff">
                                <button class="iona-button"
                                        (click)="editCurrentTariff()">
                                    {{TEXTS.EDIT_TARIFF_BUTTON}}
                                </button>
                            </ng-container>
                        </div>
                    </div>

                    <ng-container *ngIf="isERNAUser">
                        <div class="m-v-l">
                            <button class="iona-button orange"
                                    [disabled]="createTariffDisabled"
                                    (click)="openNewTariffCreationPopover()">
                                {{TEXTS.NEW_TARIFF_BUTTON}}
                            </button>
                        </div>
                        <div class="m-v-l">
                            <button class="iona-button orange"
                                    [disabled]="tariffs.length < 1"
                                    (click)="openTariffHistoryPopover()">
                                {{TEXTS.TARIFF_HISTORY_BUTTON}}
                            </button>
                        </div>
                    </ng-container>
                </section>

                <section>
                    <h2>{{buttonTitle}}</h2>
                    <h3>{{TEXTS_A.ERNA_DESCRIPTION}}</h3>
                    <ul>
                        <li>{{TEXTS_A.ERNA_PROVIDER}}</li>
                        <li>{{TEXTS_A.ERNA_METER}}</li>
                        <li>{{TEXTS_A.ERNA_TIPS}}</li>
                        <li>{{TEXTS_A.ERNA_MUCHMORE}}</li>
                    </ul>

                    <!--                    <ng-container *ngIf="isErnaUser else ernaList ">-->
                    <!--                        <h2>{{TEXTS.DESCRIPTION}}</h2>-->
                    <!--                        <ul>-->
                    <!--                            <li>{{TEXTS.ADJUST_CONTRACT_DATA}}</li>-->
                    <!--                            <li>{{TEXTS.ADJUST_PAYMENT}}</li>-->
                    <!--                            <li>{{TEXTS.VIEW_BILL}}</li>-->
                    <!--                        </ul>-->
                    <!--                    </ng-container>-->
                    <!--                    <ng-template #ernaList>-->
                    <!--                        <h2>{{TEXTS.ERNA_DESCRIPTION}}</h2>-->
                    <!--                        <ul>-->
                    <!--                            <li>{{TEXTS.ERNA_PROVIDER}}</li>-->
                    <!--                            <li>{{TEXTS.ERNA_METER}}</li>-->
                    <!--                            <li>{{TEXTS.ERNA_TIPS}}</li>-->
                    <!--                            <li>{{TEXTS.ERNA_MUCHMORE}}</li>-->
                    <!--                        </ul>-->
                    <!--                    </ng-template>-->

                    <button class="iona-button orange"
                            (click)="openLink()">
                        {{buttonTitle}}
                    </button>
                </section>

            </div>
        </main>
    </div>
</div>
