<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Heute; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading">{{TEXTS.TITLE}}</h3>
            <div class="tile-content col">

                <div class="today-content grow-space">
                    <div class="today-visualization grow-space">
                        <div class="pie-wrapper">
                            <div class="today-pie left {{leftState}}" [halfCircle]="'left'"
                                 [style.transform]="'scale(' + trend.scale.left + ')'"></div>
                            <div class="today-pie right {{rightState}}" [halfCircle]="'right'"
                                 [style.transform]="'scale(' + trend.scale.right + ')'"></div>
                        </div>
                    </div>

                    <div class="today-stats">
                        <div>
                            <div class="font-regular day m-b-s">
                                {{formatCurrentComparisonDate()}}
                            </div>
                            <div class="font-light cost m-b-s">
                                <ng-container *ngIf="comparisonDate.costs === 0.0; else leftSideCosts">
                                    -
                                    <small class="font-light">
                                        {{TEXTS.UNIT_MONEY}}
                                    </small>
                                </ng-container>
                                <ng-template #leftSideCosts>
                                    {{extractRealValue(formatValue(comparisonDate.costs))}}
                                    <small class="font-light">
                                        {{extractDecimalValue(formatValue(comparisonDate.costs))}}
                                        {{TEXTS.UNIT_MONEY}}
                                    </small>
                                </ng-template>
                            </div>
                            <div class="font-regular consumption m-b-s">
                                <ng-container *ngIf="comparisonDate.consumption === 0.0; else leftSideConsumption">
                                    -
                                </ng-container>
                                <ng-template #leftSideConsumption>
                                    {{formatValue(comparisonDate.consumption)}}
                                </ng-template>
                                {{TEXTS.UNIT_CONSUMPTION}}
                            </div>
                        </div>
                        <div>
                            <div class="font-regular day m-b-s">
                                {{TEXTS.LABEL_COMPARISON_TODAY}}
                            </div>
                            <div class="font-light cost m-b-s">
                                <ng-container *ngIf="today.costs === 0.0; else rightSideCosts">
                                    -
                                    <small class="font-light">
                                        {{TEXTS.UNIT_MONEY}}
                                    </small>
                                </ng-container>
                                <ng-template #rightSideCosts>
                                    {{extractRealValue(formatValue(today.costs))}}
                                    <small class="font-light">
                                        {{extractDecimalValue(formatValue(today.costs))}}
                                        {{TEXTS.UNIT_MONEY}}
                                    </small>
                                </ng-template>
                            </div>
                            <div class="font-regular consumption m-b-s">
                                <ng-container *ngIf="today.consumption === 0.0; else rightSideConsumption">
                                    -
                                </ng-container>
                                <ng-template #rightSideConsumption>
                                    {{formatValue(today.consumption)}}
                                </ng-template>
                                {{TEXTS.UNIT_CONSUMPTION}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="center-contents m-t-m font-regular">
                    <ng-container *ngIf="trend.percentage > 0 && trend.percentage < 9999">
                        <strong>
                            {{trend.percentage}}
                            {{TEXTS.LABEL_FRAGMENT_PERCENT}}
                            {{ trend.direction > 0 ? TEXTS.MORE : TEXTS.LESS}}
                        </strong>
                        &nbsp;
                        <span>
                            {{TEXTS.LABEL_FRAGMENT_LAST}}
                            {{getComparisonDateDayName()}}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="trend.percentage === 0">
                        <strong>
                            {{TEXTS.LABEL_FRAGMENT_IDENTICAL}}
                        </strong>
                        &nbsp;
                        <span>
                            {{TEXTS.LABEL_FRAGMENT_TO_LAST}}
                            {{getComparisonDateDayName()}}
                        </span>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>
