<div class="login-container">

    <div class="bg-video">
        <video width="100%" height="100%" autoplay muted loop>
            <source src="../../../assets/media/background.mp4" type="video/mp4">
        </video>
    </div>

    <div class="login-card">
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>

        <div class="top-logo center-contents">
            <h1>iONA</h1>
        </div>

        <ng-container [ngSwitch]="state">
            <!--
              - INITIAL
              - ==============================================================================
              -->
            <ng-container *ngSwitchCase="LoginState.INITIAL">
                <h1 class="innogy-headline m-t-l"> {{TEXTS.WELCOME_LINE_1}} {{TEXTS.WELCOME_LINE_2}} </h1>

                <div class="grow-space">
                    <p> {{TEXTS.FIRST_TIME_1}} <strong>{{TEXTS.CODE}}</strong> {{TEXTS.FIRST_TIME_2}} </p>
                </div>

                <div class="center-contents btn-container">
                    <button class="iona-button scale white" routerLink="registrieren">
                        {{TEXTS.FIRST_TIME_LOGIN}}
                    </button>
                </div>

                <div class="center-contents">
                    <a href="javascript:;" class="white"
                       (click)="changeState(LoginState.LOGIN)"> {{TEXTS.HAS_PASSWORD}} </a>
                </div>

                <div class="demo-btn cursor-pointer font-medium">
                    <a href="javascript:;" class="side white" (click)="continueWithDemoMode()">
                        {{state === LoginState.LOGIN || state === LoginState.INITIAL ? TEXTS.DEMO_MODE : ''}}
                    </a>
                </div>
            </ng-container>

            <!--
              - LOGIN
              - ==============================================================================
              -->
            <ng-container *ngSwitchCase="LoginState.LOGIN">
                <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
                    <div class="grow-space">
                        <!-- EMAIL -->
                        <!------------------------------------------>
                        <div class="btn-container">
                            <input id="loginEmail" type="email" class="iona-input white"
                                   [placeholder]="TEXTS.PLACEHOLDER_EMAIL"
                                   formControlName="email">
                            <label for="loginEmail"></label>
                        </div>
                        <div class="text-field-error font-regular">
                            <div *ngIf="displayEmailError">
                                {{TEXTS.EMAIL_ERROR}}
                            </div>
                        </div>

                        <!-- PASSWORD -->
                        <!------------------------------------------>
                        <!--                        <div class="btn-container">-->
                        <!--                            <input id="loginPassword" type="password" class="iona-input white"-->
                        <!--                                   [placeholder]="TEXTS.PLACEHOLDER_PASSWORD"-->
                        <!--                                   formControlName="password">-->
                        <!--                        </div>-->

                        <fieldset class="input">
                            <input [placeholder]="TEXTS.PLACEHOLDER_PASSWORD"
                                   [type]="passwordVisible ? 'text' : 'password'"
                                   formControlName="password">
                            <legend>Passwort</legend>
                            <i [class.password-hidden]="!passwordVisible"
                               [class.password-visible]="passwordVisible"
                               (click)="passwordVisible = !passwordVisible">
                            </i>
                        </fieldset>

                        <div class="text-field-error font-regular">
                            <div *ngIf="displayPasswordError">
                                {{TEXTS.PASSWORD_ERROR}}
                            </div>
                        </div>

                        <!-- PASSWORD FORGOTTEN -->
                        <!------------------------------------------>
                        <div class="split-row">
                            <div class="demo-btn cursor-pointer font-medium">
                                <a href="javascript:;" class="side white" (click)="continueWithDemoMode()">
                                    {{state === LoginState.LOGIN || state === LoginState.INITIAL ? TEXTS.DEMO_MODE : ''}}
                                </a>
                            </div>
                            <div class="password-reset font-medium">
                                <a href="javascript:;" class="white" (click)="onResetPasswordLinkClick()">
                                    {{TEXTS.FORGOT_PASSWORD}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- SUBMIT -->
                    <!------------------------------------------>
                    <div class="center-contents btn-container">
                        <button type="submit" class="iona-button scale white"
                                [disabled]="loginDisabled">
                            {{TEXTS.LOGIN}}
                        </button>
                    </div>

                    <div class="center-contents">
                        <a href="javascript:;" class="white" target="_self" (click)="onRegisterLinkClick()">
                            {{TEXTS.REGISTER}}
                        </a>
                    </div>
                </form>
            </ng-container>

            <!--
              - PASSWORD RESET
              - ==============================================================================
              -->
            <ng-container *ngSwitchCase="LoginState.PASSWORD_RESET">
                <h1 class="innogy-headline"> {{TEXTS.WELCOME_LINE_1}} {{TEXTS.WELCOME_LINE_2}} </h1>

                <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">
                    <div class="grow-space p-t-l">
                        <input id="reset-email" type="email" class="iona-input white"
                               [placeholder]="TEXTS.PLACEHOLDER_EMAIL"
                               formControlName="email">
                        <label for="reset-email"></label>
                    </div>
                    <div class="text-field-error font-regular">
                        <div *ngIf="displayEmailResetError">
                            <i class="icon-error"></i>
                            {{TEXTS.EMAIL_ERROR}}
                        </div>
                    </div>

                    <div class="center-contents btn-container">
                        <button class="iona-button scale white" type="submit"
                                (click)="resetPassword()"
                                [disabled]="emailResetDisabled">
                            {{TEXTS.RESET}}
                        </button>
                    </div>
                </form>

                <div class="center-contents">
                    <a href="javascript:;" target="_self" class="white"
                       (click)="changeState(LoginState.LOGIN)">{{TEXTS.BACK_TO_LOGIN}}</a>
                </div>

                <div class="demo-btn cursor-pointer font-medium">
                    <a href="javascript:;" class="side" (click)="continueWithDemoMode()">
                        {{state === LoginState.LOGIN || state === LoginState.INITIAL ? TEXTS.DEMO_MODE : ''}}
                    </a>
                </div>
            </ng-container>

        </ng-container>

    </div>
</div>
