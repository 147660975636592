<div class="overlay">
    <div class="overlay-container-cdk">

        <div class="header">
            <div class="grow-space">
                <h2 class="m-a-n">Tarif Historie</h2>
            </div>
            <button class="iona-icon-only-button close" (click)="close(null)"></button>

        </div>

        <div class="growth-wrapper">
            <ul class="history-list">
                <li *ngFor="let t of tariffs; let i = index"
                    class="row">
                    <div class="grow-space">
                        <h3>{{t.name}}</h3>
                        <div class="tariff-row">
                            <div>
                                <p>Grundpreis:</p>
                                <p>Arbeitspreis:</p>
                                <p>von:</p>
                                <p>bis:</p>
                            </div>
                            <div>
                                <p>{{t.basePrice + ' € pro Jahr'}}</p>
                                <p>{{determineWorkingPrice(t.workPrice)}}</p>
                                <p>{{determineStartDate(t)}}</p>
                                <p>{{determineEndDate(t, i)}}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="iona-icon-only-button round edit dark" (click)="openEditOverlay(t, i)"></button>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</div>
