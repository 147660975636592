import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BaseService} from './base-service';
import {constants} from '../shared/constants/constants';
import {ApplicationService} from './application.service';
import {UserService} from './user.service';
import {MeterStatuses} from '../shared/constants/meter-statuses.constants';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';
import {AccountRewriteService} from './account-rewrite.service';

@Injectable({
    providedIn: 'root'
})
export class MeterService extends BaseService {
    onMeterStatus = new Subject<any>();
    onPinEntrySuccess = new BehaviorSubject<any>(null);

    private meterConnectedStates = [
        MeterStatuses.CONNECTED_WITH_METER
    ];

    private updateRate = 10000;

    private timerSub: Subscription = null;
    private pinEntryTimerSub: Subscription = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService,
                private accountRewrite: AccountRewriteService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
    }

    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            mergeMap((cycle) => this.getStatus())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onMeterStatus.next(res);
                }
            }
        );
    }

    startContinuousPinEntry(pin: string): void {
        if (this.pinEntryTimerSub) {
            return;
        }
        this.pinEntryTimerSub = timer(0, 5000).pipe(
            mergeMap((cycle) => {
                return this.putOpticalReaderPin(pin).pipe(
                    map((res) => true),
                    catchError((e) => {
                        if (e.status === 401) {
                            this.onPinEntrySuccess.next(true);
                            this.pinEntryTimerSub.unsubscribe();
                            this.pinEntryTimerSub = null;
                        }
                        return of(false);
                    })
                );
            })
        ).subscribe(
            (res) => {
                if (res) {
                    this.pinEntryTimerSub.unsubscribe();
                }
            }
        );
    }


    getStatus(handle_error = true): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.meter.status;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.meterStatus}.json`;
        }
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            map((res: any) => {
                if ('current_status' in res) {
                    if (this.meterConnectedStates.findIndex(el => el === res.current_status) >= 0) {
                        localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, '1');
                    } else {
                        localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, '0');
                    }
                    const connected = res.current_status === 'CONNECTED_WITH_METER' ? 1 : 0;
                    localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, connected.toString());
                }
                return res;
            }),
            catchError((error: any) => {
                if (handle_error) {
                    return this.handleError(error);
                }
                return of(error);
            })
        );
    }

    getCurrentMeterValue(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.meter.info;
        if (this.accountRewrite.accountRewriteEnabled()) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.meter.info;
        }
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.meterInfo}.json`;
        }

        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            mergeMap((res: any) => {
                if (!('Electricity' in res)) {
                    return throwError(null);
                }
                if (!('CSD' in res.Electricity)) {
                    return throwError(null);
                }
                return of(res.Electricity.CSD);
            }),
            catchError((error: any) => {
                return of(error);
            })
        );
    }

    putOpticalReaderPin(pin: string): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.meter.connect;
        const payload = {smart_reader: {pincode: pin.toString()}};
        return this.http.put(url, payload).pipe(
            map((res) => {
                return this.mapDefault(res);
            }),
        );
    }

    //
    // private checkMeterStatus(res): void {
    //     if (!('current_status' in res)) {
    //         console.log('Field "current_status" missing in response');
    //         return;
    //     }
    //
    //     switch (res.current_status.toUpperCase()) {
    //         case 'READY_FOR_METER_INCLUSION':
    //             this.meterStatus = 1;
    //             localStorage.removeItem(StorageAttributes.IS_METER_CONNECTED);
    //
    //             if (this.meterNotificationId === 0) {
    //
    //                 const s = createTimeout(500).pipe(
    //                     mergeMap(() => {
    //                         return this.notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
    //                             timeOut: 0,
    //                             enableHtml: true,
    //                             toastClass: 'toast-meterstatus toast'
    //                         }).onTap;
    //                     })
    //                 ).subscribe({
    //                     next: () => {
    //                         this.router.navigate(
    //                             ['/registrieren'],
    //                             {queryParams: {jumpToMeterstate: true}}
    //                         );
    //                     },
    //                     complete: () => s.unsubscribe()
    //                 });
    //
    //                 // this.meterNotificationId = notification.toastId;
    //                 // notification.onHidden.subscribe(
    //                 //     (value) => {
    //                 //         this.notification.clear(this.meterNotificationId);
    //                 //         this.meterNotificationId = 0;
    //                 //     }
    //                 // );
    //             }
    //             break;
    //         case 'CONTACT_WITH_METER':
    //             this.meterStatus = 2;
    //             localStorage.removeItem(StorageAttributes.IS_METER_CONNECTED);
    //
    //             if (this.meterNotificationId === 0) {
    //
    //                 notification = this.notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
    //                     timeOut: 0,
    //                     enableHtml: true,
    //                     toastClass: 'toast-meterstatus toast'
    //                 });
    //                 const s = createTimeout(500).subscribe(() => {
    //                     $('.go-to-meterstate').click(() => {
    //                         this.router.navigate(['/registrieren'], {queryParams: {jumpToMeterstate: true}});
    //                     });
    //                     s.unsubscribe();
    //                 });
    //
    //                 this.meterNotificationId = notification.toastId;
    //                 notification.onHidden.subscribe(
    //                     (value) => {
    //                         this.notification.clear(this.meterNotificationId);
    //                         this.meterNotificationId = 0;
    //                     }
    //                 );
    //             }
    //             break;
    //         case 'CONNECTED_WITH_METER':
    //             this.meterStatus = 3;
    //             localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, '1');
    //
    //             if (this.meterNotificationId > 0) {
    //                 this.notification.clear(this.meterNotificationId);
    //             }
    //
    //             clearInterval(this.meterNotificationId);
    //             break;
    //     }
    // }


}
