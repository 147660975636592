<div class="overlay overlay-minimal">
    <div class="overlay-container-cdk">
        <h1>{{title}}</h1>
        <p>{{text}}</p>
        <div class="interaction-area">
            <ng-container *ngIf="turquoise; else regularColorPositive">
                <button class="iona-button orange" (click)="close(true)">{{positive}}</button>
            </ng-container>
            <ng-template #regularColorPositive>
                <button class="iona-button orange" (click)="close(true)">{{positive}}</button>
            </ng-template>
            <ng-container *ngIf="negative !== ''">
                <ng-container *ngIf="turquoise; else regularColorNegative">
                    <button class="iona-button orange" (click)="close(false)">{{negative}}</button>
                </ng-container>
                <ng-template #regularColorNegative>
                    <button class="iona-button orange" (click)="close(false)">{{negative}}</button>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="infoLink">
                <button class="iona-button outline" (click)="openInfo(infoLink)">Weitere Informationen</button>
            </ng-container>
        </div>
    </div>
</div>
