export const onboardingSteps = {
    accountCreation: 'account-creation',
    passwordEntry: 'password-entry',
    deviceSelection: 'device-selection',
    accountSetup: 'account-setup',
    hardwarePresent: 'hardware-present',
    analyticsOptIn: 'analyticsOptIn',
    devices: {
        box: {
            powerConnect: 'box-ac-connect',
            lanConnect: 'box-lan-connect',
            macEntry: 'box-mac-entry',
            macEntry2: 'box-mac-entry-2',
            connecting: 'box-connecting',
            notFoundError: 'box-not-found',
            connectionError: 'box-connection-error'
        },
        plug: {
            noteMac: 'plug-ac-note-mac',
            powerConnect: 'plug-ac-connect',
            appSelect: 'plug-app-select',
            wpsConnect: 'plug-wps-connect',
            wpsRouter: 'plug-wps-router',
            wifiConnected: 'plug-wifi-connected',
            macEntry: 'plug-mac-entry',
            macEntry2: 'plug-mac-entry-2',
            connecting: 'plug-connecting',
            connectingOR: 'plug-connecting-optical-reader'
        }
    },
    tariffEntry: 'tariff-entry',
    opticalReader: {
        deviceSelection: 'optical-reader-device-selection',
        position: {
            first: 'optical-reader-pos-one',
            second: 'optical-reader-pos-two',
        },
        installation: {
            step1: 'optical-reader-onboarding-1',
            step2: 'optical-reader-onboarding-2',
            step3: 'optical-reader-onboarding-3',
            step4: 'optical-reader-onboarding-4',
        },
        pinEntry: 'optical-reader-pin-entry'
    },
    support: 'contact-support',
    detailFailed: 'detailed-fail'
};
