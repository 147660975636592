import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {Observable, of, throwError} from 'rxjs';
import {constants} from '../../shared/constants/constants';
import {MvpConfig} from '../../services/mvp.service';
import {ApplicationService} from '../../services/application.service';
import {MeterService} from '../../services/meter.service';
import {mergeMap, switchMap} from 'rxjs/operators';
import {NilmService} from '../../services/nilm.service';
import {RegistrationService} from '../../services/registration.service';
import {BaseComponent} from '../../classes/base-component';
import {UserGroupService} from '../../services/user-group.service';
import {Popover} from '../../popovers/popover/popover.service';
import {AddTileComponent} from '../../popovers/add-tile/add-tile.component';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';
import {SortTilesComponent} from '../../popovers/sort-tiles/sort-tiles.component';
import {
    FirmwareUpdateAvailablePopover,
    FirmwareUpdatePopover,
    ManualPinEntryInfoPopoverConfig,
    ManualPinEntryPopoverConfig,
    PinEntryPopoverConfig,
    PinFailedPopoverConfig,
    RadioLinkLostPopover
} from '../../popovers/static.popover.config';
import {VisibilityService} from '../../services/visibility.service';
import {MeterReaderStatus, OpticalReaderService} from '../../services/optical-reader.service';
import {BannerComponent} from '../../components/banner/banner.component';
import * as moment from 'moment';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {MeterStatuses} from '../../shared/constants/meter-statuses.constants';
import {Banners} from '../../shared/constants/banners.constants';
import {shouldTriggerTimeBasedOverlay} from '../../lib/overlay.util';
import {StorageAttributes} from '../../shared/constants/storage-attributes.constants';
import {OptInService} from '../../services/opt-in.service';
import {DashboardTexts} from '../../shared/texts/dashboard.texts';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly TILE_TYPE = TILE_TYPE;
    readonly TEXTS = DashboardTexts;
    userHasPlug = false;

    mvpTileAvailable = false;
    dashboardTiles: TileDef[] = [];
    tilesAvailable = false;

    currentORBatteryState = 0;

    private visibilitySub = null;
    private popoversOpen = false;
    private preventPinErrorPopover = false;
    private meterPin = null;

    @ViewChild('banner', {static: true}) private banner: BannerComponent;

    constructor(public userService: UserService,
                public application: ApplicationService,
                public tileService: TileService,
                private title: Title,
                private meter: MeterService,
                private nilm: NilmService,
                private registration: RegistrationService,
                private userGroupService: UserGroupService,
                private popover: Popover,
                private visibility: VisibilityService,
                private opticalReader: OpticalReaderService,
                private updateService: FirmwareUpdateService,
                private optInService: OptInService,
                private gtm: GTMWrapperService,
                private auth: AuthService) {
        super();
    }

    ngOnInit() {
        this.title.setTitle('Übersicht | iONA');
        this.optInService.checkStatus(true);

        this.initializeDashboardTiles();

        // further inits
        this.setupVisibilityCallback();
        this.nilm.startNilmStatusUpdateForCurrentProfile();

        // todo remove model registration call from here
        this.initPlug();
        this.initOR();
        this.initPhaseChecker();
        this.initializeMVPTiles();
    }

    ngOnDestroy() {
        if (this.visibilitySub) {
            this.visibilitySub.unsubscribe();
        }

        delete this.tileService;
    }

    ngAfterViewInit() {
        if (this.auth.fromLogin) {
            const labelpartner = this.userService.getActiveUserProvider();
            const meterType = this.userService.getUserDevice();
            this.auth.fromLogin = false;
            this.trackAfterLoginEvent(labelpartner, meterType);
        }
    }


    /**
     * On Sort Dashboard tiles button clicked.
     * Opens an overlay on the rhs containing the list
     */
    onSortButtonClick() {
        const s = this.popover.open({
            content: SortTilesComponent,
            data: null,
            hasBackdrop: true,
            position: 'absolute',
            placement: 'end center'
        }).afterClosed$.subscribe(
            (res) => s.unsubscribe());
    }


    /**
     * On Add Dashboard tiles button clicked.
     * Opens an overlay containing an overview of all available tiles
     */
    onAddTileButtonClicked() {
        const s = this.popover.open({
            content: AddTileComponent,
            data: null,
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe(
            (res) => s.unsubscribe());
    }


    /**
     * Initialize Dashboard tile handling
     */
    private initializeDashboardTiles(): void {
        this.dashboardTiles = this.tileService.getCurrentTiles();
        this.tilesAvailable = this.tileService.tilesAvailable();
        this.tileService.init();
        this.addSub(this.tileService.selectionChanged.subscribe((res: any) => {
            if (this.tileService) {
                this.tilesAvailable = this.tileService.tilesAvailable();
                this.dashboardTiles = res;
            }
        }));
    }


    /**
     * Wrapper for extracting MVP tile configs an minor response restructuring
     * @param response
     */
    private extractMvpTileDefinitions(response): Observable<MvpConfig[]> {
        try {
            const mapped = response.groups.map(group => {
                const cfg = group.parameters as MvpConfig;
                cfg.group = group.group_id;
                return cfg;
            }) as MvpConfig[];
            return of(mapped);
        } catch (error) {
            return throwError(error);
        }
    }

    /**
     * MPV Tile initialization pipeline
     */
    private initializeMVPTiles(): void {
        if (this.application.isDemoMode()) {
            return;
        }

        this.tileService.removeLegacyMVPTiles();
        const sub = this.userGroupService.getUserGroup().pipe(
            mergeMap(response => this.extractMvpTileDefinitions(response)),
            mergeMap(tileDefs => of({
                    currentConfig: this.userService.getLastMvpTileConfigNew(),
                    newConfig: tileDefs
                })
            ),
        ).subscribe(
            (configs: { currentConfig: MvpConfig[], newConfig: MvpConfig[] }) => {
                const currentConfig = configs.currentConfig;
                const newConfigs = configs.newConfig;

                if (currentConfig) {
                    for (const config of currentConfig) {
                        const newConfig = newConfigs.find(el => el.id === config.id);
                        if (!newConfig) {
                            this.tileService.removeMVPTile(config);
                            this.userService.setMvpTileConfigNew(newConfigs);
                        }
                    }

                    for (const config of newConfigs) {
                        const oldConfig = currentConfig.find(el => el.id === config.id);
                        if (oldConfig) {
                            const tileThere = this.tileService.mvpTileIsCurrentlyActive(config.id);
                            if (!tileThere) {
                                if (config.dashboardConfiguration.version >
                                    oldConfig.dashboardConfiguration.version) {
                                    if (config.dashboardConfiguration.forceAdd) {
                                        this.tileService.updateMVPTileConfig(config);
                                        this.tileService.toggleMVPTile(config.id);
                                        this.tileService.updateMVPTilePosition(config);

                                        // save mvp tile configs
                                        this.userService.setMvpTileConfigNew(newConfigs);
                                    }
                                }
                            }
                        } else {
                            this.tileService.addMVPTile(config);
                        }
                    }
                } else {
                    for (const config of newConfigs) {
                        this.tileService.addMVPTile(config);
                    }
                    this.userService.setMvpTileConfigNew(newConfigs);
                }
                this.mvpTileAvailable = true;
            },
            error => console.log('Error initializing MVP tile feature:', error),
            () => sub.unsubscribe()
        );
    }

    /**
     * Initialize PhaseChecker tile
     */
    private initPhaseChecker(): void {
        const o = of(this.userService.hasPhaseChecker()).pipe(
            mergeMap((res) => {
                if (!res) {
                    return this.meter.onMeterStatus;
                }
                return of(null);
            })
        );
        let sub = null;
        sub = o.subscribe(
            (res) => {

                if (res) {
                    if ('lora_mode' in res) {
                        if (res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8) {
                            this.tileService.enableTileType(TILE_TYPE.PHASE_CHECKER);
                            this.tileService.setSelected(true, TILE_TYPE.PHASE_CHECKER);
                            // this.addTile('phase-checker', false);
                        }
                        this.userService.setPhaseCheckerAvailability(true);
                    }
                }
                if (sub) {
                    sub.unsubscribe();
                }
            },
        );
    }


    /**
     * Check the user has a plug or a box
     */
    private initPlug(): void {
        this.registration.getModel().subscribe(
            (result) => {
                this.handlePlugResponse(result);
            },
            (error) => {
                console.log('Error', error);
            }
        );
    }


    /**
     * If the user has a plug, handle the response and act accordingly
     * @param response
     */
    private handlePlugResponse(response: any): void {
        const model = response.model_identifier;
        switch (model) {
            case constants.application.devices.plug:
                this.userService.updateUserDevice(constants.application.devices.plug);
                this.userHasPlug = true;
                break;
            case constants.application.devices.plug_optical:
                this.userService.updateUserDevice(constants.application.devices.plug_optical);
                this.userHasPlug = true;
                break;
            case constants.application.devices.box:
                this.userService.updateUserDevice(constants.application.devices.box);
                break;
            default:
                this.userService.updateUserDevice(constants.application.devices.box);
                break;
        }

        if (this.userHasPlug) {
            if (!this.tileService.tileAvailable(TILE_TYPE.POWER_CHECKER)) {
                this.tileService.enableTileType(TILE_TYPE.POWER_CHECKER);
                this.tileService.setSelected(true, TILE_TYPE.POWER_CHECKER);
            }
        }
    }


    /**
     * Initialize Optical Reader features
     */
    private initOR(): void {
        if (this.userService.isEDGUser()) {
            if (this.userService.isERNAUser()) {
                this.tileService.disableTileType(TILE_TYPE.APPLIANCES);
                this.tileService.disableTileType(TILE_TYPE.FINANCE);
                this.tileService.disableTileType(TILE_TYPE.CONSUMPTION_ALERT);
            }
            this.tileService.disableTileType(TILE_TYPE.PHASE_CHECKER);
            this.opticalReader.startLiveUpdate();
            this.startMeterStatusUpdate();
        }
    }


    /**
     * Determine whether the displayal of PIN Info overlays is necessary
     */
    private shouldTriggerPinOverlay(): boolean {
        if (this.preventPinErrorPopover) {
            return false;
        }

        const storageItem = 'lastPinInfo';
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(1, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }
        return false;
    }


    /**
     * Handle state independent values in continuous meter/meterreader status update
     * @param res mapped optical reader status response
     */
    private handleStateIndependentValues(res: MeterReaderStatus): Observable<any> {
        this.currentORBatteryState = res.battery_status;
        if (res.mode === 'RT_INACTIVE') {
            if (res.battery_status > 0) {
                // this.banner.show(Banners.energySaver);
            } else {
                // this.banner.hide();
            }
        }
        return of(res);
    }


    /**
     * Trigger the popover cycle necessary when the pin entry has failed
     *    furthermore determine whether the overlays should be shown
     */
    private triggerPinFailedPopoverCycle(): Observable<any> {
        return of(this.shouldTriggerPinOverlay()).pipe(
            mergeMap((trigger) => {
                if (trigger) {
                    return this.popover.open(PinFailedPopoverConfig).afterClosed$.pipe(
                        switchMap((dialogData) => {
                            if (dialogData.data === null) {
                                this.popoversOpen = false;
                                return of(null);
                            }
                            let overlayDef: any = ManualPinEntryPopoverConfig;
                            if (dialogData.data) {
                                overlayDef = PinEntryPopoverConfig;
                            }
                            if (this.meterPin) {
                                overlayDef.data.text +=
                                    ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}`;
                            }
                            return this.popover.open(overlayDef).afterClosed$;
                        })
                    );
                } else {
                    return of(null).pipe(switchMap(res => of(res)));
                }
            })
        );
    }


    /**
     * Start continuous meter/meterreader - status update and responses towards results
     */
    private startMeterStatusUpdate(): void {
        let previousStatus = '';
        this.opticalReader.onMeterReaderStatus.pipe(
            mergeMap((res: MeterReaderStatus) => {
                return this.handleStateIndependentValues(res);
            }),
            mergeMap((result: MeterReaderStatus) => {
                // if the update state has changed killit
                if (previousStatus === MeterStatuses.UPDATE_INSTALLING &&
                    result.status !== MeterStatuses.UPDATE_INSTALLING) {
                    this.updateService.onUpdateStateReceived.next(null);
                }
                previousStatus = result.status;

                this.meterPin = result.pincode;
                const pincodeThere = result.pincode
                    ? result.pincode !== '' || result.pincode !== null
                    : false;
                const pinEntryModeValid = result.pin_entry_mode === 'optical'
                    || result.pin_entry_mode === 'unknown';
                const meterUnlocked = result.meter_unlocked;
                const pinInputRequired = result.pin_entry_mode === 'manual_push_button' ||
                    result.pin_entry_mode === 'manual_torch';

                // pasted here from status CONNECTED_WITH_METER 01.02.2022
                if (shouldTriggerTimeBasedOverlay(
                    StorageAttributes.MANUAL_PIN_ENTRY_MODE, 'hours', 24)) {
                    if (this.userService.isEDGUser()) {
                        const isEnviam = this.userService.isEnviamUser();
                        const isOPTO =
                            this.userService.getActiveUserProvider().toLowerCase() === 'opto';
                        if (isEnviam || isOPTO) {
                            if (!result.meter_unlocked && pinInputRequired) {
                                const overlayDef = ManualPinEntryInfoPopoverConfig;
                                if (this.meterPin) {
                                    overlayDef.data.text +=
                                        ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}.`;
                                }
                                return this.popover.open(overlayDef)
                                    .afterClosed$.pipe(
                                        mergeMap(res =>
                                            of({
                                                type: MeterStatuses.MANUAL_PIN_ENTRY_REQUIRED, res
                                            })
                                        ));
                            }
                        }
                    }
                }

                let toReturn = of(null) as Observable<any>;
                switch (result.status) {
                    case MeterStatuses.PIN_FAILED:
                        if (!this.popoversOpen) {
                            toReturn = this.triggerPinFailedPopoverCycle().pipe(
                                mergeMap(res => of({type: MeterStatuses.PIN_FAILED, res}))
                            );
                        }
                        break;
                    case MeterStatuses.UPDATE_INSTALLING:
                        this.updateService.onUpdateStateReceived.next(result.update_progress);
                        if (!this.popoversOpen) {
                            if (result.update_progress < 100) {
                                toReturn = this.popover.open(FirmwareUpdatePopover).afterClosed$
                                    .pipe(
                                        mergeMap(res => of({
                                            type: MeterStatuses.UPDATE_INSTALLING, res
                                        }))
                                    );
                                this.popoversOpen = true;
                            }
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        if (this.userService.isEnviamUser()) {
                            if (!meterUnlocked) {
                                if (result.key_retries === 0
                                    && !pincodeThere
                                    && pinEntryModeValid) {
                                    toReturn = this.triggerPinFailedPopoverCycle().pipe(
                                        mergeMap(res =>
                                            of({type: MeterStatuses.PIN_FAILED, res}))
                                    );
                                }
                                this.banner.show(Banners.meterConnecting);
                            }
                        }

                        if (result.firmware_status === 'UPDATE_AVAILABLE') {
                            if (!shouldTriggerTimeBasedOverlay(StorageAttributes.LAST_UPDATE_INFO,
                                'hours', 24)) {
                                break;
                            }
                            if (this.popoversOpen) {
                                break;
                            }
                            toReturn = this.popover.open(FirmwareUpdateAvailablePopover)
                                .afterClosed$.pipe(
                                    mergeMap(res => of(
                                        {type: MeterStatuses.CONNECTED_WITH_METER, res}
                                    ))
                                );
                            this.popoversOpen = true;
                        }


                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!shouldTriggerTimeBasedOverlay(
                            StorageAttributes.RADIO_LINK_LOST_INFO, 'hours', 24)) {
                            break;
                        }
                        if (this.popoversOpen) {
                            break;
                        }
                        toReturn = this.popover.open(RadioLinkLostPopover).afterClosed$.pipe(
                            mergeMap(res => of({type: MeterStatuses.RADIO_LINK_LOST, res}))
                        );
                        this.popoversOpen = true;
                        break;
                    case MeterStatuses.READY_FOR_METER_INCLUSION:
                        this.banner.show(Banners.meterConnectingTutorial);
                        break;
                    default:
                        break;

                }
                return toReturn;
            }),
        ).subscribe((result) => {
            try {
                if (result.type === MeterStatuses.PIN_FAILED) {
                    if (typeof result.res.data === 'string') {
                        // const pin = parseInt(result.res.data, 10);
                        this.preventPinErrorPopover = true;
                        this.meter.startContinuousPinEntry(result.res.data);
                        this.meter.onPinEntrySuccess.subscribe((res) => {
                            if (res) {
                                this.preventPinErrorPopover = false;
                            }
                        });
                    }
                    this.popoversOpen = false;
                } else if (result.type === MeterStatuses.UPDATE_INSTALLING) {

                } else if (result.type === MeterStatuses.CONNECTED_WITH_METER) {
                    if (result.res.data) {
                        this.updateService.startUpdate().subscribe(() => {
                        });
                    }
                } else if (result.type === MeterStatuses.MANUAL_PIN_ENTRY_REQUIRED) {

                }
                this.popoversOpen = false;
            } catch (error) {
            }
        });
    }


    /**
     * Setup visibility change funcitonality
     */
    private setupVisibilityCallback(): void {
        if (!this.visibilitySub) {
            this.visibilitySub = this.visibility.onVisible.subscribe(() => {
                // this.checkNilmStatus();
                this.opticalReader.getBatteryStatus().subscribe(
                    value => this.currentORBatteryState = value);
            });
        }
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackAfterLoginEvent(labelpartner: string, meterType): void {
        this.gtm.trackEvent({
            event: 'login',
            eventCategory: 'conversion',
            eventAction: 'login',
            journeyId: 'smart control login',
            toolId: 'smart control login',
            elementId: 'smart control login',
            stepId: 'smart control login',
            lablePartner: labelpartner,
            tariffMeterType: meterType
        });
    }
}
