<ng-container *ngIf="showBanner">
    <div class="banner-container"
         [class.info]="currentBanner.type === 'info'"
         [class.alert]="currentBanner.type === 'alert'"
         [ngClass]="{clickable: currentBanner.route.length > 0}"
         (click)="onClick()">
        <div class="banner-icon">
            <ng-container [ngSwitch]="currentBanner.type">
                <i *ngSwitchCase="'info'" class="icon-info"></i>
                <i *ngSwitchCase="'alert'" class="icon-error"></i>
            </ng-container>
        </div>
        <div class="banner-text eon-regular">
            <strong>{{currentBanner.main}}</strong>
            {{currentBanner.sub}}
        </div>
        <div class="banner-interaction">
            <i class="clickable-icon"></i>
        </div>
    </div>
</ng-container>
