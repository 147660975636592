import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {HomestateService} from '../../../services/homestate.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {
    ConsumptionAlertChartComponent
} from '../../../charts/consumption-alert-chart/consumption-alert-chart.component';
import {mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as moment from 'moment';
import {InstantaneousService} from '../../../services/instantaneous.service';
import {MetaResponse} from '../../../shared/interfaces/meta-response';
import {HttpErrorResponse} from '@angular/common/http';
import {AnimationOptions} from 'ngx-lottie';

@Component({
    selector: 'app-consumption-alert-details',
    templateUrl: './consumption-alert-detail.component.html',
    styleUrls: ['consumption-alert-detail.component.scss'],
    viewProviders: [ApiService]
})
export class ConsumptionAlertDetailsComponent
    extends BasePopover
    implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('consumptionAlertChart', {static: true})
    consumptionAlertChart: ConsumptionAlertChartComponent;

    private alertChart: ConsumptionAlertChartComponent = null;

    private currentMaxValue = 0;
    private cachedDiagramData = null;
    private chartInitialized = false;
    private lastFetchedDataset = null;

    currentDataOffset = 0;
    maxDataOffset = 6;

    infoVisible = false;
    showEmptyState = true;

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/empty-states/consumption-alert.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Verbrauchsalarm '
    };

    constructor(protected popoverRef: PopoverRef,
                private analytics: TrackAnalyticsService,
                private homestate: HomestateService,
                private application: ApplicationService,
                private instantaneous: InstantaneousService) {
        super(popoverRef);
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getSevenDayHistoricalData();
        this.initializeHomeStateUpdate();
    }

    /**
     * Chart loaded callback
     * @param chart
     */
    onChartLoaded(chart: ConsumptionAlertChartComponent): void {
        this.alertChart = chart;
        this.chartInitialized = true;
        this.updateDiagramFromCachedData();
        this.alertChart.deactivateXAxisLabelLimitation();
    }


    /**
     * Decrease Offset, thus step forward one timeframe (24h)
     */
    stepForward(): void {
        --this.currentDataOffset;
        this.updateDiagramFromCachedData();
    }


    /**
     * Increase offset, thus step back one timeframe (24h)
     */
    stepBack(): void {
        ++this.currentDataOffset;
        this.updateDiagramFromCachedData();
    }


    /**
     * Reset the current offset
     */
    resetHistory(): void {
        this.currentDataOffset = 0;
        this.updateDiagramFromCachedData();
    }


    /**
     * Request historical data for the last seven days
     */
    private getSevenDayHistoricalData(): void {
        this.instantaneous.onConsumptionAlertDataUpdate.pipe(
            mergeMap((results: MetaResponse) => {
                if (results) {
                    if (results.status === 'error') {
                        this.handleInstantaneousErrors(results.data);
                    } else {
                        return of(results.data.results);
                    }
                }
                return of(null);
            })).subscribe({
            next: result => {
                if (!result) {
                    return;
                }
                this.cachedDiagramData = result;
                this.updateDiagramFromCachedData();
                this.showEmptyState = false;
            },
            error: error => {
                if (this.chartInitialized) {
                    this.alertChart.showLoadingState();
                    console.log('Error: ', error);
                }
            },
        });
        this.instantaneous.startConsumptionAlertValueLiveUpdate(this.maxDataOffset);
        // this.instantaneous.getLatestConsumptionAlertData();
    }


    /**
     * Extract current series data from cached dataset
     */
    private extractSeriesDataFromCache(): void {
        const start = moment().subtract(this.currentDataOffset + 1, 'days').toDate();
        const end = moment().subtract(this.currentDataOffset, 'days').toDate();
        this.lastFetchedDataset = this.cachedDiagramData.filter(el => {
            const ts = moment(el.timestamp).toDate();
            return ts >= start && ts <= end;
        });
    }


    /**
     * Update the diagram from already cached data
     */
    private updateDiagramFromCachedData(): void {
        try {
            this.extractSeriesDataFromCache();
            if (this.chartInitialized) {
                this.updateChart();
            }

        } catch (e) {
            if (this.chartInitialized) {
                this.alertChart.showLoadingState();
            }
        }
    }


    /**
     * Update the chart with the last stored data
     */
    private updateChart(): void {
        this.alertChart.showLoadingState(false);
        this.alertChart.addNewSeries(
            this.lastFetchedDataset,
            'power',
            1,
            0
        );

        // generate day change plotlines
        const time = moment(this.lastFetchedDataset.first().timestamp).endOf('day');
        this.alertChart.insertVerticalLine(time.toDate());

        // insert max value line
        this.alertChart.updateMaxValueLine(this.currentMaxValue, false);
    }


    /**
     * Handle instantaneous data error
     * @param error
     */
    private handleInstantaneousErrors(error: HttpErrorResponse): void {
        if (error.error.error.code === 290) {
            this.showEmptyState = true;
        }
        this.chartInitialized = false;
    }


    /**
     * Initialize homestate-status updates
     */
    private initializeHomeStateUpdate(): void {
        const s = this.homestate.onHomestateInfo.subscribe(
            (result) => {
                if (!result) {
                    return;
                }
                this.currentMaxValue = result.config.thresholds.sort((a, b) => a - b).last();
                if (this.chartInitialized) {
                    this.consumptionAlertChart.updateMaxValueLine(this.currentMaxValue, false);
                }
                this.handleHomeStateConfig(result.config);
            }
        );
        this.addSub(s);
        this.homestate.startLiveUpdateForBundledInfo();
    }


    /**
     * Handle homestate-config data
     * @param config
     */
    private handleHomeStateConfig(config: any): void {
        try {
            if (config.thresholds.length < 1) {
                // this.powerText = `${0} Watt`;
                // this.powerValue = 0;
            } else {
                // this.powerText = `${Math.round(config.thresholds.reduce((a, b) => Math.max(a, b))).toLocaleString('de')} Watt`;
                // this.powerValue = Math.round(config.thresholds.reduce((a, b) => Math.max(a, b)));
            }
            // this.showPowerInfo = true;
        } catch (e) {
            console.log('Error', e);
        }
    }
}
