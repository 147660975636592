<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>2-Faktor-Code eingeben</h1>
        <p>
            Bitte geben Sie ein aktuelles Einmalkennwort aus der Authenticator-App ein.
        </p>
        <form [formGroup]="form" (ngSubmit)="submitCode()">
            <div>
                <input #inputField id="code-input" class="iona-input" type="tel"
                       pattern="^[0-9]*$"
                       (keydown)="onKeyDown($event)"
                       (keyup)="onKeyUp($event)"
                       placeholder="Code eingeben"
                       formControlName="code">
                <label for="code-input"></label>
                <div class="loading-indicator"
                     [class.hidden]="!isLoading">
                    <ng-lottie class="loading-spinner" height="45px"
                               [options]="loadingAnimation"></ng-lottie>
                </div>
            </div>
            <button class="iona-button orange" type="submit"
            [disabled]="submitDisabled">Absenden</button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close()"></button>
    </div>
</div>
