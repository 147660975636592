<div class="overlay">
    <div class="detail-overlay">

        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">Live</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>

                <section>
                    <div class="center-contents current-live-value font-bold"
                         [ngClass]="{muted: isNotRealtime}">
                        <i [class.feedin-white]="currentConsumption < 0"
                           [class.consumption-white]="currentConsumption >= 0">
                        </i>
                        {{currentConsumptionFormatted()}} {{valueUnit}}
                    </div>

                    <ng-container *ngIf="!userService.isERNAUser()">

                        <ng-container *ngIf="!userHasHappyHour; else happyHourTemplate">

                            <ng-container *ngIf="!status.noZone; else noZoneTemplate">
                                <div class="consumption-zones">
                                    <div>
                                        <div class="zone-title"
                                             [style.visibility]="status.trend === 0 ? 'visible' : 'hidden'">
                                            niedrig
                                        </div>
                                        <div class="zone-bar" [class.active]="status.trend === 0"></div>
                                        <div class="zone-duration"
                                             [style.visibility]="status.trend === 0 ? 'visible' : 'hidden'">
                                            {{ status.since}}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="zone-title"
                                             [style.visibility]="status.trend === 1 ? 'visible' : 'hidden'">
                                            mittel
                                        </div>
                                        <div class="zone-bar" [class.active]="status.trend === 1"></div>
                                        <div class="zone-duration"
                                             [style.visibility]="status.trend === 1 ? 'visible' : 'hidden'">
                                            {{ status.since}}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="zone-title"
                                             [style.visibility]="status.trend === 2 ? 'visible' : 'hidden'">
                                            hoch
                                        </div>
                                        <div class="zone-bar" [class.active]="status.trend === 2"></div>
                                        <div class="zone-duration"
                                             [style.visibility]="status.trend === 2 ? 'visible' : 'hidden'">
                                            {{ status.since}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noZoneTemplate>
                                <div class="center-contents zone-unavailable">
                                    Verbrauchszone kann nicht ermittelt werden
                                </div>
                            </ng-template>
                        </ng-container>

                        <ng-template #happyHourTemplate>
                            <div class="happy-hour-indicator-row">
                                <app-remaining-time-indicator
                                    #happyHourIndicator
                                    [size]="36"
                                    [overallTime]="60*60"
                                    [refreshrate]="10"
                                    [indicatorWidth]="6"
                                    [inverted]="true">
                                </app-remaining-time-indicator>
                            </div>
                        </ng-template>

                    </ng-container>
                </section>

            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">Live: Was ist das?</h2>
                    <p>
                        <ng-container *ngIf="userService.isEDGUser()">
                            Mit iONA sehen Sie, wie viel Strom während der letzten 15 Minuten und davor verbraucht
                            wurde. Über die Schaltflächen können Sie jederzeit in die Vergangenheit navigieren, um sich
                            ein Bild Ihres Stromverbrauchs zu machen. machen.
                        </ng-container>
                        <ng-container *ngIf="!userService.isEDGUser()">
                            iONA stellt Ihren Stromverbrauch in Echtzeit dar. So sehen Sie jederzeit, wie viel Strom
                            aktuell verbraucht wird. Ob sich dieser Wert im niedrigen, mittleren oder hohen Bereich in
                            Bezug zur Grundlast befindet, erkennen Sie an den oberen drei Balken. Über die Schaltflächen
                            können Sie jederzeit in die Vergangenheit navigieren, um sich ein Bild Ihres Stromverbrauchs
                            zu machen.
                        </ng-container>
                    </p>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <section class="energy-saver-warning" *ngIf="isNotRealtime && energySaverWarningVisible">
                <div class="heading" (click)="toggleWarning($event)">
                    <div class="collapse">
                        <i [class]="energySaverWarningCollapsed ? 'collapsed' : 'expand'"></i>
                    </div>
                    <div class="title"> Energiesparmodus</div>
                    <button id="hide-button" class="iona-icon-only-button close" (click)="hideWarning()"></button>
                </div>
                <ng-container *ngIf="!energySaverWarningCollapsed">
                    <div class="content-wrapper">
                        <div class="content">
                            <div></div>
                            <div>
                                <p>
                                    Ihr optischer Ausleser ist aktuell im Energiesparmodus. Eine Datenübertragung findet
                                    nur nach größeren Veränderungen statt.
                                    <ng-container *ngIf="lastValueTimestamp">
                                        Der letzte Wert ist vom {{lastValueTimestamp}}.
                                    </ng-container>
                                </p>

                                <button class="iona-button orange" (click)="routeToSettings()">Energiesparmodus</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </section>

            <section class="detail-content">

                <div class="live-diagram-head">
                    <ng-container *ngFor="let zoom of zoomLevels">
                        <div *ngIf="zoom.level === currentZoomLevelIdx"
                             class="center-contents font-medium current-zoom">
                            {{zoom.name}}
                            <span>&nbsp;</span>
                            <small class="font-regular" *ngIf="zoom.hint">
                                ({{ zoom.hint }})
                            </small>
                        </div>
                    </ng-container>

                    <div class="live-chart-legend">
                        <div *ngIf="userHasHappyHour">
                            <span class="dot happy-hour"></span> Happy Hour
                        </div>
                        <div>
                            <span class="dot consumption"></span> Verbrauch
                        </div>
                        <div>
                            <span class="dot feedin"></span> Einspeisung
                        </div>
                    </div>

                    <div class="live-diagram-zoom-controls">
                        <button class="iona-icon-only-button timeframe-minus"
                                (click)="zoomOut()"
                                [disabled]="disabled || currentZoomLevelIdx === zoomLevels.length"
                                angulartics2On="click" angularticsCategory="Detailansichten"
                                angularticsAction="detail_time_interval_change" angularticsLabel="Live">
                        </button>
                        <div class="center-contents zoom-level">
                            <div class="dot" title="{{ zoom.name }}"
                                 [class.active]="zoom.level === currentZoomLevelIdx"
                                 *ngFor="let zoom of zoomLevels" (click)="setZoom(zoom.level)">
                            </div>
                        </div>

                        <button class="iona-icon-only-button timeframe-plus"
                                (click)="zoomIn()" [disabled]="disabled || currentZoomLevelIdx === 1"
                                angulartics2On="click" angularticsCategory="Detailansichten"
                                angularticsAction="detail_time_interval_change" angularticsLabel="Live">
                        </button>
                    </div>
                </div>


                <app-live-chart #liveChart
                                (chartLoaded)="onChartLoaded()"
                                isDetail="true">
                </app-live-chart>


                <div class="diagram-controls">
                    <div class="left">
                        <div class="date-input center-contents">
                            <input #dateSelect id="dayInput" type="date"
                                   min="2018-01-01" [max]="today"
                                   (change)="onDateChange(dateSelect.value)">
                            <label class="" for="dayInput">Datum</label>
                        </div>
                        <div class="m-h-s"></div>
                        <button class="iona-icon-only-button prev"
                                [disabled]="disabled"
                                (click)="stepBack()">
                        </button>
                    </div>
                    <div class="center center-contents font-regular">
                        <strong> {{baseDate && specificDateMode ? specifiedDateDisplay : ''}} </strong>
                    </div>
                    <div class="right">
                        <button class="iona-icon-only-button next"
                                [disabled]="disabled || (position === 1 && !specificDateMode)"
                                (click)="stepForward()">
                        </button>
                        <div class="m-h-s"></div>
                        <button class="iona-button small outline"
                                [disabled]="disabled || (position === 1 && !specificDateMode)"
                                (click)="resetPosition()">
                            Jetzt
                        </button>
                    </div>
                </div>

            </section>

        </div>
    </div>
</div>




