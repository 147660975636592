import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private http: HttpClient,
                private userService: UserService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const activeUser = this.userService.getActiveUserName();
        if (activeUser) {
            const token = this.userService.getActiveUserAccessToken();
            if (token) {
                const tokenHeaderValue = `Bearer ${token}`;
                const clonedRequest = req.clone({
                    headers:
                        req.headers.set('Authorization', tokenHeaderValue)
                });
                return next.handle(clonedRequest);
            }
        }
        return next.handle(req);
    }
}
