import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {SmartBridgeService} from '../../services/smart-bridge.service';

@Component({
    selector: 'app-energy-saver-control',
    templateUrl: './energy-saver-control.component.html',
    styleUrls: ['./energy-saver-control.component.scss']
})
export class EnergySaverControlComponent implements OnInit {

    public currentThreshold = 0;
    private readonly thresholdSteps = [25, 50, 150];

    constructor(private popoverRef: PopoverRef,
                private smartBridge: SmartBridgeService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.getThreshold();
    }

    onSliderChange(value): void {
        const filteredValue = this.thresholdSteps[value];
        this.setNewThreshold(filteredValue);
    }


    close(value = false): void {
        if (this.popoverRef) {
            this.popoverRef.close(value);
        }
    }

    private getThreshold(): void {
        this.smartBridge.getBatterySaverThreshold().subscribe((res) => {
            let filteredValue = this.thresholdSteps.findIndex(el => res === el);
            filteredValue = filteredValue >= 0 ? filteredValue : 1;
            this.currentThreshold = filteredValue;
        });
    }

    private setNewThreshold(value): void {
        this.smartBridge.setBatterySaverThreshold(value).subscribe(
            (res) => null,
            (error) => {
                console.log('Error', error);
            }
        );
    }

    private initializePopoverData(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
        this.currentThreshold = this.popoverRef.data.threshold;
    }

}
