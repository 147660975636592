import {Injectable} from '@angular/core';
import {Angulartics2} from 'angulartics2';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private readonly storageAttribute = 'sendAnalyticsData';

    constructor(private analytics: Angulartics2) {
    }

    changeTrackingState(state: boolean): void {
        localStorage.setItem(this.storageAttribute, state ? '1' : '0');
        this.analytics.settings.developerMode = !state;
    }

    getTrackingState(): boolean {
        return localStorage.getItem(this.storageAttribute) === '1';
    }

    private isDisabled(): boolean {
        return this.getTrackingState() === false;
    }

    trackEvent(payload): void {
        if (this.isDisabled()) {
            return;
        }
        this.analytics.eventTrack.next(payload);
    }
}

export interface EventTrackingPayload {
    action: string;
    properties: {
        category?: string,
        label?: string,
        user_group?: string
    };
}


export enum TRACKING_ACTION {
    ONBOARDING_FINISH = 'onboarding_finish',
    ONBOARDING_CANCEL = 'onboarding_cancel',
    ONBOARDING_START = 'onboarding_start',
    ONBOARDING_VOUCHER_CODE_ENTER = 'onboarding_voucher_code_enter'
}

export enum TRACKING_EVENT {
    ONBOARDING_START,
    ONBOARDING_CANCEL,
    ONBOARDING_FINISH,
    ONBOARDING_VOUCER_ENTER,
}
