<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">Stecker</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="PowerChecker"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
                <section class="powerchecker-details-head">
                    <div>
                        <app-power-switch [style]="{containerWidth: 100, elementSize: 50, darkTheme: true}"
                                          [state]="state"
                                          (stateChange)="onPlugStateChange($event)">
                        </app-power-switch>
                    </div>

                    <div>
                        <div class="plug-labels" *ngIf="!editModeEnabled; else editMode">
                            <div>{{plugRoom}} </div>
                            <div>{{plugName}}</div>
                        </div>
                        <ng-template #editMode>
                            <div class="plug-label-inputs">
                                <div>
                                    <input #room type="text" class="iona-input grey"
                                           [(ngModel)]="plugRoom"
                                           placeholder="Mein Zimmer">
                                </div>
                                <div>
                                    <input #name type="text" class="iona-input grey"
                                           [(ngModel)]="plugName"
                                           placeholder="Mein Gerät">
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div>
                        <ng-container *ngIf="!editModeEnabled; else editModeIcon">
                            <button class="iona-icon-only-button round edit"
                                    (click)="onSaveChanges()">
                            </button>
                        </ng-container>
                        <ng-template #editModeIcon>
                            <button class="iona-icon-only-button round save"
                                    (click)="onSaveChanges()">
                            </button>
                        </ng-template>
                    </div>

                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">PowerChecker: Was ist das?</h2>
                    <p>
                        Schalten Sie einen Verbraucher einfach und unkompliziert von Unterwegs Ein und Aus. Der iONA
                        PowerChecker ermöglicht es Ihnen, Ihren smarten Energiehaushalt nicht nur im Blick zu haben,
                        sondern auch aktiv zu steuern. Einfach mal ausprobieren!
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <h3>Entdecke, was dein PowerChecker kann</h3>
                <div class="m-b-m carousel-container">
                    <ngu-carousel #carousel [inputs]="carouselTileConfig" [dataSource]="slideshowAnimations">
                        <ngu-tile *nguCarouselDef="let item; let j = index" class="image-tile">
                            <div lottie [options]="item"></div>
                        </ngu-tile>
                        <div NguCarouselPoint
                             class="image-tile-point-list m-a-s" >
                            <div *ngFor="let image of slideshowAnimations; let i = index"
                                 [class.active]="i===carousel.activePoint"
                                 (click)="carousel.moveTo(i)"
                                 class="image-tile-point m-a-s">
                            </div>
                        </div>
                        <div NguCarouselPrev class="carousel-control carousel-prev">
                            <button class="iona-icon-only-button prev"
                                    [disabled]="carousel.isFirst"></button>
                        </div>
                        <div NguCarouselNext class="carousel-control carousel-next">
                            <button class="iona-icon-only-button next"
                                    [disabled]="carousel.isLast"></button>
                        </div>
                    </ngu-carousel>
                </div>
            </section>


        </div>
    </div>
</div>
