<div class="overlay-minimal">
    <div class="overlay-container-cdk">
        <h1>{{TEXTS.TITLE}}</h1>
        <div>{{TEXTS.DESCRIPTION}}</div>
        <form [formGroup]="form" (ngSubmit)="submitChange()">
            <input formControlName="email1" type="email" class="iona-input m-b-l"
                   [placeholder]="TEXTS.BUTTON_FIRST_PLACEHOLDER">
            <input formControlName="email2" type="email" class="iona-input m-b-l"
                   [placeholder]="TEXTS.BUTTON_SECOND_PLACEHOLDER">
            <button type="submit" class="iona-button orange"
                    [disabled]="submitDisabled">
                {{TEXTS.CHANGE}}
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close(false)"></button>
    </div>
</div>
