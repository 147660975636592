import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../../popover/popover-ref';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-mfa-code-popover',
    templateUrl: './mfa-code-popover.component.html',
    styleUrls: ['./mfa-code-popover.component.scss']
})
export class MfaCodePopoverComponent implements OnInit {

    setupCode = '';

    constructor(public popoverRef: PopoverRef,
                private toastr: ToastrService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    copyCode(): void {
        navigator.clipboard.writeText(this.setupCode);
        this.toastr.info('Der Code wurde in die Zwischenablage kopiert', 'Code kopiert');

    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            if (this.popoverRef.data.nullableBackdrop) {
                this.close(null);
            } else {
                this.close(false);
            }
        });
        try {
            this.setupCode = this.popoverRef.data.setupCode;
        } catch (e) {
            console.log('Data is missing');
        }
    }
}
