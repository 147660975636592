import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../services/api.service';
import {ElectricityService} from '../../../services/electricity.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {static_links} from '../../../shared/constants/web-resources.constants';
import {UserService} from '../../../services/user.service';
import * as moment from 'moment';
import {ApplicationService} from '../../../services/application.service';
import {MONTHS} from '../../../lib/DateUtil';
import {AnimationOptions} from 'ngx-lottie';

@Component({
    selector: 'app-finance-details',
    templateUrl: './finance-details.component.html',
    styleUrls: ['./finance-details.component.scss'],
    viewProviders: [ApiService]
})

export class FinanceDetailsComponent extends BasePopover implements OnInit {

    finance = {
        trend: 0,
        amount: 0,
        percent: 0,
        from: null,
        to: null
    };

    config = {
        notify: 0
    };

    dataAvailable = false;

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/finance-detail-2.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Smart Meter'
    };

    private animation = null;

    infoVisible = false;

    constructor(private toastrService: ToastrService,
                private analytics: TrackAnalyticsService,
                private electricity: ElectricityService,
                protected popoverRef: PopoverRef,
                private userService: UserService,
                private application: ApplicationService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.getFinanceDate();
    }


    getFinanceDate(): void {
        const s = this.electricity.getBillPrediction().subscribe(
            (res: any) => {
                if (!res) {
                    return;
                }
                if (res.sum_of_prepayments > 12) {
                    this.dataAvailable = true;
                    this.extractFinanceData(res);
                    s.unsubscribe();
                }
            }
        );
    }

    formatStartDate(date: Date): string {
        if (this.application.isDemoMode()) {
            const now = new Date();
            return `01.01.${now.getFullYear()}`;
        }
        return this.formatDate(date);
    }

    formatEndDate(date: Date): string {
        if (this.application.isDemoMode()) {
            const now = new Date();
            return `31.12.${now.getFullYear()}`;
        }
        return this.formatDate(date);
    }

    private formatDate(date: Date): string {
        const parsedDate = moment(date);
        return parsedDate
            .format(`DD. ** YYYY`)
            .replace('**', MONTHS[parsedDate.month()]);
    }

    extractFinanceData(data: any): void {
        const estimated_cost = data.estimated_cost_billing_period;
        const sum_prepayments = data.sum_of_prepayments;

        const difference = estimated_cost - sum_prepayments;
        const percentage = 100 * (difference) / sum_prepayments;

        this.dataAvailable = sum_prepayments > 12 && (percentage > -50 && percentage < 50);
        this.finance.percent = Math.round(percentage);

        if (percentage > 10 && difference > 10) { // additional payment
            this.finance.amount = Math.ceil(difference / 10) * 10;
            this.finance.trend = 1;
        } else if (percentage < -10 && difference < -10) { // credit voucher
            this.finance.amount = Math.abs(Math.floor(difference / 10) * 10);
            this.finance.trend = -1;
        } else {
            this.finance.trend = 0;
            this.finance.amount = 0;
        }

        this.finance.from = new Date(data.billing_period_from);
        this.finance.to = new Date(data.billing_period_until);

    }

    openLink(): void {
        const link = static_links.finance[this.userService.getActiveUserProvider()];
        window.open(link);
    }
}
