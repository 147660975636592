export enum LoginTexts {
    REGISTER = 'Erstanmeldung',
    LOGIN = 'Anmelden',
    FORGOT_PASSWORD = 'Passwort vergessen',
    DEMO_MODE = 'Demo Modus',
    EMAIL_ERROR = 'Die Eingabe entspricht nicht einer validen Email-Adresse.',
    PASSWORD_ERROR = 'Das Passwort muss mehr als 6 Zeichen enthalten.',
    BACK_TO_LOGIN = 'Zurück zur Anmeldung',
    WELCOME_LINE_1 = 'Willkommen bei',
    WELCOME_LINE_2 = 'iONA',
    RESET = 'Zurücksetzen',
    FIRST_TIME_1 = 'Sie melden sich zum ersten Mal an und haben einen ',
    FIRST_TIME_2 = 'erhalten?',
    CODE = 'Bestellcode',
    HAS_PASSWORD = 'Ich habe schon ein Passwort',
    FIRST_TIME_LOGIN = 'Erstanmeldung',
    PLACEHOLDER_EMAIL = 'E-Mail-Adresse',
    PLACEHOLDER_PASSWORD = 'Passwort'
}


