import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {
    ConnectionIndicatorConfig
} from '../../../components/connection-indicator/connection-indicator.component';
import {MeterService} from '../../../services/meter.service';
import {InitializationService} from '../../../services/initialization.service';
import {InstantaneousService} from '../../../services/instantaneous.service';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {padNumber} from '../../../lib/Utility';
import {MeterConnectionConfig} from '../../../shared/constants/connection.constants';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {of, throwError} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {MeterTileTexts} from '../../../shared/texts/tiles/meter-tile.texts';
import {AccountRewriteService} from '../../../services/account-rewrite.service';

@Component({
    selector: 'app-meter-tile',
    templateUrl: './meter-tile.component.html',
    styleUrls: ['./meter-tile.component.scss'],
    providers: [Globals]
})

export class MeterTileComponent extends BaseComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.METER;
    readonly TEXTS = MeterTileTexts;

    @Output() tileClicked = new EventEmitter<string>();

    connectionConfig: ConnectionIndicatorConfig = MeterConnectionConfig;
    connection_quality = 0;

    meterStats = {kwh: '000000', id: null,};
    meter_status = 'disconnected';
    meter_connected = false;
    meterId = '';

    constructor(private _globals: Globals,
                private meter: MeterService,
                private initialization: InitializationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private accountRewrite: AccountRewriteService) {
        super();
    }

    ngOnInit() {
        this.getMeterInfoFromInit();
        this.initMeterStatusUpdate();
        this.initMeterValueUpdate();
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return this.TEXTS.LABEL_CONNECTED;
            default:
                return this.TEXTS.LABEL_DISCONNECTED;
        }
    }

    detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    private getMeterInfoFromInit() {
        const s = this.initialization.get(this.accountRewrite.accountRewriteEnabled()).pipe(
            mergeMap((res) => {
                try {
                    return of(res.profile);
                } catch (error) {
                    return throwError(error);
                }
            })
        ).subscribe(
            (res: any) => {
                try {
                    const status = res.meter_status_electricity;
                    if (status === 0) {
                        this.meter_status = 'connected';
                    } else if (status === 1) {
                        this.meter_status = 'disconnected';
                    } else {
                        this.meter_status = 'pending';
                    }
                    this.meter_connected = res.meter_status_electricity === 0;
                    this.meterStats.id = this.formatSerialNumber(res.meter_serial_number);
                } catch (error) {

                }
            },
            (error) => null,
            () => s.unsubscribe()
        );
    }

    private initMeterStatusUpdate(): void {
        this.meter.startLiveUpdate();
        const meterS = this.meter.onMeterStatus.subscribe(
            (res: any) => {
                try {
                    this.connection_quality = res.meter_txrssi;
                } catch (error) {
                    this.connection_quality = 0;
                }
            }
        );
        this.addSub(meterS);
    }

    private initMeterValueUpdate(): void {
        this.meter.getCurrentMeterValue().subscribe((res) => {
            const value = Math.round(res / 1000);
            this.meterStats.kwh = padNumber(value, 6);
        });
    }

    private formatSerialNumber(value: string) {
        if (value === null || value === undefined || value.length < 1) {
            return value;
        }
        let return_str = '';
        for (let i = 0; i < value.length; i++) {
            if (i === 1 || i === 4 || i === 6 || i === 10) {
                return_str += ' ';
            }
            return_str += value.charAt(i);
        }
        return return_str;
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Meter'
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Meter-Details'
            }
        });
    }
}
