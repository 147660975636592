<div id="container">
    <app-menu activeElement="account"></app-menu>
    <div id="inner">
        <div class="page">
            <div class="page-card">
                <h1>{{mainTitle}}</h1>
                <section>
                    <h2>{{TEXTS.ERNA_DESCRIPTION}}</h2>
                    <ul>
                        <li>{{TEXTS.ERNA_PROVIDER}}</li>
                        <li>{{TEXTS.ERNA_METER}}</li>
                        <li>{{TEXTS.ERNA_TIPS}}</li>
                        <li>{{TEXTS.ERNA_MUCHMORE}}</li>
                    </ul>

<!--                    <ng-container *ngIf="isErnaUser else ernaList ">-->
<!--                        <h2>{{TEXTS.DESCRIPTION}}</h2>-->
<!--                        <ul>-->
<!--                            <li>{{TEXTS.ADJUST_CONTRACT_DATA}}</li>-->
<!--                            <li>{{TEXTS.ADJUST_PAYMENT}}</li>-->
<!--                            <li>{{TEXTS.VIEW_BILL}}</li>-->
<!--                        </ul>-->
<!--                    </ng-container>-->
<!--                    <ng-template #ernaList>-->
<!--                        <h2>{{TEXTS.ERNA_DESCRIPTION}}</h2>-->
<!--                        <ul>-->
<!--                            <li>{{TEXTS.ERNA_PROVIDER}}</li>-->
<!--                            <li>{{TEXTS.ERNA_METER}}</li>-->
<!--                            <li>{{TEXTS.ERNA_TIPS}}</li>-->
<!--                            <li>{{TEXTS.ERNA_MUCHMORE}}</li>-->
<!--                        </ul>-->
<!--                    </ng-template>-->

                    <button class="iona-button orange"
                            (click)="openLink()">
                        {{providerMenuName}}
                    </button>
                </section>

            </div>
        </div>
    </div>
</div>


