import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ApiService} from './api.service';
import {ApplicationService} from './application.service';
import {MeterService} from './meter.service';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {VisibilityService} from './visibility.service';
import {HeartbeatService} from './heartbeat.service';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';
import {RegistrationService} from './registration.service';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private api: ApiService,
                private router: Router,
                private application: ApplicationService,
                private meter: MeterService,
                private auth: AuthService,
                private visbility: VisibilityService,
                private registration: RegistrationService,
                private user: UserService,
                private heartbeat: HeartbeatService) {
    }

    canActivate(): Observable<any> {
        const state = this.application.getAppMode();
        if (state === 'demo') {
            if (!this.checkDemoModeValid()) {
                this.router.navigate(['/login']);
                return of(false);
            }
            return of(true);
            // }
            // else if (state === 'none') {
            //     console.log('is none');
            //     this.router.navigate(['/login']);
            //     return of(false);
        } else if (state === null || state === 'undefined') {
            this.router.navigate(['/login']);
            return of(false);
        }

        if (!this.user.getActiveUserName()) {
            this.router.navigate(['/login']);
            return of(false);
        }

        return this.meter.getStatus(false).pipe(
            mergeMap((res) => {
                if (res) {
                    this.visbility.init();
                    this.auth.startContinuousTokenRefresh();
                    return of(true);
                }
                return of(false);
            }),
            mergeMap(result => {
                if (!result) {
                    return of(result);
                }
                return this.registration.getOptInStatus().pipe(
                    mergeMap(status => {
                        if (!status) {
                            localStorage.setItem(StorageAttributes.OPT_IN_REQUIRED, '0');
                        }
                        return of(true);
                    })
                );
            }),
            catchError((error) => {
                return this.auth.refreshToken().pipe(
                    mergeMap((res) => {
                        if (res) {
                            this.auth.startContinuousTokenRefresh();
                            return of(res);
                        }
                    }),
                );
            })
        );
    }

    private checkDemoModeValid(): boolean {
        const now = new Date();
        const expire = new Date(localStorage.getItem(StorageAttributes.APP_EXPIRY));
        return expire > now;
    }

}
