import {static_links} from '../shared/constants/web-resources.constants';
import {imprintBaseUrl, imprintUrls} from '../shared/constants/imprint.constants';


export const getProviderIcon = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }
    switch (provider.toLowerCase()) {
        default:
            return 'logo-eon';
    }
};

export const getActualProviderName = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }
    switch (provider.toLowerCase()) {
        case 'innogy':
            return 'E.ON';
        case 'enviam':
            return 'EnviaM';
        case 'eprimo':
            return 'eprimo';
        case 'energis':
            return 'Energis';
        case 'lew':
            return 'lew';
        case 'e.on':
            return 'eon';
        case 'avacon':
            return 'avacon';
        case 'schleswig-holstein netz':
            return 'shn';
        case 'bayernwerk netz':
            return 'bn';
        case 'edis':
        case 'e.dis':
            return 'edis';
        default:
            return 'eon';
    }
};

export const getProviderIconClass = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }
    switch (provider.toLowerCase()) {
        case 'innogy':
            return 'eon';
        case 'enviam':
            return 'envia';
        case 'eprimo':
            return 'eprimo';
        case 'energis':
            return 'energis';
        case 'lew':
            return 'lew';
        case 'e.on':
            return 'eon';
        case 'avacon':
            return 'avacon';
        case 'schleswig-holstein netz':
            return 'shn';
        case 'bayernwerk netz':
            return 'bn';
        case 'edis':
        case 'e.dis':
            return 'edis';
        default:
            return 'eon';
    }
};

export const getProviderSettingsLink = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }

    switch (provider.toLowerCase()) {
        case 'innogy':
            return static_links.settings.innogy;
        case 'enviam':
            return static_links.settings.enviam;
        case 'eprimo':
            return static_links.settings.eprimo;
        case 'energis':
            return static_links.settings.energis;
        case 'lew':
            return static_links.settings.lew;
        case 'e.on':
            return static_links.settings['e.on'];
        case 'avacon':
            return static_links.settings.avacon;
        case 'schleswig-holstein netz':
            return static_links.settings.shn;
        case 'bayernwerk netz':
            return static_links.settings.bn;
        case 'edis':
        case 'e.dis':
            return static_links.settings['e.dis'];
        default:
            return static_links.settings['e.on'];

    }
};


export const ProviderMappings = [
    {name: 'innogy', displayName: 'E.ON', menuName: 'Mein E.ON'},
    {name: 'opto', displayName: 'OPTO', menuName: 'Mein E.ON'},
    {name: 'enviam', displayName: 'enviaM', menuName: 'Meine enviaM'},
    {name: 'eprimo', displayName: 'eprimo', menuName: 'Mein eprimo'},
    {name: 'energis', displayName: 'energis', menuName: 'Meine energis'},
    {name: 'lew', displayName: 'LEW', menuName: 'Meine LEW'},
    {name: 'e.on', displayName: 'E.ON', menuName: 'Mein E.ON'},
    {name: 'eon', displayName: 'E.ON', menuName: 'Mein E.ON'},
    {name: 'avacon', displayName: 'Avacon', menuName: 'Meine Avacon'},
    {
        name: 'schleswig-holstein netz',
        displayName: 'Schleswig-Holstein Netz',
        menuName: 'Meine SH-Netz'
    },
    {name: 'bayernwerk netz', displayName: 'Bayernwerk Netz', menuName: 'Mein Bayernwerk'},
    {name: 'edis', displayName: 'E.DIS', menuName: 'Meine E.DIS'},
    {name: 'e.dis', displayName: 'E.DIS', menuName: 'Meine E.DIS'},
];

export const getProviderMenuName = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }

    switch (provider.toLowerCase()) {
        case 'innogy':
            return 'Mein E.ON';
        case 'enviam':
            return 'Meine enviaM';
        case 'eprimo':
            return 'Mein eprimo';
        case 'energis':
            return 'Meine energis';
        case 'lew':
            return 'Meine LEW';
        case 'e.on':
            return 'Mein E.ON';
        case 'avacon':
            return 'Meine Avacon';
        case 'schleswig-holstein netz':
            return 'Meine SH-Netz';
        case 'bayernwerk netz':
            return 'Mein Bayernwerk';
        case 'edis':
        case 'e.dis':
            return 'Meine E.DIS';
        default:
            return 'Mein E.ON';
    }
};

export const getProviderImprintUrl = (provider: string): string => {
    switch (provider) {
        case 'innogy':
            return imprintBaseUrl + imprintUrls.innogy;
        case 'enviam':
            return imprintBaseUrl + imprintUrls.enviam;
        case 'eprimo':
            return imprintBaseUrl + imprintUrls.eprimo;
        case 'energis':
            return imprintBaseUrl + imprintUrls.energis;
        case 'lew':
            return imprintBaseUrl + imprintUrls.lew;
        case 'e.on':
            return imprintBaseUrl + imprintUrls.esc;
        case 'avacon':
            return imprintBaseUrl + imprintUrls.avacon;
        case 'schleswig-holstein netz':
            return imprintBaseUrl + imprintUrls.sh;
        case 'bayernwerk netz':
            return imprintBaseUrl + imprintUrls.bh;
        case 'edis':
        case 'e.dis':
            return imprintBaseUrl + imprintUrls.edis;
        default:
            return imprintBaseUrl + imprintUrls.esc;
    }
};









