import {Component, OnDestroy, OnInit} from '@angular/core';

import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {getDayName} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import * as moment from 'moment';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Observable, of, Subscription, timer} from 'rxjs';
import {TodayDetailTexts} from '../../../shared/texts/detail/today-detail.texts';

@Component({
    selector: 'app-today-details',
    templateUrl: './today-details.component.html',
    styleUrls: ['./today-details.component.scss'],
    viewProviders: []
})

export class TodayDetailsComponent extends BasePopover implements OnInit, OnDestroy {
    readonly TEXTS = TodayDetailTexts;

    private updateIntervalSub: Subscription = null;
    private dateFormatDisplay = 'DD.MM.YYYY';

    currentComparisonDate = moment().subtract(7, 'days').toDate();

    todayDate = moment().format('MM.DD.YYYY');

    today: SingleDayComparisonData = {
        consumption: 0,
        costs: 0.0,
        hours: []
    };

    comparisonDate: SingleDayComparisonData = {
        consumption: 0,
        costs: 0.0,
        hours: []
    };

    trend: TodayTrend = {
        direction: 0,
        percentage: 0,
        scale: {
            left: 1,
            right: 1
        }
    };

    leftState = 'inactive';
    rightState = 'inactive';
    infoVisible = false;
    dateChanged = false;
    showEntireList = false;

    /*
       TODO:
         - put all calculations and mappings in a separate service since both, tile and detail
           basically do the same thing
    */
    constructor(private apiService: ApiService,
                private mockDataService: MockDataService,
                private electricityService: ElectricityService,
                protected popoverRef: PopoverRef) {
        super(popoverRef);
    }


    ngOnInit() {
        this.initializeApiConnection();
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.updateIntervalSub) {
            this.updateIntervalSub.unsubscribe();
            this.updateIntervalSub = null;
        }
    }


    /**
     * Callback on comparison date change
     * @param selectedValue
     */
    onComparisonDateChange(selectedValue: Date) {
        this.currentComparisonDate = selectedValue;
        this.dateChanged = true;
        this.initializeApiConnection();
    }


    /**
     * Returns the current comparison date with the correct format
     */
    formatCurrentComparisonDate() {
        return moment(this.currentComparisonDate).format(this.dateFormatDisplay);
    }


    /**
     * Returns the name of the comparison date day
     */
    getComparisonDateDayName() {
        const difference = moment().diff(this.currentComparisonDate, 'days');
        return getDayName(difference);
    }


    /**
     * Extracts the real portion of a passed floating point value
     * @param value
     */
    extractRealValue(value: string): string {
        return value.split(',')[0] + ',';
    }


    /**
     * Extract the decimal part of a passed floating point value
     * @param value
     */
    extractDecimalValue(value: string): string {
        return value.split(',')[1];
    }


    /**
     * Formats a numerical value
     * @param value
     */
    formatValue(value: number) {
        if (value === 0.0) {
            return '–';
        }
        const ret = value.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        );
        return ret.trim();
    }

    /**
     * Toggle whether the entire list of hours should be shown
     */
    toggleEntireListDisplayal(state): void {
        this.today.hours = [];
        this.showEntireList = state;
        this.initializeApiConnection();
    }


    /**
     * Request current day and historical data
     * Align data and return both
     */
    private requestData$(): Observable<TodayData> {
        return this.electricityService.getConsumptionForDay(0).pipe(
            map(data => this.alignData(data)),
            mergeMap(today => {
                const comparisonDateOffset = moment()
                    .diff(this.currentComparisonDate, 'days');

                return this.electricityService.getConsumptionForDay(comparisonDateOffset)
                    .pipe(
                        map(data => this.alignData(data)),
                        mergeMap(comparisonDate => {
                            return of({today, comparisonDate});
                        })
                    );
            })
        );
    }


    /**
     * Aligns an API dataset for a single day by only considering the data to the current hour
     * @param dataset
     */
    private alignData(dataset: any): SingleDayComparisonData {
        let consumption = 0;
        let costs = 0;
        const hourData = [];
        for (const hourValue of dataset) {
            const hourObject: SingleHourData = {
                costs: 0, hour: 0, consumption: 0
            };

            const dataPointTs = moment(hourValue.timestamp).toDate();
            const now = new Date();

            if (!this.showEntireList) {
                if (dataPointTs.getHours() > now.getHours()) {
                    continue;
                }
            }

            if ('measured' in hourValue) {
                if (dataPointTs.getHours() <= now.getHours()) {
                    consumption += hourValue.measured;
                }
                hourObject.hour = new Date(hourValue.timestamp).getHours();
                hourObject.consumption = (hourValue.measured / 1000);
            }
            if ('cost_measured' in hourValue) {
                if (dataPointTs.getHours() <= now.getHours()) {
                    costs += hourValue.cost_measured;
                }
                hourObject.costs = hourValue.cost_measured;
            }

            hourData.push(hourObject);
        }
        return {
            consumption: (consumption) / 1000,
            costs,
            hours: hourData.reverse()
        };
    }


    /**
     * Determine the current trend based on the fetched and aligned data
     * @param values
     */
    private determineTrend(values: TodayData): void {
        this.today = values.today;
        this.comparisonDate = values.comparisonDate;

        let percentage = 0;
        let trendDirection = 0;
        let scale = 0;
        let leftScale = 1;
        let rightScale = 1;
        if (this.today.consumption > this.comparisonDate.consumption) {
            const temp = ((this.today.consumption - this.comparisonDate.consumption)
                / this.comparisonDate.consumption) * 100;
            percentage = Math.round(temp);
            trendDirection = 1;
            scale = percentage > 80 ? 20 : 100 - percentage;
            leftScale = scale / 100;
            rightScale = 1;
        } else if (this.today.consumption < this.comparisonDate.consumption) {
            const temp = this.today.consumption / this.comparisonDate.consumption;
            percentage = Math.round((1 - (temp)) * 100);
            trendDirection = -1;
            scale = percentage > 80 ? 20 : 100 - percentage;
            leftScale = 1;
            rightScale = scale / 100;
        }

        this.trend.direction = trendDirection;
        this.trend.percentage = percentage;
        this.trend.scale.left = leftScale;
        this.trend.scale.right = rightScale;

        // determine ui state
        if (this.comparisonDate.consumption === 0 && this.today.consumption === 0) {
            return;
        }

        if (this.comparisonDate.consumption > this.today.consumption) {
            this.leftState = 'inactive';
            this.rightState = 'active';
        } else if (this.comparisonDate.consumption < this.today.consumption) {
            this.rightState = 'higher';
            this.leftState = 'inactive';
        } else if (this.comparisonDate.consumption === this.today.consumption) {
            this.rightState = 'active';
            this.leftState = 'active';
        }
    }

    /**
     * Initializes continuous data update
     */
    private initializeApiConnection(): void {
        if (this.updateIntervalSub) {
            this.updateIntervalSub.unsubscribe();
            this.updateIntervalSub = null;
        }
        this.updateIntervalSub = timer(0, 10000).pipe(
            mergeMap(() => this.requestData$()),
            catchError(error => {
                console.log('Error:', error);
                return of(null);
            })
        ).subscribe((values: TodayData | null) => {
            if (!values) {
                return;
            }
            this.determineTrend(values);
        });
    }
}

export interface TodayData {
    today: SingleDayComparisonData;
    comparisonDate: SingleDayComparisonData;
}

export interface SingleDayComparisonData {
    consumption: number;
    costs: number;
    hours: SingleHourData[];
}

export interface SingleHourData {
    consumption: number;
    costs: number;
    hour: number;
}

export interface TodayTrend {
    direction: number;
    percentage: number;
    scale: {
        left: number
        right: number
    };
}
