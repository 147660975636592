import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {MockDataService} from '../../../services/mock-data.service';
import * as moment from 'moment';
import {months} from 'moment';
import {getWeek, MONTHS} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {HappyHourService} from '../../../services/happy-hour.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Tariff} from '../../../classes/user';
import {UserService} from '../../../services/user.service';
import {ProfileService} from '../../../services/profile.service';
import {Router} from '@angular/router';
import {SeriesBarOptions} from 'highcharts';

@Component({
    selector: 'app-comparison-details',
    templateUrl: './comparison-details.component.html',
    styleUrls: ['comparison-details.component.scss'],
    viewProviders: []
})
export class ComparisonDetailsComponent extends BasePopover implements OnInit, AfterViewInit {

    readonly comparisonTimeframe = ComparisonTimeframe;
    readonly comparisonMode = ComparisonMode;
    readonly comparisonDataMode = ComparisonDataMode;
    readonly comparisonDisplayMode = ComparisonDisplayMode;
    readonly validMonths = MONTHS;

    private onUserHasHappyHour = new Subject<boolean>();
    private onDataModeChange = new Subject<ComparisonDataMode>();

    currentMode = ComparisonMode.STATIC;
    currentTimeframe = ComparisonTimeframe.MONTH;
    currentDisplayMode = ComparisonDisplayMode.CONSUMPTION;
    currentDataMode = ComparisonDataMode.REGULAR;

    position = 1;
    year = 0;
    limit = 6;

    comparisonDates = {
        left_day: 0,
        left_kw: 0,
        left_month: 0,
        left_year: 0,
        right_day: 0,
        right_kw: 0,
        right_month: 0,
        right_year: 0
    };

    chart: Chart = null;
    seriesLegend = [];
    disabled = true;

    lastYearColor = '#d27300';
    thisYearColor = '#f59b00';
    positionFinal = false;

    isErnaUser = false;
    userHasHappyHour = false;
    tariffs: Tariff[] = null;
    tariffsAvailable = false;
    warningCollapsed = true;
    infoVisible = false;

    constructor(protected popoverRef: PopoverRef,
                public mockDataService: MockDataService,
                public application: ApplicationService,
                public userService: UserService,
                private analytics: TrackAnalyticsService,
                private electricityService: ElectricityService,
                private happyHour: HappyHourService,
                private router: Router,
                private profile: ProfileService
    ) {
        super(popoverRef);
    }

    ngOnInit() {
        this.constructComparisonDates();
        this.initializeHappyHour();
        this.initializeModeChange();
        this.initializeStaticChart();
        this.initializeTariffs();
    }

    ngAfterViewInit(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        if (this.userService.isEnviamUser()) {
            this.determineUserHasHappyHour();
        }
    }


    setMode(newMode: ComparisonMode) {
        if (newMode === this.currentMode) {
            return;
        }
        this.currentMode = newMode;
        this.resetChart();

        this.trackModeChangeEvent();
    }

    setTimeframe(newTimeframe: ComparisonTimeframe) {
        if (this.disabled) {
            return;
        }
        if (newTimeframe !== this.currentTimeframe) {
            this.currentTimeframe = newTimeframe;
            this.position = 1;
            this.constructComparisonDates();
            this.resetChart();
        }

    }

    setCompare(key, value) {
        this.comparisonDates[key] = parseInt(value, 10);
        this.resetChart();
    }

    positionForward() {
        if ((this.position > 1) && (!this.disabled)) {
            this.position--;
            this.resetChart();
        }
    }

    positionBack() {
        if (!this.disabled) {
            this.position++;
            this.resetChart();
        }
    }

    positionNow() {
        if (!this.disabled) {
            this.position = 1;
            this.resetChart();
        }
    }

    resetChart() {
        if (this.chart.ref) {
            this.chart.ref.showLoading();
        }

        this.disabled = true;
        if (this.currentMode === ComparisonMode.DYNAMIC) {
            this.initializeDynamicChart();
        } else {
            this.initializeStaticChart();
        }
    }

    setValueMode(mode: ComparisonDisplayMode) {
        this.currentDisplayMode = mode;
        this.resetChart();
    }

    loop(start: number, times: number) {
        const loop = [];
        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }
        return loop;
    }


    toggleWarning(): void {
        this.warningCollapsed = !this.warningCollapsed;
    }

    routeToProfile(): void {
        this.close(false);
        this.router.navigate(['meine-daten']);
    }

    setDataMode(mode: ComparisonDataMode): void {
        this.onDataModeChange.next(mode);
    }

    determineCurrentBasePrice(): string {
        if (!this.tariffs) {
            return;
        }
        let unit = '€ / ';
        let valueFormatted = this.tariffs[0].basePrice;
        switch (this.currentTimeframe) {
            case ComparisonTimeframe.DAY:
                unit += 'Tag';
                valueFormatted = valueFormatted / 365;
                break;
            case ComparisonTimeframe.WEEK:
                unit += 'Woche';
                valueFormatted = valueFormatted / 52.1429;
                break;
            case ComparisonTimeframe.MONTH:
                unit += 'Monat';
                valueFormatted = valueFormatted / 12;
                break;
            case ComparisonTimeframe.YEAR:
                unit += 'Jahr';
                break;
        }
        const valueFormattedStr = valueFormatted.toLocaleString('de-DE', {
            maximumFractionDigits: 2
        });
        return `${valueFormattedStr} ${unit}`;
    }


    private constructComparisonDates() {
        const dateRight = new Date();
        const dateLeft = new Date();

        this.year = dateRight.getFullYear();

        switch (this.currentTimeframe) {
            case ComparisonTimeframe.WEEK: { // Woche
                dateLeft.setDate(dateRight.getDate() - 7);
                break;
            }
            case ComparisonTimeframe.MONTH: { // Monat
                dateLeft.setMonth(dateRight.getMonth() - 1);
                break;
            }
            case ComparisonTimeframe.YEAR: { // Jahr
                dateLeft.setFullYear(dateRight.getFullYear() - 1);
                break;
            }
            default: { // Tag
                dateLeft.setDate(dateRight.getDate() - 1);
            }
        }

        this.comparisonDates = {
            left_day: dateLeft.getDate(),
            left_kw: getWeek(dateLeft),
            left_month: dateLeft.getMonth() + 1,
            left_year: dateLeft.getFullYear(),
            right_day: dateRight.getDate(),
            right_kw: getWeek(dateRight),
            right_month: dateRight.getMonth() + 1,
            right_year: dateRight.getFullYear()
        };
    }


    /**
     * Gather data for static chart and map them according to the different modes
     */
    private getStaticDiagramData() {
        const years = [
            {year: this.year - 1, color: '#D27300'},
            {year: this.year, color: '#F59B00'}
        ];
        if (this.chart) {
            this.chart.ref.showLoading('Auswertung wird geladen…');
        }
        for (const year of years) {
            const s = of(this.currentDisplayMode).pipe(
                mergeMap(mode => {
                    const dates = this.determineStaticDatesByTimeframe(year);
                    switch (mode) {
                        case ComparisonDisplayMode.CONSUMPTION:
                        case ComparisonDisplayMode.COST:
                            return this.electricityService.getConsumptionForTimeframe(
                                dates.from, dates.to, dates.timeframe);
                        case ComparisonDisplayMode.FEEDIN:
                            return this.electricityService.getFeedinForTimeframe(
                                dates.from, dates.to, dates.timeframe);
                    }
                }),
                mergeMap((res) => {
                    if (!res) {
                        return throwError(null);
                    }
                    if (res.length === 0) {
                        return throwError(null);
                    }
                    return of(res);
                }),
                map((res: any) => {
                    let categories = [];
                    const series = {name: null, data: [], color: '#ffffff', originalData: res};

                    switch (this.currentTimeframe) {
                        case ComparisonTimeframe.WEEK: {
                            const consumptions = [];
                            for (const con of res) {
                                const kw = getWeek(new Date(con.timestamp));
                                const value = this.determineSeriesValue(con);

                                const index = consumptions.map((el) => el.kw).indexOf(kw);
                                if (index >= 0) {
                                    consumptions[index].wh += value;
                                } else {
                                    consumptions.push({kw, ts: con.timestamp, wh: value});
                                }
                            }
                            for (const c of consumptions) {
                                categories.push('KW ' + c.kw);
                            }
                            const mapped = consumptions.map(
                                (c) => Number(((c.wh) / 1000).toFixed(2))
                            );
                            series.name = new Date(res[res.length - 1].timestamp)
                                .getFullYear().toString();
                            series.data = mapped;
                            series.color = year.color;

                            break;
                        }
                        case ComparisonTimeframe.YEAR: {
                            let consumption = 0;
                            for (const con of res) {
                                consumption += this.determineSeriesValue(con);
                            }

                            const mapped =
                                [Number(((consumption) / 1000).toFixed(2))];
                            categories = ['', ''];

                            series.name = year.year;
                            series.data = mapped;
                            series.color = year.color;
                            break;
                        }
                        default: {
                            for (const con of res) {
                                const date = new Date(con.timestamp);
                                switch (this.currentTimeframe) {
                                    case ComparisonTimeframe.MONTH: {
                                        categories.push(this.validMonths[date.getMonth()]);
                                        break;
                                    }
                                    default: {
                                        categories.push(date.getDate() + '. ' +
                                            this.validMonths[date.getMonth()]);
                                    }
                                }
                            }

                            const mapped = res.map(con => {
                                const value = this.determineSeriesValue(con);
                                return Number((value / 1000).toFixed(2));
                            });

                            series.name = new Date(res[res.length - 1].timestamp)
                                .getFullYear().toString();
                            series.data = mapped;
                            series.color = year.color;
                        }

                    }

                    this.disabled = false;
                    return {categories, series};
                }),
                catchError((error) => {
                    if (this.chart.ref) {
                        this.chart.ref.showLoading(
                            'Keine Auswertung für diesen Zeitraum verfügbar!');
                        this.disabled = false;
                    }
                    return of(false);
                })
            ).subscribe(
                (result: any) => {
                    if (!result) {
                        return;
                    }
                    this.chart.addSeries(result.series, true, true);
                    this.chart.ref.update({xAxis: {categories: result.categories}});
                    this.chart.ref.hideLoading();
                    s.unsubscribe();
                },
            );

        }

    }

    private getMockStatic(): void {
        for (const year of [
            {year: this.year - 1, color: this.lastYearColor},
            {year: this.year, color: this.thisYearColor}
        ]) {
            const req_params = this.determineStaticDatesByTimeframe(year);
            const from = req_params.from;
            const to = req_params.to;
            const api = req_params.timeframe;

            const s = this.mockDataService.getConsumptionByDate(api, from, to).subscribe(
                (data: any) => {
                    if (!('data' in data)) {
                        return;
                    }
                    if (data.data.length < 1) {
                        return;
                    }
                    let categories: any = [];

                    switch (this.currentTimeframe) {
                        case ComparisonTimeframe.WEEK: {
                            const consumption = [];
                            for (const con of data.data) {
                                const kw = getWeek(new Date(con.timestamp));
                                const index = consumption.map(c => c.kw).indexOf(kw);
                                if (index > -1) {
                                    if ('measured' in con) {
                                        consumption[index].wh += con.measured;
                                    }
                                } else {
                                    const wh = 'measured' in con ? con.measured : 0;
                                    consumption.push({kw, wh});
                                }
                            }
                            for (const con of consumption) {
                                categories.push('KW ' + con.kw);
                            }

                            const series: any = {
                                name: new Date(data.data.last().timestamp).getFullYear().toString(),
                                type: 'bar',
                                data: consumption.map((con: any) =>
                                    Number((con.wh / 1000).toFixed(2))
                                ),
                                color: year.color
                            };
                            this.chart.addSeries(series, true, true);
                            break;
                        }
                        case ComparisonTimeframe.YEAR: {
                            let consumption = 0;
                            for (const con of data.data) {
                                consumption += ('measured' in con) ? con.measured : 0;
                            }
                            categories = ['', ''];
                            const series: any = {
                                name: year.year,
                                data: [Number((consumption / 1000).toFixed(2))],
                                color: year.color
                            };
                            this.chart.addSeries(series, true, true);
                            break;
                        }
                        default: {
                            for (const con of data.data) {
                                const date = new Date(con.timestamp);
                                switch (this.currentTimeframe) {
                                    case ComparisonTimeframe.MONTH: {
                                        categories.push(this.validMonths[date.getMonth()]);
                                        break;
                                    }
                                    default: {
                                        categories.push(
                                            date.getDate() + '. ' + this.validMonths[date.getMonth()]
                                        );
                                    }
                                }
                            }
                            const series: any = {
                                name: new Date(data.data.last().timestamp).getFullYear().toString(),
                                data:
                                    data.data.map((con: any) => {
                                        return Number((con.measured / 1000).toFixed(2));
                                    }),
                                color: year.color
                            };
                            this.chart.addSeries(series, true, true);
                        }
                    }
                    this.chart.ref.update({xAxis: {categories}});

                    // if (this.chart.ref) {
                    this.chart.ref.hideLoading();
                    // }

                    this.disabled = false;
                },
                () => {
                    if (this.chart.ref) {
                        this.chart.ref.showLoading(
                            'Keine Auswertung für diesen Zeitraum verfügbar!');
                    }
                    this.disabled = false;
                }
            );
            this.addSub(s);
        }
    }

    private getDynamicDiagramData() {
        let left_from = new Date(0);
        let left_to = new Date(0);

        let right_from = new Date(0);
        let right_to = new Date(0);

        let api = null;

        this.chart.ref.showLoading('Auswertung wird geladen…');

        switch (this.currentTimeframe) {
            case ComparisonTimeframe.WEEK: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.comparisonDates.left_year);
                left_from.setDate(left_from.getDate() + (this.comparisonDates.left_kw * 7) - 7);

                left_to = new Date(0);
                left_to.setFullYear(this.comparisonDates.left_year);
                left_to.setDate(left_to.getDate() + (this.comparisonDates.left_kw * 7) - 1);

                right_from = new Date(0);
                right_from.setFullYear(this.comparisonDates.right_year);
                right_from.setDate(right_from.getDate() + (this.comparisonDates.right_kw * 7) - 7);

                right_to = new Date(0);
                right_to.setFullYear(this.comparisonDates.right_year);
                right_to.setDate(right_to.getDate() + (this.comparisonDates.right_kw * 7) - 1);

                api = 'days';

                break;
            }
            case ComparisonTimeframe.MONTH: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.comparisonDates.left_month - 1);
                left_from.setFullYear(this.comparisonDates.left_year);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.comparisonDates.right_month - 1);
                right_from.setFullYear(this.comparisonDates.right_year);

                right_to = right_from;

                api = 'months';

                break;
            }
            case ComparisonTimeframe.YEAR: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.comparisonDates.left_year);

                left_to = new Date(0);
                left_to.setFullYear(this.comparisonDates.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);

                right_from = new Date(0);
                right_from.setFullYear(this.comparisonDates.right_year);

                right_to = new Date(0);
                right_to.setFullYear(this.comparisonDates.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);

                api = 'months';

                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.comparisonDates.left_year);
                left_from.setMonth(this.comparisonDates.left_month - 1);
                left_from.setDate(this.comparisonDates.left_day);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setFullYear(this.comparisonDates.right_year);
                right_from.setMonth(this.comparisonDates.right_month - 1);
                right_from.setDate(this.comparisonDates.right_day);

                right_to = right_from;

                api = 'days';
            }
        }

        let left$ = this.electricityService.getConsumptionForTimeframe(left_from, left_to, api)
            .pipe(catchError(error => of(null)));
        let right$ = this.electricityService.getConsumptionForTimeframe(right_from, right_to, api)
            .pipe(catchError(error => of(null)));
        if (this.currentDisplayMode === ComparisonDisplayMode.FEEDIN) {
            left$ = this.electricityService.getFeedinForTimeframe(left_from, left_to, api)
                .pipe(catchError(error => of(null)));
            right$ = this.electricityService.getFeedinForTimeframe(right_from, right_to, api)
                .pipe(catchError(error => of(null)));
        }

        const sub = forkJoin({left: left$, right: right$}).pipe(
            map((res: any) => {
                const consumptions = {
                    left: {value: 0, timestamp: null},
                    right: {value: 0, timestamp: null}
                };
                for (const key of Object.keys(res)) {
                    if (res[key]) {
                        for (const consumption of res[key]) {
                            const value = this.determineSeriesValue(consumption);
                            consumptions[key].timestamp = consumption.timestamp;
                            consumptions[key].value += value;
                        }
                    }
                }
                return consumptions;
            })
        ).subscribe(
            (results) => {
                if (!results) {
                    if (this.chart.ref) {
                        this.chart.ref.showLoading(
                            'Keine Auswertung für diesen Zeitraum verfügbar!'
                        );
                    }
                    this.disabled = false;
                }
                let left_name = '';
                let right_name = '';
                const ldate = moment(results.left.timestamp);
                const rdate = moment(results.right.timestamp);
                switch (this.currentTimeframe) {
                    case ComparisonTimeframe.DAY: {
                        left_name = ldate.format('DD.MM.YYYY');
                        right_name = rdate.format('DD.MM.YYYY');
                        break;
                    }
                    case ComparisonTimeframe.WEEK: {
                        left_name = `KW ${ldate.week()} ${ldate.year()}`;
                        right_name = `KW ${rdate.week()} ${rdate.year()}`;
                        break;
                    }
                    case ComparisonTimeframe.MONTH: {
                        left_name = `${ldate.locale('de-DE').format('MMMM YYYY')}`;
                        right_name = `${rdate.locale('de-DE').format('MMMM YYYY')}`;
                        break;
                    }
                    case ComparisonTimeframe.YEAR: {
                        left_name = ldate.year().toString();
                        right_name = rdate.year().toString();
                        break;
                    }
                }
                const series: any = {
                    name: null,
                    data: [
                        {
                            name: left_name,
                            y: Number((results.left.value / 1000).toFixed(2)),
                            color: this.lastYearColor
                        },
                        {
                            name: right_name,
                            y: Number((results.right.value / 1000).toFixed(2)),
                            color: this.thisYearColor
                        }
                    ]
                };
                this.chart.addSeries(series, true, true);
                if (this.chart.ref) {
                    this.chart.ref.hideLoading();
                }
                this.disabled = false;
            },
            (error) => sub.unsubscribe(),
            () => sub.unsubscribe()
        );
    }

    private getMockDynamic(): void {
        const times = this.determineDynamicDatesByTimeframe();
        const left_from = times.left_from;
        const left_to = times.left_to;
        const right_from = times.right_from;
        const right_to = times.right_to;
        const api: string = times.api;

        let left_wh = 0;
        let right_wh = 0;

        const s1 = this.mockDataService.getConsumptionByDate(api, left_from, left_to).subscribe(
            (data: any) => {
                if ('data' in data) {
                    for (const con of data.data) {
                        left_wh += 'measured' in con ? con.measured : 0;
                    }

                    const s2 = this.mockDataService.getConsumptionByDate(
                        api, right_from, right_to)
                        .subscribe(
                            (data2: any) => {
                                if ('data' in data2) {
                                    for (const con of data2.data) {
                                        if ('measured' in con) {
                                            right_wh += con.measured;
                                        }
                                    }

                                    let left_name = '';
                                    let right_name = '';
                                    const ldate = moment(data.data[0].timestamp);
                                    const rdate = moment(data2.data[0].timestamp);
                                    switch (this.currentTimeframe) {
                                        case ComparisonTimeframe.DAY: {
                                            left_name = ldate.format('DD.MM.YYYY');
                                            right_name = rdate.format('DD.MM.YYYY');
                                            break;
                                        }
                                        case ComparisonTimeframe.WEEK: {
                                            left_name = `KW ${ldate.week()} ${ldate.year()}`;
                                            right_name = `KW ${rdate.week()} ${rdate.year()}`;
                                            break;
                                        }
                                        case ComparisonTimeframe.MONTH: {
                                            left_name = `${ldate.locale('de-DE')
                                                .format('MMMM YYYY')}`;
                                            right_name = `${rdate.locale('de-DE')
                                                .format('MMMM YYYY')}`;
                                            break;
                                        }
                                        case ComparisonTimeframe.YEAR: {
                                            left_name = ldate.year().toString();
                                            right_name = rdate.year().toString();
                                            break;
                                        }
                                    }
                                    const series: any = {
                                        name: null,
                                        data: [
                                            {
                                                name: left_name,
                                                y: Number((left_wh / 1000).toFixed(2)),
                                                color: this.lastYearColor
                                            },
                                            {
                                                name: right_name,
                                                y: Number((right_wh / 1000).toFixed(2)),
                                                color: this.thisYearColor
                                            },
                                        ]
                                    };
                                    this.chart.addSeries(series, true, true);
                                    if (this.chart.ref) {
                                        this.chart.ref.hideLoading();
                                    }
                                    this.disabled = false;
                                }
                            },
                            () => {
                                if (this.chart.ref) {
                                    this.chart.ref.showLoading(
                                        'Keine Auswertung für diesen Zeitraum verfügbar!');
                                }
                                this.disabled = false;
                            }
                        );
                    this.addSub(s2);
                }
            },
            () => {
                if (this.chart.ref) {
                    this.chart.ref.showLoading(
                        'Keine Auswertung für diesen Zeitraum verfügbar!');
                }
                this.disabled = false;
            }
        );
        this.addSub(s1);
    }


    private getStaticDiagramDataForHappyHour(): void {
        let api_mode: 'day' | 'week' | 'month' | 'year';
        let left_start;
        let left_end;
        let right_start;
        let right_end;

        const day_format = 'YYYY-MM-DD';
        const month_format = 'YYYY-MM';

        if (this.chart.ref) {
            this.chart.ref.showLoading('Auswertung wird geladen…');
        }

        this.positionFinal = false;
        // determine start and end days
        switch (this.currentTimeframe) {
            case ComparisonTimeframe.DAY:
                api_mode = 'day';
                left_start = moment()
                    .subtract((this.position * this.limit) - 1, 'days')
                    .format(day_format);
                left_end = moment()
                    .subtract((this.position - 1) * this.limit, 'days')
                    .format(day_format);
                right_start = moment(left_start, day_format)
                    .subtract(1, 'year')
                    .format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY')
                        .format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format)
                    .subtract(1, 'year')
                    .format(day_format);
                break;
            case ComparisonTimeframe.WEEK:
                // using day api for weeks
                api_mode = 'week';
                left_start = moment()
                    .subtract((7 * this.position * this.limit) - 7, 'days')
                    .format(day_format);
                left_end = moment()
                    .subtract(7 * (this.position - 1) * this.limit, 'days').format(day_format);
                right_start = moment(left_start, day_format)
                    .subtract(1, 'year')
                    .format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY')
                        .format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format)
                    .subtract(1, 'year')
                    .format(day_format);
                break;
            case ComparisonTimeframe.MONTH:
                api_mode = 'month';
                left_start = moment()
                    .subtract((this.position * this.limit) - 1, 'months')
                    .format(month_format);
                left_end = moment()
                    .subtract((this.position - 1) * this.limit, 'months')
                    .format(month_format);
                right_start = moment(left_start, month_format)
                    .subtract(1, 'year')
                    .format(month_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY')
                        .format(month_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, month_format)
                    .subtract(1, 'year')
                    .format(month_format);

                break;
            case ComparisonTimeframe.YEAR:
                api_mode = 'year';
                left_start = moment().year();
                left_end = moment().year();
                right_start = moment().subtract(this.position, 'year').year();
                right_end = right_start;
                break;
            default:
                console.log('Unknown case');
                break;
        }

        const thisYearRequest = this.happyHour.getConsumptionFor(api_mode, left_start, left_end);
        const lastYearRequest = this.happyHour.getConsumptionFor(api_mode, right_start, right_end);

        const s = forkJoin([thisYearRequest, lastYearRequest]).subscribe(
            (responses) => {
                const thisYearData = responses[0];
                const lastYearData = responses[1];

                const lastYearDataMapped = this.determineHappyHourValues(lastYearData);
                if (lastYearData) {
                    const series: any = {
                        // name: new Date(lastYearData.last.timestamp).getFullYear().toString(),
                        name: moment(lastYearData.last().timestamp).year(),
                        data: lastYearDataMapped,
                        color: this.lastYearColor
                    };
                    console.log('last year data', lastYearData);
                    console.log('last year data', lastYearData.last);
                    console.log('last year data series', series);
                    this.chart.addSeries(series, true, true);
                }

                if (thisYearData) {
                    let categories = [];
                    if (api_mode === 'day') {
                        for (const consumption of thisYearData) {
                            const date = moment(consumption.timestamp).toDate();
                            const date_string =
                                `${date.getDate()}. ${this.validMonths[date.getMonth()]}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'week') {
                        for (const consumption of thisYearData) {
                            const date_string = `KW ${moment(consumption.month).week()}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'month') {
                        for (const consumption of thisYearData) {
                            const date = moment(consumption.timestamp).toDate();
                            const date_string = `${this.validMonths[date.getMonth()]}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'year') {
                        categories = ['', ''];
                    }

                    const thisYearDataMapped = this.determineHappyHourValues(thisYearData);
                    const series: any = {
                        name: new Date(thisYearData[thisYearData.length - 1].timestamp)
                            .getFullYear().toString(),
                        data: thisYearDataMapped,
                        color: this.thisYearColor
                    };
                    this.chart.addSeries(series, true, true);
                    this.chart.ref.update({xAxis: {categories: categories}});
                    this.chart.ref.hideLoading();
                }
                this.disabled = false;
                s.unsubscribe();
            }
        );
    }

    private getHappyHourDynamic(): void {
        let apiMode: 'day' | 'week' | 'month' | 'year';
        let rightStart;
        let rightEnd;
        let leftStart;
        let leftEnd;

        const dayFormat = 'YYYY-MM-DD';
        const monthFormat = 'YYYY-MM';

        const dateStringRight = `${this.comparisonDates.right_year}-${this.comparisonDates.right_month}-${this.comparisonDates.right_day}`;
        const dateStringLeft = `${this.comparisonDates.left_year}-${this.comparisonDates.left_month}-${this.comparisonDates.left_day}`;

        this.chart.ref.showLoading('Auswertung wird geladen…');

        switch (this.currentTimeframe) {
            case ComparisonTimeframe.DAY:
                apiMode = 'day';
                rightStart = moment(dateStringRight, dayFormat).format(dayFormat);
                leftStart = moment(dateStringLeft, dayFormat).format(dayFormat);
                rightEnd = rightStart;
                leftEnd = leftStart;
                break;
            case ComparisonTimeframe.WEEK:
                apiMode = 'week';
                moment.locale('de');
                rightStart = moment()
                    .year(this.comparisonDates.right_year)
                    .week(this.comparisonDates.right_kw)
                    .startOf('week')
                    .format(dayFormat);
                rightEnd = moment(rightStart, dayFormat)
                    .endOf('week')
                    .format(dayFormat);
                leftStart = moment()
                    .year(this.comparisonDates.left_year)
                    .week(this.comparisonDates.left_kw)
                    .format(dayFormat);
                leftEnd = moment(leftStart, dayFormat)
                    .endOf('week').format(dayFormat);
                break;
            case ComparisonTimeframe.MONTH:
                apiMode = 'month';
                rightStart = moment(dateStringRight, dayFormat)
                    .format(monthFormat);
                rightEnd = rightStart;

                leftStart = moment(dateStringLeft, dayFormat)
                    .format(monthFormat);
                leftEnd = leftStart;
                break;
            case ComparisonTimeframe.YEAR:
                rightStart = moment(dateStringRight, dayFormat).year();
                rightEnd = rightStart;
                leftStart = moment(dateStringLeft, dayFormat).year();
                leftEnd = leftStart;
                apiMode = 'year';
                break;
            default:
                console.log('Unknown case');
        }

        const right_request = this.happyHour.getConsumptionFor(apiMode, rightStart, rightEnd);
        const left_request = this.happyHour.getConsumptionFor(apiMode, leftStart, leftEnd);

        const s = forkJoin([right_request, left_request]).subscribe(
            (responses) => {
                const rightData = responses[0];
                const leftData = responses[1];

                const rightDataMapped = this.determineHappyHourValues(rightData);
                const leftDataMapped = this.determineHappyHourValues(leftData);

                if (leftData && rightData) {
                    const series: SeriesBarOptions = {
                        name: null,
                        type: 'bar',
                        data: [
                            {
                                name: null,
                                y: leftDataMapped,
                                color: this.lastYearColor
                            },
                            {
                                name: null,
                                y: rightDataMapped,
                                color: this.thisYearColor
                            }
                        ]
                    };
                    this.chart.addSeries(series, true, true);
                }

                this.disabled = false;
                this.chart.ref.hideLoading();
                s.unsubscribe();
            }
        );
        this.disabled = false;
    }


    private initializeStaticChart() {
        this.seriesLegend = [];
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                marginLeft: 80,
                marginBottom: 30,
                marginTop: 50,
                events: {
                    addSeries(evt) {
                        console.log('on add series event');
                        console.log(evt.options);
                        self.seriesLegend.push({name: evt.options.name, color: evt.options.color});
                    },
                    load(load_evt) {
                        if (self.application.isDemoMode()) {
                            self.getMockStatic();
                            return;
                        }
                        if (self.currentDataMode === ComparisonDataMode.REGULAR) {
                            self.getStaticDiagramData();
                        } else {
                            self.getStaticDiagramDataForHappyHour();
                        }
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                // type: 'categories',
                categories: [],
                labels: {
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '18px'
                    }
                },
            },
            yAxis: {
                title: {
                    text: self.currentDisplayMode === 'cost' ? '€' : 'Watt',
                    align: 'high',
                    textAlign: 'right',
                    x: 36,
                    y: -30,
                    rotation: 0,
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px',
                    }
                },
                labels: {
                    align: 'right',
                    x: -8,
                    y: 5,
                    step: 2,
                    formatter() {
                        if (this.value >= 1) {
                            const unit = self.currentDisplayMode === 'cost' ? '€' : 'kWh';
                            return `${this.value.toLocaleString('de-DE')} ${unit}`;
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px'
                    }

                }
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const idx = this.point.index;
                    let name = '';
                    try {
                        const originalData = this.series.userOptions['originalData'].reverse();
                        const year = moment(originalData[idx].timestamp).year();
                        name = `<div class="header">${this.key} ${year} </div>`;
                    } catch (e) {

                    }
                    const style = `style="border-color: ${this.color}"`;
                    const unit = self.currentDisplayMode === 'cost' ? '€' : 'kWh';
                    let valueFormatted = this.y.toFixed(2);
                    if (self.currentDisplayMode === 'cost') {
                        valueFormatted = Math.ceil(this.y).toString();
                    }
                    const value = `<div class="body"> ${valueFormatted} ${unit}</div>`;
                    return `<div class="column-callout" ${style}> ${name} ${value}</div>`;
                },
                borderColor: 'white',
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: 'transparent',
                shadow: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 3,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            legend: {enabled: false},
            series: [],
            credits: {
                enabled: false
            }
        });
    }

    private initializeDynamicChart() {
        this.seriesLegend = [];
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                marginLeft: 80,
                marginBottom: 30,
                marginTop: 50,
                events: {
                    addSeries(evt) {
                        self.seriesLegend.push({name: evt.options.name, color: evt.options.color});
                    },
                    load(load_evt) {
                        if (self.application.isDemoMode()) {
                            self.getMockDynamic();
                            return;
                        }
                        if (self.currentDataMode === ComparisonDataMode.REGULAR) {
                            self.getDynamicDiagramData();
                        } else {
                            self.getHappyHourDynamic();
                        }
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                title: {text: null},
                labels: {
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '18px',
                        color: 'transparent',
                    }
                },
            },
            yAxis: {
                title: {
                    text: self.currentDisplayMode === 'cost' ? '€' : 'Watt',
                    align: 'high',
                    textAlign: 'right',
                    x: 36,
                    y: -30,
                    rotation: 0,
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px',
                    }
                },
                labels: {
                    align: 'right',
                    x: -8,
                    y: 5,
                    step: 2,
                    formatter() {
                        if (this.value >= 1) {
                            const unit = self.currentDisplayMode === 'cost' ? '€' : 'kWh';
                            return `${this.value.toLocaleString('de-DE')} ${unit}`;
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px'
                    }

                }
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const style = `style="border-color: ${this.color}"`;
                    const name = `<div class="header">${this.key}</div>`;
                    const unit = self.currentDisplayMode === 'cost' ? '€' : 'kWh';
                    let valueFormatted = this.y.toFixed(2);
                    if (self.currentDisplayMode === 'cost') {
                        valueFormatted = Math.ceil(this.y).toString();
                    }
                    const value = `<div class="body"> ${valueFormatted} ${unit}</div>`;
                    return `<div class="column-callout" ${style}> ${name} ${value}</div>`;
                },
                borderColor: 'white',
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: 'transparent',
                shadow: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 3,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false
                }
            },
            legend: {enabled: false},
            series: [],
            credits: {
                enabled: false
            }
        });
    }

    /**
     * Determine dynamic timeframes
     */
    private determineDynamicDatesByTimeframe(): any {
        let left_from: Date = null;
        let left_to: Date = null;

        let right_from: Date = null;
        let right_to: Date = null;

        let apiPrefix = '';

        switch (this.currentTimeframe) {
            case ComparisonTimeframe.WEEK: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.comparisonDates.left_year);
                left_from.setDate(left_from.getDate() + (this.comparisonDates.left_kw * 7) - 7);

                left_to = new Date(0);
                left_to.setFullYear(this.comparisonDates.left_year);
                left_to.setDate(left_to.getDate() + (this.comparisonDates.left_kw * 7) - 1);

                right_from = new Date(0);
                right_from.setFullYear(this.comparisonDates.right_year);
                right_from.setDate(right_from.getDate() + (this.comparisonDates.right_kw * 7) - 7);

                right_to = new Date(0);
                right_to.setFullYear(this.comparisonDates.right_year);
                right_to.setDate(right_to.getDate() + (this.comparisonDates.right_kw * 7) - 1);

                apiPrefix = 'days';

                break;
            }
            case ComparisonTimeframe.MONTH: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.comparisonDates.left_month - 1);
                left_from.setFullYear(this.comparisonDates.left_year);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.comparisonDates.right_month - 1);
                right_from.setFullYear(this.comparisonDates.right_year);

                right_to = right_from;

                apiPrefix = 'months';

                break;
            }
            case ComparisonTimeframe.YEAR: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.comparisonDates.left_year);

                left_to = new Date(0);
                left_to.setFullYear(this.comparisonDates.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);

                right_from = new Date(0);
                right_from.setFullYear(this.comparisonDates.right_year);

                right_to = new Date(0);
                right_to.setFullYear(this.comparisonDates.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);

                apiPrefix = 'months';

                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.comparisonDates.left_year);
                left_from.setMonth(this.comparisonDates.left_month - 1);
                left_from.setDate(this.comparisonDates.left_day);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setFullYear(this.comparisonDates.right_year);
                right_from.setMonth(this.comparisonDates.right_month - 1);
                right_from.setDate(this.comparisonDates.right_day);

                right_to = right_from;

                apiPrefix = 'days';
            }
        }

        return {left_from, left_to, right_from, right_to, api: apiPrefix};
    }

    /**
     * Determine timeframe dates
     * @param year
     */
    private determineStaticDatesByTimeframe(year): { from: Date, to: Date, timeframe: string } {
        let from: Date = null;
        let to: Date = null;
        let timeframe: string = null;

        switch (this.currentTimeframe) {
            case ComparisonTimeframe.WEEK: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * (this.limit * 7)) + 7);
                from.setDate(from.getDate() - (from.getDay() > 0 ? (from.getDay() - 1) : 6));

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * (this.limit * 7)) + (this.limit * 7));
                to.setDate(to.getDate() + (to.getDay() > 0 ? (7 - to.getDay()) : 0));

                timeframe = 'days';

                break;
            }
            case ComparisonTimeframe.MONTH: {
                from = new Date();
                from.setFullYear(year.year);
                from.setMonth(from.getMonth() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setMonth(to.getMonth() - (this.position * this.limit) + this.limit);

                timeframe = 'months';

                break;
            }
            case ComparisonTimeframe.YEAR: {
                from = new Date(0);
                from.setFullYear(year.year);
                from.setMonth(0);

                to = new Date(0);
                to.setFullYear(year.year);
                to.setMonth(11);
                to.setDate(31);

                timeframe = 'months';

                break;
            }
            default: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * this.limit) + this.limit);

                timeframe = 'days';
            }
        }

        return {from, to, timeframe};
    }

    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        const s = this.happyHour.getParticipation().subscribe(
            // this._apiService.userParticipatesInHappyHour().subscribe(
            (res) => {
                if (res) {
                    const now = moment();

                    const parsed_response_data = res as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    const filtered = parsed_response_data.filter(
                        (element) =>
                            now >= moment(element.from_date, 'DD/MM/YYYY'));
                    const sorted = filtered.sort(
                        (a, b) => {
                            const a_from = moment(a.from_date, 'DD/MM/YYYY');
                            const b_from = moment(b.from_date, 'DD/MM/YYYY');
                            if (a_from.unix() > b_from.unix()) {
                                return -1;
                            } else if (a_from.unix() < b_from.unix()) {
                                return 1;
                            }
                            return 0;
                        });
                    if (sorted.length > 0) {
                        if ('value' in sorted[0]) {
                            this.onUserHasHappyHour.next(sorted[0].value === 1);
                        }
                    }
                }
            },
            (error) => s.unsubscribe(),
            () => s.unsubscribe()
        );
    }


    /**
     * Determines multiplier in stored tariffs by an entered timestamp
     * @param date
     */
    private getTariffMultiplier(date): number {
        try {
            date = new Date(date);
            const tariff = this.tariffs.find((el) => {
                const start = moment(el.dateStart)
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0)
                    .toDate();
                const end = el.dateEnd ? new Date(el.dateEnd) : new Date();
                return date >= start && date <= end;
            });
            return tariff.workPrice;
        } catch (error) {
            return 1;
        }
    }

    /**
     * Initialize happy hour feature
     */
    private initializeHappyHour(): void {
        const s = this.onUserHasHappyHour.subscribe(
            (val) => this.userHasHappyHour = val,
            (error) => null,
            () => s.unsubscribe()
        );
    }

    /**
     * Initializing mode change response
     */
    private initializeModeChange(): void {
        this.addSub(this.onDataModeChange.subscribe(
            (value: ComparisonDataMode) => {
                this.currentDataMode = value;
                this.constructComparisonDates();
                this.resetChart();
            }
        ));
    }

    /**
     * Initializes tariff data from user entered and stored tariffs
     */
    private initializeTariffs(): void {
        this.tariffs = this.userService.getTariffInfo();
        of(this.userService.getTariffInfo()).pipe(
            mergeMap(tariffs => tariffs ? of(tariffs) : of(null)),
            mergeMap((tariffs: any) => {
                if (tariffs) {
                    return of(tariffs);
                }
                return this.profile.getContract().pipe(
                    mergeMap(contract => {
                        return this.constructBaseTariff(contract);
                    })
                );
            })
        ).subscribe((result) => {
            this.tariffs = result;
            this.tariffsAvailable = true;
        });
    }

    /**
     * Returns the default tariff for the happy hour cost calculation
     */
    private getDefaultTariff(): Tariff {
        return this.tariffs.find(el => el.name === 'default');
    }

    /**
     * Generates a base tariff for some reason
     * @param response
     */
    private constructBaseTariff(response: any): Observable<any> {
        return new Observable<any>((observer) => {
            try {
                const basePrice = typeof response.profile.e_fixed_tariff !== 'undefined' ?
                    parseFloat(response.profile.e_fixed_tariff) :
                    parseFloat(response.profile.budget_bill);
                const workPrice = parseFloat(response.profile.e_tariff);

                const t: Tariff = {
                    name: 'default',
                    dateStart: moment().subtract(1, 'day').toDate(),
                    dateEnd: moment().add(2, 'days').toDate(),
                    basePrice,
                    workPrice
                };
                observer.next([t]);
            } catch (error) {
                observer.error(error);
            }
        });
    }

    /**
     * Retunrs if the component is currently in cost displayal mode
     */
    private isDisplayModeCost(): boolean {
        return this.currentDisplayMode === ComparisonDisplayMode.COST;
    }

    /**
     * Determines the value used based on the current data display mode
     * furthermore calculates the price if erna users have entered their own tariff
     * @param consumption
     */
    private determineSeriesValue(consumption): number {
        let value = 0;
        let costMultiplier = 1;
        if (this.isDisplayModeCost()) {
            costMultiplier = this.getTariffMultiplier(consumption.timestamp);
            if (this.isErnaUser) {
                value = 'measured' in consumption ? consumption.measured * costMultiplier : 0;
            } else {
                value = 'cost_measured' in consumption ? consumption.cost_measured * 1000 : 0;
            }
        } else {
            value = 'measured' in consumption ? consumption.measured * costMultiplier : 0;
        }
        return value;
    }

    /**
     * Determine the final diagram values for happy hour
     * @param consumptionData
     */
    private determineHappyHourValues(
        consumptionData: [{ measured: number, timestamp: string }]): number {
        let mapped = null;
        const defaultTariff = this.getDefaultTariff();
        if (this.isDisplayModeCost()) {
            mapped = consumptionData.map((el: { measured: number, timestamp: string }) =>
                (el.measured / 1000) * defaultTariff.workPrice
            );
        } else {
            mapped = consumptionData.map((el: { measured: number, timestamp: string }) =>
                Number((el.measured / 1000).toFixed(2))
            );
        }
        return mapped;
    }

    /**
     * EventTracking
     * @private
     */
    private trackModeChangeEvent(): void {
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: Vergleich - ' + (this.currentMode === ComparisonMode.DYNAMIC ? 'Anpassbar' : 'Statisch') +
                    '; previous_screen: Vergleich - ' + (this.currentMode === ComparisonMode.DYNAMIC ? 'Statisch' : 'Anpassbar')
            }
        });
    }
}

enum ComparisonTimeframe {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'mont',
    YEAR = 'year'
}

enum ComparisonMode {
    STATIC = 'static',
    DYNAMIC = 'dynamic'
}

enum ComparisonDisplayMode {
    COST = 'cost',
    CONSUMPTION = 'consumption',
    FEEDIN = 'feedin'
}

enum ComparisonDataMode {
    REGULAR = 'regular',
    HAPPY_HOUR = 'happy-hour'
}
