<div class="overlay-minimal">
    <div class="overlay-container-cdk">
        <h1>{{title}}</h1>
        <p>{{text}}</p>
        <div>
            <input #value type="text" class="iona-input"
                   placeholder="1234" [attr.maxlength]="maxlength"
                   (keyup)="valueChange($event)">
        </div>
        <div class="interaction-area m-t-l">
            <ng-container *ngIf="hasSkip">
                <button class="iona-button outline" (click)="close(false)">Später</button>
            </ng-container>
            <ng-container *ngIf="turquoise; else regularColorPositive">
                <button class="iona-button orange" [disabled]="submitDisabled"
                        (click)="close(value.value)">{{positive}}</button>
            </ng-container>
            <ng-template #regularColorPositive>
                <button class="iona-button orange" [disabled]="submitDisabled"
                        (click)="close(value.value)">{{positive}}</button>
            </ng-template>
            <ng-container *ngIf="negative !== ''">
                <ng-container *ngIf="turquoise; else regularColorNegative">
                    <button class="iona-button orange" (click)="close(false)">{{negative}}</button>
                </ng-container>
                <ng-template #regularColorNegative>
                    <button class="iona-button orange" (click)="close(false)">{{negative}}</button>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
