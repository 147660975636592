import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {HttpClient} from '@angular/common/http';
import {constants} from '../shared/constants/constants';
import * as moment from 'moment';
import {catchError, map} from 'rxjs/operators';
import {ApplicationService} from './application.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConsumptionService extends BaseService {

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    getConsumptionForMonths(monthFrom: Date, monthTo: Date): Observable<any> {
        const d1 = moment(monthFrom).format('YYYY-MM');
        const d2 = moment(monthTo).format('YYYY-MM');
        let url = this.API_BASE_URL + constants.api.routes.consumption.electricity.appliances.months;
        url = `${url}/${d1}/${d2}`;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.appliances}.json`;
        }
        return this.http.get(url).pipe(
            map(res => this.mapDefault(res)),
            catchError(error => this.handleError(error))
        );
    }
}
