<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>2-Faktor-Authentifizierung</h1>
        <p>
            Bitte wechseln Sie jetzt in die Authenticator-App Ihrer Wahl und legen Sie einen neuen Account an. Zum
            Anlegen verwenden Sie den unten angezeigten <strong>Code</strong>:
        </p>
        <p class="secret-code" (click)="copyCode()">
            {{setupCode}}
            <i></i>
        </p>
        <div>
            <button class="iona-button orange" (click)="close(true)">Weiter</button>
        </div>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close()"></button>
    </div>
</div>
