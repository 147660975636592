<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Abschlags-Check; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading">
                {{TEXTS.TITLE}}
            </h3>
            <div class="tile-content col">
                <div class="finance-status-container">
                    <div class="finance-status-icon-container" appMatchWidth>
                        <ng-container *ngIf="dataAvailable; else emptyState">
                            <div class="finance-status-icon"
                                 [class.status-icon-piggy]="finance.trend < 0"
                                 [class.status-icon-thumb]="finance.trend === 0"
                                 [class.status-icon-alert]="finance.trend > 0">
                                &nbsp;
                            </div>

                            <div class="status-circle circle"
                                 [style.width.%]="finance.trend < 0 ? finance.percent : 100"
                                 [style.height.%]="finance.trend < 0 ? finance.percent : 100">
                            </div>

                            <div class="status-circle-outline circle"
                                 [style.width.%]="finance.trend > 0 ? finance.percent : 100"
                                 [style.height.%]="finance.trend > 0 ? finance.percent : 100">
                            </div>
                        </ng-container>

                        <ng-template #emptyState>
                            <div class="animation-container"
                                 lottie [options]="lottieConfig">
                            </div>
                        </ng-template>
                    </div>
                </div>

                <div class="finance-status-wording font-regular">
                    <ng-container *ngIf="dataAvailable; else subtitle_empty">
                        <div *ngIf="finance.trend < 0">
                            {{TEXTS.LABEL_FRAGMENT_CIRCA}}
                            <span class="font-medium">
                                {{ finance.amount }}
                                {{TEXTS.UNIT_FINANCE}}
                            </span>
                            <br>
                            {{TEXTS.LABEL_FRAGMENT_CREDIT}}
                        </div>
                        <div *ngIf="finance.trend === 0">
                            {{TEXTS.LABEL_FRAGMENT_ESTIMATED}}
                            <span class="font-medium">
                                <br>
                                {{TEXTS.LABEL_FRAGMENT_EVEN}}
                            </span>
                        </div>
                        <div *ngIf="finance.trend > 0">
                            {{TEXTS.LABEL_FRAGMENT_CIRCA}}
                            <span class="font-medium">
                                {{ finance.amount }}
                                {{TEXTS.UNIT_FINANCE}}
                            </span>
                            <br>
                            {{TEXTS.LABEL_FRAGMENT_ADDITIONAL_PAYMENT}}
                        </div>
                    </ng-container>
                    <ng-template #subtitle_empty>
                        <h4>
                            {{TEXTS.LABEL_EMPTY_STATE}}
                        </h4>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
