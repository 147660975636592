export const static_links = {
    settings: {
        innogy: 'https://www.eon.de/de/meineon/start.html',
        enviam: 'https://www.enviam.de/privatkunden/Meine-enviaM#/',
        eprimo: 'https://www.eprimo.de/service/account/auth/login',
        energis: 'https://www.energis.de',
        lew: 'https://kundenkonto.lew.de/',
        'e.on': 'https://www.eon.de/de/meineon/start.html',
        eon: 'https://www.eon.de/de/meineon/start.html',
        avacon: 'https://www.avacon-netz.de/de/energie-service/messstellenbetrieb.html',
        shn: 'https://www.sh-netz.com/de/energie-service/zaehler.html',
        bn: 'https://www.bayernwerk-netz.de/de/energie-service/messstellenbetrieb.html',
        'e.dis': 'https://www.e-dis-netz.de/de/energie-service/messstellenbetrieb.html'
    },
    privacy: {
        innogy: 'https://www.iona-energy.com/-/media/iONA/documents/iona-eon/datenschutzinformation.pdf',
        enviam: 'http://www.enviam.de/Media/docs/default-source/iona-app/dsi-iona-120522.pdf',
        eprimo: 'assets/downloads/eprimo-datenschutz.pdf',
        energis: 'https://www.iona-energy.com/-/media/iONA/documents/iona-energis/datenschutzinformation.pdf',
        lew: 'https://www.iona-energy.com/-/media/iONA/documents/iona-lew/datenschutzinformation.pdf',
        'e.on': 'https://www.iona-energy.com/iONA/datenschutz',
        eon: 'https://www.iona-energy.com/iONA/datenschutz',
        avacon: 'https://www.avacon-netz.de/zählercheck/datenschutz',
        'schleswig-holstein netz': 'https://www.sh-netz.com/zaehlercheck/datenschutz',
        'bayernwerk netz': 'https://www.bayernwerk-netz.de/zaehlercheck/datenschutz',
        'e.dis': 'https://www.e-dis-netz.de/zählercheck/datenschutz'
    },
    finance: {
        innogy: 'https://www.eon.de/de/meineon/start.html',
        enviam: 'https://www.enviam.de/privatkunden/Meine-enviaM#/',
        eprimo: 'https://www.eprimo.de/service/account/auth/login',
        energis: 'http://www.energis.de',
        lew: 'https://kundenkonto.lew.de/abschlaege/',
        'e.on': 'https://www.eon.de/de/meineon/start.html',
        eon: 'https://www.eon.de/de/meineon/start.html',
        avacon: 'https://www.avacon-netz.de/de/energie-service/messstellenbetrieb.html',
        shn: 'https://www.sh-netz.com/de/energie-service/zaehler.html',
        bn: 'https://www.bayernwerk-netz.de/de/energie-service/messstellenbetrieb.html',
        'e.dis': 'https://www.e-dis-netz.de/de/en'
    }
};
