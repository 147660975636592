<div class="overlay-minimal">
    <div class="overlay-container-cdk">
        <h1 class="font-medium">
            {{showFeatures ? 'Ihre Daten können mehr!' : 'Hinweis'}}
        </h1>
        <ng-container *ngIf="showFeatures; else alternate">
            <p>
                Erhalten Sie eine umfangreiche Auswerung, indem Sie Ihre Daten dafür freigeben.
            </p>
            <div class="feature-list">
                <div *ngFor="let feature of features" class="feature-list-item">
                    <div class="feature-icon"
                         [style.background-image]="createImageUrl(feature)"></div>
                    <div class="feature-description">
                        <div><strong class="m-b-m">{{feature.title}}</strong></div>
                        <div> {{feature.text}} </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #alternate>
            <p class="font-regular">
                Wenn Sie die WebApp und alle Funktionen von iONA nutzen möchten, können Sie in den Online-Modus
                wechseln. Im Online-Modus werden Ihre Stromzähler- und Stromverbrauchsdaten auf iONA-Servern gespeichert
                und verarbeitet. Den vollen Funktionsumfang des iONA-Service sowie eine genaue Erläuterung wie iONA Ihre
                Stromzähler und Stromverbrauchsdaten verarbeitet, können Sie Ihren Vertragsunterlagen und der
                Datenschutzinformation zum iONA-Service entnehmen.
                <br>
                Die WebApp ist aus technischen Gründen nur im Online-Modus nutzbar.
            </p>
        </ng-template>
        <div class="row">
            <button class="iona-button orange m-r-x" (click)="close(true)">Online-Modus erlauben</button>
            <button class="iona-button outline" (click)="close()">Abmelden</button>
        </div>
    </div>
</div>
