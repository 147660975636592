<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">Abschlags-Check</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Abschlags-Check"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
                <section>
                    <ng-container *ngIf="!dataAvailable; else subtitle_empty">
                        <div class="finance-details-value center-contents">
                            <div *ngIf="finance.trend < 0">
                                <div class="status-icon-piggy"></div>
                                <div>Etwa <strong>{{ finance.amount }} &euro;</strong> Gutschrift</div>
                            </div>
                            <div *ngIf="finance.trend === 0">
                                <div class="status-icon-thumb"></div>
                                <div><strong>Verbrauch passt genau</strong></div>
                            </div>
                            <div *ngIf="finance.trend > 0">
                                <div class="status-icon-alert"></div>
                                <div>Etwa <strong>{{ finance.amount }} &euro;</strong> Nachzahlung</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #subtitle_empty>
                        <div class="empty-state">
                            <div class="empty-state-animation-container">
                                <div class="empty-state-animation"
                                     lottie [options]="lottieConfig"></div>
                            </div>
                            <div class="empty-state-label">Daten werden noch gesammelt</div>
                        </div>
                    </ng-template>
                </section>
            </section>


            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">Abschlags-Check: Was ist das?</h2>
                    <p>
                        Nie mehr nachzahlen. iONA wirft für Sie einen Blick in die Zukunft und berechnet, ob Ihr
                        monatlicher Abschlag ausreicht. Falls nicht, können Sie ihn online anpassen. Die Vorhersage von
                        iONA wird jeden Monat besser. Für eine genaue Berechnung werden ca. sechs Monatsverbräuche
                        benötigt.
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <ng-container *ngIf="dataAvailable">
                    <h4>Abrechnungszeitraum</h4>
                    <p class="finance-range">{{formatStartDate(finance.from)}} - {{formatEndDate(finance.to)}}</p>
                    <ng-container [ngSwitch]="finance.trend">
                        <p *ngSwitchCase="-1">Auf Basis Ihres aktuellen Verbrauchs ergibt sich zur Jahresrechnung
                            voraussichtlich eine Gutschrift von bis zu {{finance.amount}} Euro.</p>
                        <p *ngSwitchCase="0"> Auf Basis der ermittelten Daten passt der Abschlag. </p>
                        <p *ngSwitchCase="1">Auf Basis Ihres aktuellen Verbrauchs ergibt sich zur Jahresrechnung
                            voraussichtlich eine
                            Nachzahlung von bis zu {{finance.amount}} Euro.</p>
                    </ng-container>
                </ng-container>
                <p> &nbsp;</p>
                <p>
                    Sie können ihren Abschlag jederzeit online anpassen.
                </p>
                <p>
                    <button class="iona-button orange" (click)="openLink()">Abschlag ändern</button>
                </p>
                <p>
                    Die Vorhersage von iONA wird jeden Monat besser. Für eine genaue Berechnung werden ca. sechs
                    Monatsverbräuche benötigt.
                </p>
            </section>

        </div>
    </div>
</div>


