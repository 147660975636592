<div id="container">
    <app-menu activeElement="settings"></app-menu>
    <div class="page">
        <div id="inner">
            <div class="page-card">
                <h1>Einstellungen</h1>

                <section>
                    <h2 class="extra-space">Verbindungen</h2>
                    <div>
                        <h3>{{deviceIsPlug ? 'Ihr iONA PowerChecker' : 'Ihre iONA Box'}}</h3>
                        <div class="connection-container p-b-l p-t-s">
                            <div class="p-r-s">
                                <app-connection-indicator [value]="wifiConnectionQuality"
                                                          container_height="24px"
                                                          [disabled]="wifiConnected >= 0"
                                                          [config]="wifiConnectionConfig"
                                                          [ignoreValue]="lanConnected">
                                </app-connection-indicator>
                            </div>
                            <ng-container *ngIf="lanConnected else wifiConnection">
                                <div> verbunden</div>
                            </ng-container>
                            <ng-template #wifiConnection>
                                <div> {{ wifiConnected < 0 ? '(W-)LAN verbunden' : '(W-)LAN nicht verbunden' }} </div>
                            </ng-template>
                        </div>

                        <h3>Ihr Stromzähler</h3>
                        <div class="connection-container p-r-s p-b-l">
                            <div class="p-r-s">
                                <app-connection-indicator [value]="meterConnectionQuality"
                                                          [container_height]="'24px'"
                                                          [disabled]="meterStatus !== 'connected'"
                                                          [config]="meterConnectionConfig">
                                </app-connection-indicator>
                            </div>
                            <div>{{determineMeterStatusMessage()}}</div>
                        </div>

                        <ng-container *ngIf="pincode">
                            <h3>Aktuelle Zähler-PIN</h3>
                            <div class="p-r-s">
                                {{pincode}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isEDGUser && userService.isEnviamUser() && pinEntryUnknownOrOptical">
                            <button class="iona-button orange m-t-l"
                                    [disabled]="currentBatteryState === 0"
                                    (click)="onPinEntryOpen()">
                                Zähler-PIN eingeben
                            </button>
                        </ng-container>

                        <ng-container *ngIf="isEDGUser">
                            <h3 class="m-t-l">Batteriestatus</h3>
                            <div class="p-t-s">
                                <app-battery-load [currentBatteryState]="currentBatteryState"
                                                  [isAvailable]="currentBatteryState > -1">
                                </app-battery-load>
                            </div>

                            <button class="iona-button orange m-t-x"
                                    [disabled]="currentBatteryState === 0"
                                    (click)="onEnergySaverOpen()">
                                Energiesparen
                            </button>
                        </ng-container>
                    </div>
                </section>

                <section>
                    <h2 class="extra-space">Tracking</h2>
                    <!--                    <fieldset>-->
                    <!--                        <div class="formItem checkbox-wrap">-->
                    <!--                            <input #trackingCheckbox type="checkbox" id="config-tracking"-->
                    <!--                                   [checked]="config.tracking"-->
                    <!--                                   (change)="setTracking(trackingCheckbox.checked)">-->
                    <!--                            <label for="config-tracking">-->
                    <!--                                <i></i>-->
                    <!--                                <span>-->
                    <!--                                    <strong>Tracking erlauben</strong>-->
                    <!--                                    <small>Wir möchten uns gerne weiterentwickeln und verbessern.-->
                    <!--                                        Aus diesem Grund analysieren wir anonymisiert die Nutzung der Webseite.-->
                    <!--                                    </small>-->
                    <!--                                </span>-->
                    <!--                            </label>-->
                    <!--                        </div>-->
                    <!--                    </fieldset>-->

                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="tracking-checkbox">
                            <input #trackingCb type="checkbox" id="tracking-checkbox"
                                   [checked]="config.tracking"
                                   (change)="setTracking(trackingCb.checked)">
                            Tracking erlauben
                        </label>
                        <p>
                            Wir möchten uns gerne weiterentwickeln und verbessern. Aus diesem Grund analysieren wir
                            anonymisiert die Nutzung der Webseite.
                        </p>
                    </div>

                    <h2 class="extra-space">Online-Modus</h2>
                    <!--                    <fieldset>-->
                    <!--                        <div class="formItem checkbox-wrap">-->
                    <!--                            <input #optInCheckbox type="checkbox" id="config-optin"-->
                    <!--                                   [checked]="config.optIn"-->
                    <!--                                   (change)="setOptIn(optInCheckbox.checked)">-->
                    <!--                            <label for="config-optin">-->
                    <!--                                <i></i>-->
                    <!--                                <span>-->
                    <!--                                    <strong>Online-Modus erlauben</strong>-->
                    <!--                                        Wenn Sie alle Funktionen von iONA nutzen möchten, können Sie hier für die Zukunft in den Online-Modus wechseln. Damit geben Sie der [E.ON Energie Deutschland GmbH] Ihre Einwilligung um auf die Verbrauchsdaten Ihrer [E.ON Smart Control Empfangseinheit] zugreifen zu dürfen. Dies beinhaltet unter anderem ihren Stromverbrauch in Sekunden-Auflösung. Dieser Zugriff ist notwendig, damit wir Ihnen den vollen Funktionsumfang - der in den Nutzungsbedingungen der App beschrieben wird – anbieten können. Die weitere Verarbeitung dieser Daten wird in unseren Datenschutzhinweisen und den folgenden Einwilligungserklärungen beschrieben. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen indem Sie die Option in den Einstellungen wieder deaktivieren.-->
                    <!--                                </span>-->
                    <!--                            </label>-->
                    <!--                        </div>-->
                    <!--                    </fieldset>-->

                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="optin-checkbox">
                            <input #optInCb type="checkbox" name="checkbox" id="optin-checkbox"
                                   [checked]="config.optIn"
                                   (change)="setOptIn(optInCb.checked)">
                            Online-Modus erlauben
                        </label>
                        <p>
                            Wenn Sie alle Funktionen von iONA nutzen möchten, können Sie hier für die Zukunft in den
                            Online-Modus wechseln. Damit geben Sie uns Ihre Einwilligung um auf die
                            Verbrauchsdaten {{deviceIsPlug ? 'Ihres Powercheckers' : 'Ihrer Box'}} zugreifen zu dürfen.
                            Dies beinhaltet unter anderem ihren Stromverbrauch in Sekunden-Auflösung. Dieser Zugriff ist
                            notwendig, damit wir Ihnen den vollen Funktionsumfang - der in den Nutzungsbedingungen der
                            App beschrieben wird – anbieten können. Die weitere Verarbeitung dieser Daten wird in
                            unseren Datenschutzhinweisen und den folgenden Einwilligungserklärungen beschrieben. Sie
                            können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen indem Sie die
                            Option in den Einstellungen wieder deaktivieren.
                        </p>
                    </div>
                </section>

                <button class="iona-button orange" (click)="saveSettings()">Einstellungen speichern</button>
            </div>
        </div>
    </div>
</div>
