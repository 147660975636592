<div class="overlay overlay-dark">
    <div class="overlay-container-cdk">
        <h1>iONA hat ein neues Gerät erkannt</h1>
        <h2>Wie viele {{appliancePlural}} gibt es aktuell in Ihrem Haushalt?</h2>

        <div #applianceIcon class="appliance-icon"></div>

        <div class="interaction-area">
            <button class="iona-icon-only-button minus big white"
                    (click)="lowerAmount()"></button>
            <span class="value">{{this.amount}}</span>
            <button class="iona-icon-only-button plus big white"
                    (click)="raiseAmount()"></button>
        </div>

        <div class="center-contents">
            <button class="iona-button white" (click)="confirm()">
                Übernehmen
            </button>
        </div>
    </div>
</div>
