<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">Heute</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Heute"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
                <section>
                    <div class="today-content">
                        <div class="today-visualization grow-space">
                            <div class="pie-wrapper">
                                <div class="today-pie left {{leftState}}" [halfCircle]="'left'"
                                     [style.transform]="'scale(' + trend.scale.left + ')'"></div>
                                <div class="today-pie right {{rightState}}" [halfCircle]="'right'"
                                     [style.transform]="'scale(' + trend.scale.right + ')'"></div>
                            </div>
                        </div>

                        <div class="today-stats-wrapper">
                            <div class="today-stats">
                                <div class="muted">
                                    <div class="day m-b-s">
                                        <div>
                                            <app-date-picker-button [displaySelectedDate]="true"
                                                                    [currentDate]="currentComparisonDate"
                                                                    (dateChanged)="onComparisonDateChange($event)">
                                            </app-date-picker-button>
                                        </div>
                                    </div>
                                    <div class="font-medium cost m-b-s">
                                        {{formatValue(comparisonDate.costs)}}
                                        {{TEXTS.UNIT_MONEY}}
                                    </div>
                                    <div class="font-medium cost m-b-s">
                                        {{formatValue(comparisonDate.consumption)}}
                                        {{TEXTS.UNIT_CONSUMPTION}}
                                    </div>
                                </div>
                                <!--                                <div [class.active]="trend.direction < 0"-->
                                <!--                                     [class.warn]="trend.direction > 0">-->
                                <div class="white">
                                    <div class="font-regular day right m-b-s">
                                        {{TEXTS.LABEL_COMPARISON_TODAY}}
                                    </div>
                                    <div class="font-medium cost m-b-s">
                                        {{formatValue(today.costs)}}
                                        {{TEXTS.UNIT_MONEY}}
                                    </div>
                                    <div class="font-medium cost m-b-s">
                                        {{formatValue(today.consumption)}}
                                        {{TEXTS.UNIT_CONSUMPTION}}
                                    </div>
                                </div>
                            </div>

                            <div class="today-stats-summary font-regular">
                                <ng-container *ngIf="trend.percentage > 0 && trend.percentage < 9999">
                                    <strong>
                                        {{trend.percentage}}
                                        % {{ trend.direction > 0 ? 'mehr ' : 'weniger '}}
                                        &nbsp;
                                    </strong>
                                    <ng-container *ngIf="!dateChanged">
                                        <span>als letzten {{getComparisonDateDayName()}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="dateChanged">
                                        <span>als zum {{formatCurrentComparisonDate()}}</span>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="trend.percentage === 0">
                                    <strong>identisch</strong>
                                    &nbsp;
                                    <ng-container *ngIf="!dateChanged">
                                        <span>zum letzten {{getComparisonDateDayName()}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="dateChanged">
                                        <span>zum {{formatCurrentComparisonDate()}}</span>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">Heute: Was ist das?</h2>
                    <p>
                        iONA ist nicht von gestern und kennt den Stromverbrauch und die Kosten von heute. Sie sehen auf
                        einen Blick, wie sich der Verbrauch im Tagesverlauf entwickelt hat. Und wie hoch war der
                        Tagesverbrauch eigentlich letzte Woche? Bei Bedarf kann iONA diese Frage mit einem Vergleich
                        dieser Zeiträume beantworten.
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <div class="header-row">
                    <h3>Übersicht in Stunden</h3>
                    <app-toggle icon="timeframe"
                                (stateChanged)="toggleEntireListDisplayal($event)">
                    </app-toggle>
                </div>

                <ul class="hours-list">
                    <li *ngFor="let hour of today.hours; index as i">
                        <div>
                            <div class="list-time">
                                {{ hour.hour }}:00 - {{ hour.hour }}:59 Uhr
                            </div>
                            <div class="list-date">
                                Heute
                            </div>
                            <div class="list-consumption">
                                {{ formatValue(hour.consumption) }} kWh
                            </div>
                            <div class="list-cost">
                                {{ formatValue(hour.costs) }} &euro;
                            </div>
                        </div>
                        <div class="comparison-hours">
                            <div class="list-time"></div>
                            <div class="list-date">
                                {{formatCurrentComparisonDate()}}
                            </div>
                            <div class="list-consumption">
                                {{ formatValue(comparisonDate.hours[i].consumption) }}kWh
                            </div>
                            <div class="list-cost">
                                {{ formatValue(comparisonDate.hours[i].costs) }} &euro;
                            </div>
                        </div>

                    </li>
                </ul>
            </section>

        </div>
    </div>
</div>
