import {
    AfterViewChecked,
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2
} from '@angular/core';

@Directive({
    selector: '[halfCircle]'
})
export class HalfCircleDirective implements OnInit, AfterViewInit, AfterViewChecked {

    @Input() halfCircle: 'left' | 'right' | 'top' | 'bottom' = 'left';

    private height: number;
    private width: number;

    constructor(private elementRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngAfterViewInit(): void {
        this.initialize();
    }

    ngAfterViewChecked() {
        const height = this.elementRef.nativeElement.parentNode.clientHeight;
        const width = Math.floor(height / 2);
        if (height !== this.height && this.width !== width) {
            this.height = height;
            this.width = width;
            this.initialize();
        }
    }

    private initialize(): void {
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${this.height}px`);
        this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${this.height / 2}px`);
        const radius = this.height / 2;
        if (this.halfCircle === 'left') {
            this.renderer.setStyle(this.elementRef.nativeElement, 'border-bottom-left-radius', `${radius}px`);
            this.renderer.setStyle(this.elementRef.nativeElement, 'border-top-left-radius', `${radius}px`);
        } else if (this.halfCircle === 'right') {
            this.renderer.setStyle(this.elementRef.nativeElement, 'border-bottom-right-radius', `${radius}px`);
            this.renderer.setStyle(this.elementRef.nativeElement, 'border-top-right-radius', `${radius}px`);
        } else if (this.halfCircle === 'top') {
            this.renderer.setStyle(this.elementRef.nativeElement, 'border-top-left-radius', `${radius}px`);
            this.renderer.setStyle(this.elementRef.nativeElement, 'border-top-right-radius', `${radius}px`);
        } else if (this.halfCircle === 'bottom') {
            this.renderer.setStyle(this.elementRef.nativeElement, 'border-bottom-left-radius', `${radius}px`);
            this.renderer.setStyle(this.elementRef.nativeElement, 'borderbottom--right-radius', `${radius}px`);
        } else {
            console.log('Error: undefined direction');
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initialize();
    }
}
