import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    private cachedChangelog = null;

    constructor(protected http: HttpClient) {
    }

    getChangelog(): Observable<any> {
        const url = 'assets/changelog.json';
        return of(this.cachedChangelog).pipe(
            mergeMap((res) => res ? of(res) : this.http.get(url)),
        );
    }
}
