import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../../shared/constants/constants';
import {UserService} from '../../services/user.service';
import {MeterService} from '../../services/meter.service';
import {ApplicationService} from '../../services/application.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {RegistrationService} from '../../services/registration.service';
import {InitializationService} from '../../services/initialization.service';
import {map, mergeMap} from 'rxjs/operators';
import {OpticalReaderService} from '../../services/optical-reader.service';
import {SmartBridgeService} from '../../services/smart-bridge.service';
import {Popover} from '../../popovers/popover/popover.service';
import {ActivatedRoute} from '@angular/router';
import {
    EnergySaverControlComponent
} from '../../popovers/energy-saver-control/energy-saver-control.component';
import {
    MeterConnectionConfig,
    WifiConnectionConfig
} from '../../shared/constants/connection.constants';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {PinEntryPopoverConfig} from '../../popovers/static.popover.config';
import {iif, Observable, of, throwError} from 'rxjs';
import {OptInService} from '../../services/opt-in.service';

@Component({
    selector: 'iona-app',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent extends BaseComponent implements OnInit, OnDestroy {

    config: any = {
        tracking: false,
        optIn: 1
    };

    // connection qualities
    wifiConnectionQuality = 0;
    wifiConnected = 0;
    lanConnected = false;
    wifiConnectionConfig = WifiConnectionConfig;

    meterConnectionQuality = 0;
    meterStatus = 'disconnected';
    meterConnectionConfig = MeterConnectionConfig;
    pincode = null;

    deviceIsPlug = false;

    isEDGUser = false;
    currentBatteryState = 0;
    currentEnergySaverThreshold = 1;
    pinEntryUnknownOrOptical = false;

    constructor(public application: ApplicationService,
                public userService: UserService,
                private pageTitle: Title,
                private notifificationService: ToastrService,
                private meterService: MeterService,
                private versionService: VersionService,
                private registrationService: RegistrationService,
                private initializationService: InitializationService,
                private analyticsService: TrackAnalyticsService,
                private opticalReader: OpticalReaderService,
                private smartBridge: SmartBridgeService,
                private popover: Popover,
                private route: ActivatedRoute,
                private optInService: OptInService) {
        super();
    }

    ngOnInit() {
        this.initComponent();
    }

    ngOnDestroy() {
    }

    /**
     * Save the made changes
     */
    saveSettings() {
        this.analyticsService.changeTrackingState(this.config.tracking);
        this.registrationService.optInDevice(this.config.optIn).subscribe(
            (res) => null);
        this.notifificationService.success('' +
            'Ihre Einstellungen wurden erfolgreich gespeichert!');
        this.optInService.checkStatus();
    }

    /**
     * Determine the current Meter status message
     */
    determineMeterStatusMessage(): string {
        switch (this.meterStatus) {
            case 'connected':
                return 'verbunden';
            default:
                return 'nicht verbunden';
        }
    }

    /**
     * Opens the EnergySaverConfig Overlay
     */
    onEnergySaverOpen(): void {
        this.popover.open({
            content: EnergySaverControlComponent,
            data: {threshold: this.currentEnergySaverThreshold},
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe((res) => {
        });
    }

    /**
     * Set the tracking settings
     */
    setTracking(value: boolean) {
        this.config.tracking = value;
    }

    /**
     * Set the opt in settings
     */
    setOptIn(value: boolean) {
        this.config.optIn = value;
    }

    /**
     * Opens the Pin Entry Dialog
     */
    onPinEntryOpen(): void {
        const config = PinEntryPopoverConfig;
        delete config.data.hasSkip;
        if (this.pincode) {
            config.data.text +=
                ` Ihre aktuelle Zähler-Pin lautet: ${this.pincode}.`;
        }
        this.popover.open(config).afterClosed$.pipe(
            mergeMap((value: any) => iif(
                () => value.data,
                this.meterService.putOpticalReaderPin(value.data),
                of(false)
            ))
        ).subscribe(res => {
        });
    }

    /**
     * Base component initialization
     */
    private initComponent(): void {
        this.pageTitle.setTitle('Einstellungen | iONA');
        this.setupOptInHandling();

        const device = this.userService.getUserDevice();
        console.log('device', device);
        this.deviceIsPlug = device === constants.application.devices.plug
            || device === constants.application.devices.plug_optical;

        this.isEDGUser = this.userService.isEDGUser();
        this.addSub(this.initializationService.getMeterConnectionInfo()
            .subscribe(status => this.meterStatus = status));

        this.setupMeterStatusHandling();

        if (this.isEDGUser) {
            this.getOpticalReaderBatteryStatus();
        }

        this.config.tracking = this.analyticsService.getTrackingState();
        this.listenForRouteChanges();
    }


    /**
     * Listen for changes on the current component route
     *  if the field 'open' container 'energy-saver' directly
     *  open the energy saver configuration overlay
     */
    private listenForRouteChanges(): void {
        this.route.params.subscribe((params) => {
            if (!('open' in params)) {
                return;
            }
            if (params.open === 'energy-saver') {
                this.onEnergySaverOpen();
            }
        });
    }

    /**
     * Request the current battery status of the optical reader
     */
    private getOpticalReaderBatteryStatus(): void {
        this.opticalReader.getOpticalReaderStatus().subscribe((res) => {
            this.currentBatteryState = res.battery_status;
            this.meterConnectionQuality = res.connection_quality;
            this.pincode = res.pincode;
        });
    }

    /**
     * Setup handling for meter/status
     * if the user is a user with an optical reader --> also check meterreader/status
     */
    private setupMeterStatusHandling(): void {
        // extract wifi connection quality
        this.meterService.getStatus().pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(
            result => this.handleMeterWifiValue(result),
            error => null,
            () => null
        );

        this.meterService.startLiveUpdate();
        this.addSub(this.meterService.onMeterStatus.pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(result =>
                this.handleMeterWifiValue(result),
            error => null,
            () => null
        ));

        if (!this.isEDGUser) {
            return;
        }

        this.addSub(this.registrationService.getOnline().pipe(
            mergeMap((res) => {
                if (!this.isEDGUser) {
                    return this.meterService.getStatus().pipe(
                        mergeMap(meterResponse => of({meterReaderResponse: null, meterResponse}))
                    );
                }
                return this.opticalReader.getOpticalReaderStatus(true);
            }),
            map((results: any) => {
                this.meterConnectionQuality = ('meter_txrssi' in results) ? results.meter_txrssi : 0;
                try {
                    this.pinEntryUnknownOrOptical =
                        results.electricity.smartreader.pin_entry_mode === 'optical' ||
                        results.electricity.smartreader.pin_entry_mode === 'unknown';
                } catch (e) {
                    return null;
                }

                return null;
            })).subscribe((res) => null));
    }

    /**
     * Utility method to extract the Wifi Connection parameter from a passed request
     * @param response
     */
    private extractMeterWifiConnection(response): Observable<number> {
        try {
            return of(response['wlan_rssi']);
        } catch (e) {
            return throwError(e);
        }
    }

    /**
     * Handle Meter Wifi Value and determine conneciton strength as well status
     * @param value
     */
    private handleMeterWifiValue(value: number): void {
        this.wifiConnectionQuality = value;
        this.wifiConnected = this.wifiConnectionQuality;
        if (this.wifiConnectionQuality === 0) {
            this.lanConnected = true;
        }
    }

    /**
     * Sets up the initial opt in handling so that users can give and revoke their permissions
     */
    private setupOptInHandling(): void {
        this.optInService.checkStatus();

        const s = this.optInService.onDialogSettingsChange.subscribe(result =>
            this.config.optIn = result
        );
        this.addSub(s);

        this.registrationService.getOptInStatus().subscribe(
            res => {
                this.config.optIn = res;
            });
    }

}
