import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {constants} from '../shared/constants/constants';
import {catchError, mergeMap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService extends BaseService {

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
    }

    getStoredPlugAttributes(): Observable<any> {
        return this.getStoredUserSettings().pipe(
            mergeMap(settings => {
                if (this.validateParsedStorageObject(settings)) {
                    return of(settings.power_checker);
                }
                return throwError({msg: 'Invalid PowerChecker Settings'});
            })
        );
    }


    private getStoredUserSettings(): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.configuration.dashboard;
        return this.http.get( url ).pipe(
            mergeMap((res: { status: string, data: any }) => {
                return of(this.mapDefault(res));
            }),
            mergeMap((mapped: string) => {
                let parsed = null;
                try {
                    parsed = JSON.parse(mapped);
                    return of(parsed);
                } catch (e) {
                    return throwError({msg: 'Error parsing expected string resource', e});
                }
            }),
            catchError(error => this.handleError(error))
        );
    }

    private validateParsedStorageObject(obj: any) {
        if ('power_checker' in obj) {
            const name = 'name' in obj.power_checker;
            const room = 'room' in obj.power_checker;
            return name && room;
        }
        return false;
    }
}
