<div id="container">
    <app-menu activeElement="imprint"></app-menu>
    <div id="inner">
        <main class="page">
            <div class="page-card">
                <section #imprintContainer class="imprint"></section>
            </div>
        </main>
    </div>
</div>
