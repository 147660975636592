import {Component, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {UserService} from '../../../services/user.service';
import {PowercheckerStateService} from '../../../services/powerchecker-state.service';
import {PowerCheckerService} from '../../../services/powerchecker.service';
import {ApplicationService} from '../../../services/application.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../services/configuration.service';

@Component({
    selector: 'app-powerchecker-tile',
    templateUrl: './powerchecker-tile.component.html',
    styleUrls: ['./powerchecker-tile.component.scss'],
    providers: [Globals]
})

export class PowerCheckerTileComponent implements OnInit, OnDestroy {
    private readonly type: TILE_TYPE = TILE_TYPE.POWER_CHECKER;

    plugAttributes = {
        name: '',
        room: ''
    };

    plugName = 'Mein Gerät';
    plugRoom = 'Mein Zimmer';

    state = false;

    subscriptions: Subscription[] = [];

    constructor(private globals: Globals,
                private userService: UserService,
                private powercheckerState: PowercheckerStateService,
                private powerchecker: PowerCheckerService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private configuration: ConfigurationService) {
    }

    ngOnInit() {
        this.initPlugAttributes();
        this.getPlugAttributes();
        this.initializeDetailViewAttributeChangeListener();
        this.initPowerCheckerStateChange();
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    onTileClicked(event): void {
        if (event.target.id === 'sliderKnob') {
            return;
        }
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    initializeDetailViewAttributeChangeListener(): void {
        // subscribe to external changes
        this.userService.plugAttributesChanged.subscribe(
            (values: any) => {
                this.plugAttributes = values;
                this.plugName = this.plugAttributes.name;
                this.plugRoom = this.plugAttributes.room;
            }
        );

        // subscribe to state changes
        this.powercheckerState.stateChange.subscribe(
            (value) => {
                this.state = value;
            }
        );
    }

    private initPlugAttributes(): void {
        this.plugAttributes = this.userService.getPlugAttributes();
        if (this.plugAttributes === null || this.plugAttributes === undefined) {
            this.getPlugAttributes();
            return;
        }

        this.plugName = this.plugAttributes.name;
        this.plugRoom = this.plugAttributes.room;
    }

    private getPlugAttributes(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        const s = this.configuration.getStoredPlugAttributes().subscribe(
            (result) => {
                if (result.name !== '') {
                    this.plugName = result.name;
                }
                if (result.room !== '') {
                    this.plugRoom = result.room;
                }
                this.userService.updatePlugAttributes(result);
            },
            (error) => null,
            () => s.unsubscribe()
        );
    }

    onStateChange(state: boolean): void {
        this.state = state;
        this.powercheckerState.stateChange.next(state);
        if (this.application.isDemoMode()) {
            return;
        }
        this.powerchecker.setState(state ? 1 : 0).subscribe({
            next: (res) => {
            },
            error: (error) => console.log('Error:', error)
        });
    }


    /**
     * Inititialize state change synchronization between tile and detail view
     */
    private initPowerCheckerStateChange(): void {
        const s = this.powerchecker.getState().subscribe({
            next: (res) => {
                const value = res.relay.on_off;
                this.state = value === 1;
            },
            error: (error) => console.log('Error:', error),
            complete: () => s.unsubscribe()
        });
    }


    // TRACKING
    // =============================================================================================
    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: PowerChecker'
            }
        });
    }


    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Powerchecker-Details'
            }
        });
    }

}
