import {Injectable} from '@angular/core';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';

@Injectable()
export class Globals {
    private keyFirstDetails = StorageAttributes.IS_FIRST_DETAILS_VIEW;
    private keyIsMeterConnected = StorageAttributes.IS_METER_CONNECTED;

    constructor() {
        localStorage.setItem(this.keyFirstDetails, '0');
    }

    setFirstDetailsViews() {
        localStorage.setItem(this.keyFirstDetails, '1');
    }

    getFirstDetailsViewed() {
        return (localStorage.getItem(this.keyFirstDetails) === '1');
    }

    setIsMeterConnected() {
        localStorage.setItem(this.keyIsMeterConnected, '1');
    }

    resetIsMeterConnected() {
        localStorage.removeItem(this.keyIsMeterConnected);
    }

}
