<div class="overlay">
    <div class="detail-overlay">

        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">Zählerstand</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
                <section>
                    <div class="center-contents current-meter-value">
                        <div> {{ meterStats.kwh }} kWh</div>
                    </div>
                    <div *ngIf="meterStats.id && meterStats.id.length > 0"
                         class="meter-id center-contents font-light m-b-m">
                        Zählernummer&nbsp;<strong>{{ meterStats.id }}</strong>
                    </div>
                    <div class="connected">
                        <div class="connection-container">
                            <div class="m-r-m">
                                <app-connection-indicator [value]="connection_quality"
                                                          container_height="24px"
                                                          [disabled]="meter_status!== 'connected'"
                                                          [invert_colors]="true"
                                                          [config]="connectionConfig">
                                </app-connection-indicator>
                            </div>
                            <h4 class="connection-text font-light"
                                [ngClass]="{connected: meter_status === 'connected', disconnected: meter_status !== 'connected'}">
                                {{determineMeterStatusMessage()}}
                            </h4>
                        </div>
                    </div>
                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">Zählerstand: Was ist das?</h2>
                    <p>
                        Der Weg in den Keller bleibt Ihnen erspart: Rufen Sie jederzeit bequem Ihren aktuellen
                        Zählerstand ab. Wetten, dass iONA sich an jeden Zählerstand erinnern kann? Probieren Sie es aus!
                    </p>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <section class="detail-content">
                <ng-container *ngIf="false">
                    <p>
                        Melden Sie ganz einfach Ihren <strong>aktuellen Zählerstand</strong>.
                    </p>
                    <p>
                        <button class="iona-button orange">Jetzt melden</button>
                    </p>
                </ng-container>
                <div>
                    <strong>Zählerstand zum Stichtag</strong>
                    <p>
                        Zählerstand zum Stichtag Hier können Sie Ihren Zählerstand zu einem bestimmten Stichtag abrufen.
                        Einfach Wunschdatum eingeben und Zählerstand ablesen.
                    </p>
                </div>
                <div class="meter-value-date-select">
                    <div class="box-container">
                        <div class="date-input-container m-b-m">
                            <span class="p-r-m"> Ihr Zählerstand zum </span>
                            <div class="date-input center-contents">
                                <input #dateSelect id="dayInput" type="date"
                                       min="2018-01-01" [max]="today"
                                       (change)="setDate($event)">
                                <!--                                   (change)="onDateChange(dateSelect.value)">-->
                                <label class="" for="dayInput">{{searchDateDisplay}}</label>
                            </div>
                        </div>
                        <div class="center-contents">
                            <div class="meter-value-date-value">
                                <span *ngFor="let p of selectedConsumption.toString().split('')"
                                      [style.color]="p !== '-' ? '#e60055': '#cfcecf'">
                                    {{p}}
                                </span>
                                <span> kWh </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    </div>
</div>
