<div class="connection-container">
    <div class="battery-state-container">
        <ng-container *ngIf="currentBatteryState > 0 || !isAvailable; else plugged">
            <div *ngFor="let s of batteryStates"
                 class="battery-bar"
                 [ngClass]="{
                     'active' : s <= currentBatteryState,
                     'small': smallBars,
                      'warn': s <= currentBatteryState && currentBatteryState === 1 && coloredWarning}">
            </div>
        </ng-container>
        <ng-template #plugged>
            <div class="wall-plug-icon" [ngClass]="{'small': smallBars}"></div>
        </ng-template>
    </div>
    <div [ngClass]="{'warning': currentBatteryState === 1 && coloredWarning, 'innogy-medium': boldText}">
        <ng-container *ngIf="isAvailable; else notAvailable">
            {{currentBatteryState > 0 ? currentBatteryState * 2 + '0% geladen' : 'Netzteil verbunden'}}
        </ng-container>
        <ng-template #notAvailable>
            nicht verfügbar
        </ng-template>
    </div>
    <div *ngIf="coloredWarning && currentBatteryState === 1" class="warn-triangle">
    </div>
</div>
