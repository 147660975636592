import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {Url} from '../lib/Url';
import * as moment from 'moment';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class HappyHourService extends BaseService {
    // onConsumptionUpdate = new Subject<any>();

    private updateRate = 10000;
    private timerSub: Subscription = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
    }


    /**
     * Returns whether the user participates in the HappyHour.
     */
    getParticipation(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.iona.happyHour.participation;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.happyHourParticipation}.json`;
        }

        return this.http.get(url.toString()).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((e) => {
                console.log(e);
                return throwError(e);
            })
        );
    }


    /**
     * Request Happy Hour schedule for a specific timeframe
     * @param date
     */
    requestSchedule(date): Observable<HappyHourSchedule> {
        const month = date.month();
        const year = date.year();

        return this.getSchedule(year, month).pipe(
            mergeMap((response) => {
                if (!response) {
                    return throwError('Error fetching happy hour schedule');
                }
                if (response.length === 0) {
                    return throwError('Error: schedule is empty');
                }
                return of(response);
            }),
            mergeMap((schedule: HappyHourScheduleResponseItem[]) => {
                return this.extractSchedule(schedule, date);
            })
        );

    }


    /**
     * Returns the consumption for a specific timeframe
     * @param fct
     * @param first
     * @param second
     */
    getConsumptionFor(fct: 'day' | 'week' | 'month' | 'year', first: string, second: string): Observable<any> {
        const url = new Url(null);
        url.push(this.API_BASE_URL);

        switch (fct) {
            case 'day':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.days);
                break;
            case 'month':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.months);
                break;
            case 'year':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.years);
                break;
            case 'week':
                break;
        }
        url.push(first.toString());
        url.push(second.toString());

        return this.http.get(url.toString()).pipe(
            map((res: any) => {
                    if (fct !== 'week') {
                        return res['data'];
                    } else {
                        if ('data' in res) {
                            const extracted_data = {};
                            const data = res['data'];
                            for (const element of data) {
                                const week = moment(element.timestamp).week();
                                if (extracted_data[week] === null || extracted_data[week] === undefined) {
                                    extracted_data[week] = {value: 0, timestamp: element.timestamp};
                                }
                                extracted_data[week].value += element.measured;
                            }
                            const final_data = [];
                            for (const key of Object.keys(extracted_data)) {
                                const obj = extracted_data[key];
                                final_data.push({
                                    timestamp: obj.timestamp,
                                    measured: obj.value
                                });
                            }
                            return final_data;
                        }
                        return res['data'];
                    }
                }
            ),
            catchError((e) => this.handleError(e))
        );
    }


    /**
     * Request the current HappyHour Schedule
     * @param year
     * @param month
     */
    private getSchedule(year: number, month: number): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.iona.happyHour.schedule;
        url += `/${year}`;
        url += `/${month}`;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.happyHourSchedule}.json`;
        }

        return this.http.get(url.toString()).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((e) => this.handleError(e))
        );
    }


    /**
     * Mapping function to extract the HappyHour schedule
     * @param response
     * @param date
     */
    private extractSchedule(response: any, date: any): Observable<HappyHourSchedule> {
        let today_schedule = null;
        let yesterday_schedule = null;
        const weekday = date.isoWeekday();
        for (let i = 0; i < response.length; ++i) {
            const element = response[i];
            if (element.dayofweek === weekday) {
                today_schedule = element;
            }
            if (element.dayofweek === 7) {
                yesterday_schedule = response[0];
            } else if (element.dayofweek === 1) {
                yesterday_schedule = response[6];
            } else {
                yesterday_schedule = response[i - 1];
            }
        }

        const schedule = {} as HappyHourSchedule;

        schedule.currentStart = date.hour(today_schedule.starttime)
            .minute(0).second(0).millisecond(0).toDate();
        schedule.currentEnd = date.hour(today_schedule.endtime)
            .minute(0).second(0).millisecond(0).toDate();

        schedule.todayStart = date.hour(today_schedule.starttime)
            .minute(0).second(0).millisecond(0).toDate();
        schedule.todayEnd = date.hour(today_schedule.endtime)
            .minute(0).second(0).millisecond(0).toDate();

        const yesterday = moment(date).add(1, 'day');
        schedule.yesterdayStart = yesterday.hour(yesterday_schedule.starttime)
            .minute(0).second(0).millisecond(0).toDate();
        schedule.yesterdayEnd = yesterday.hour(yesterday_schedule.endtime)
            .minute(0).second(0).millisecond(0).toDate();

        return of(schedule);
    }
}

interface HappyHourScheduleResponseItem {
    dayofweek: number;
    endtime: number;
    month: number;
    starttime: number;
    year: number;
}

interface HappyHourSchedule {
    currentStart: Date;
    currentEnd: Date;
    todayStart: Date;
    todayEnd: Date;
    yesterdayStart: Date;
    yesterdayEnd: Date;
}
