<div id="container">
    <app-menu activeElement="imprint"></app-menu>
    <div id="inner">
        <main class="page">
            <div class="page-card">
                <h1>Impressum</h1>
                <section class="imprint">
                    <div class="inner">
                        <h1>Datenschutz</h1>
                        <p>
                            Im Demomodus werden keinerlei Daten erfasst oder übertragen. Erst nach Registrierung und
                            Anmeldung werden Daten gemäß der Datenschutzbestimmungen unseres Services verarbeitet.
                        </p>
                    </div>
                </section>
            </div>
        </main>
    </div>
</div>
