<div class="overlay">
    <div class="overlay-container-cdk">
        <div class="col changelogs">
            <div *ngFor="let logs of changelog.versions" class="version-block">
                <h4>{{ logs.version }}</h4>

                <strong>Neue Funktionen:</strong>
                <ul *ngIf="logs.features.length > 0">
                    <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
                </ul>

                <strong>Anpassungen</strong>
                <ul *ngIf="logs.adjustments.length > 0">
                    <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
                </ul>

                <strong>Bugfixes:</strong>
                <ul *ngIf="logs.bugfixes.length > 0">
                    <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
