<main class="register-container">

    <div class="info-btn iona-icon-only-button info-big" (click)="openInfo()"></div>

    <!--
      -- ACCOUNT CREATION --------------------------------------
      -->
    <section class="white" *ngIf="currentStep === onboardingSteps.accountCreation">
        <div class="top-logo center-contents"></div>
        <form class="login-form" (submit)="validateVoucher(voucherInput.value, email.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #email type="email" name="email" placeholder="E-Mail-Adresse"
                           class="iona-input">
                </div>
                <div class="btn-container">
                    <input #voucherInput type="text" name="code" placeholder="Code"
                           class="iona-input">
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">Weiter</button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" target="_self" routerLink="/">Anmelden</a>
            </div>
        </form>
    </section>

    <!---- SET PASSWORD ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.passwordEntry">
        <div class="top-logo center-contents">
        </div>
        <h1>Wählen Sie ein Passwort und bestätigen Sie es.</h1>
        <form class="login-form" [formGroup]="passwordForm" (ngSubmit)="registerUser()">
            <div class="grow-space">
                <div class="btn-container">
                    <input id="first-password" type="password" class="iona-input"
                           placeholder="Passwort"
                           formControlName="password1">
                    <label for="first-password"></label>
                    <p class="password-info">
                        Das Passwort muss aus 8 Stellen bestehen und mindestens einen Großbuchstaben sowie eine Zahl
                        enthalten.
                    </p>
                    <div class="password-strength">
                        <span *ngFor="let i of [0,1,2,3,4]"
                              [class.active]="i <= currentPasswordScore"></span>
                        <div class="password-score-label">
                            Passwort: <span>{{determinePasswordScoreRatingText()}}</span>
                        </div>

                    </div>
                </div>
                <div class="btn-container">
                    <input id="second-password" type="password" class="iona-input"
                           placeholder="Passwort wiederholen"
                           formControlName="password2">
                    <label for="second-password"></label>
                </div>
            </div>

            <div class="btn-container center-contents">
                <button class="iona-button dark"
                        type="submit"
                        [disabled]="setPasswordDisabled">Weiter
                </button>
            </div>
        </form>
        <!--        <form class="login-form" (submit)="registerUser(password1.value, password2.value)">-->
        <!--            <div class="grow-space">-->
        <!--                <div class="btn-container">-->
        <!--                    <input #password1 type="text" name="password1" placeholder="Passwort eingeben"-->
        <!--                           class="iona-input">-->
        <!--                </div>-->
        <!--                <div class="btn-container">-->
        <!--                    <input #password2 type="text" name="password2" placeholder="Passwort wiederholen"-->
        <!--                           class="iona-input">-->
        <!--                </div>-->
        <!--            </div>-->

        <!--            <div class="center-contents btn-container">-->
        <!--                <button class="iona-button dark" type="submit">Weiter</button>-->
        <!--            </div>-->
        <!--        </form>-->
    </section>

    <!---- SELECT DEVICE ---------------------------------------->
    <section class="big white"
             *ngIf="currentStep === onboardingSteps.deviceSelection">
        <h1 class="m-b-x">Bitte wählen Sie ein Gerät</h1>
        <div class="device-selection">
            <div>
                <div>
                    <img src="../../../assets/images/device-box.png" height="300px">
                </div>
                <button class="iona-button dark" (click)="selectDevice('box')">iONA Box</button>
            </div>
            <div>
                <div>
                    <img src="../../../assets/images/device-plug.png" height="300px">
                </div>
                <button class="iona-button dark" (click)="selectDevice('plug')">PowerChecker</button>
            </div>
        </div>
    </section>


    <!---- HARDWARE PRESENT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.hardwarePresent">
        <h1 class="m-b-x">Haben Sie alle notwendigen Komponenten für die Installation vorliegen? </h1>
        <div class=" checklist-container">
            <ul class="checklist">
                <li class="font-bold"> iONA PowerChecker</li>
                <li class="font-bold"> Optischer Ausleser</li>
                <li *ngIf="userService.isERNAUser()" class="font-bold"> 4-stellige Geräte-PIN des Stromzählers*</li>
                <li class="font-bold"> 3 AA-Batterien oder USB-Netzteil</li>
                <li class="font-bold"> Zugang zu Ihrem Stromzähler</li>
            </ul>
            <p class="subtext">
                *Diese erhalten Sie in der Regel telefonisch von Ihrem Messstellenbetreiber. Die Information, wer Ihr
                Messstellenbetreiber ist, finden Sie auf Ihrer Jahresabrechnung oder ggf. auf dem Stromzähler.
            </p>
        </div>
        <div class="center-contents btn-container">
            <button class="iona-button dark" (click)="setStep(onboardingSteps.analyticsOptIn)">
                Ja, jetzt einrichten
            </button>
        </div>
        <div class="center-contents">
            <a (click)="hardwareNotReady()">Nein, noch nicht.</a>
        </div>
    </section>


    <!---- ANALYTICS ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.analyticsOptIn">
        <h1 class="m-b-x">Stimmen Sie zu, unseren Service vollumfänglich zu nutzen?</h1>

        <div class="permissions-wrap">
            <form class="login-form" (submit)="checkOptInResponse(trackingCb.checked, optInCb.checked)">
                <div class="permissions-list">

                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="tracking-checkbox">
                            <input #trackingCb type="checkbox" id="tracking-checkbox">
                            Tracking erlauben
                        </label>
                        <p>
                            Wir möchten uns gerne weiterentwickeln und verbessern. Aus diesem Grund analysieren wir
                            anonymisiert die Nutzung der Webseite.
                        </p>
                    </div>

                    <!--                    <fieldset>-->
                    <!--                        <div class="formItem checkbox-wrap">-->
                    <!--                            <input #trackingCheckbox type="checkbox" id="config-tracking">-->
                    <!--                            <label for="config-tracking">-->
                    <!--                                <i></i>-->
                    <!--                                <span>-->
                    <!--                                    <strong>Tracking erlauben</strong>-->
                    <!--                                    <small>Wir möchten uns gerne weiterentwickeln und verbessern.-->
                    <!--                                        Aus diesem Grund analysieren wir anonymisiert die Nutzung der Webseite.-->
                    <!--                                    </small>-->
                    <!--                                </span>-->
                    <!--                            </label>-->
                    <!--                        </div>-->
                    <!--                    </fieldset>-->


                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="optin-checkbox">
                            <input #optInCb type="checkbox" name="checkbox" id="optin-checkbox">
                            Online-Modus erlauben
                        </label>
                        <p>
                            Wenn Sie alle Funktionen von iONA nutzen möchten, können Sie hier für die Zukunft in den
                            Online-Modus wechseln. Damit geben Sie uns Ihre Einwilligung um auf die
                            Verbrauchsdaten {{device === 'plug' ? 'Ihres Powercheckers' : 'Ihrer Box'}} zugreifen zu
                            dürfen. Dies beinhaltet unter anderem ihren Stromverbrauch in Sekunden-Auflösung. Dieser
                            Zugriff ist notwendig, damit wir Ihnen den vollen Funktionsumfang - der in den
                            Nutzungsbedingungen der App beschrieben wird – anbieten können. Die weitere Verarbeitung
                            dieser Daten wird in unseren Datenschutzhinweisen und den folgenden Einwilligungserklärungen
                            beschrieben. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen
                            indem Sie die Option in den Einstellungen wieder deaktivieren.
                        </p>
                    </div>

                    <!--                    <fieldset>-->
                    <!--                        <div class="formItem checkbox-wrap">-->
                    <!--                            <input #optInCheckbox type="checkbox" id="config-optin">-->
                    <!--                            <label for="config-optin">-->
                    <!--                                <i></i>-->
                    <!--                                <span>-->
                    <!--                                    <strong>Online-Modus erlauben</strong>-->
                    <!--                                    <small>-->
                    <!--                                        Wenn Sie alle Funktionen von [iONA] nutzen möchten, können Sie hier für die Zukunft in den Online-Modus wechseln. Damit geben Sie der [E.ON Energie Deutschland GmbH] Ihre Einwilligung um auf die Verbrauchsdaten Ihrer [iONA Empfangseinheit] zugreifen zu dürfen. Dies beinhaltet unter anderem ihren Stromverbrauch in Sekunden-Auflösung. Dieser Zugriff ist notwendig, damit wir Ihnen den vollen Funktionsumfang - der in den Nutzungsbedingungen der App beschrieben wird – anbieten können. Die weitere Verarbeitung dieser Daten wird in unseren Datenschutzhinweisen und den folgenden Einwilligungserklärungen beschrieben. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen indem Sie die Option in den Einstellungen wieder deaktivieren.-->
                    <!--                                    </small>-->
                    <!--                                </span>-->
                    <!--                            </label>-->
                    <!--                        </div>-->
                    <!--                    </fieldset>-->
                </div>
                <div class="permissions-btn-wrap">
                    <button class="iona-button dark" type="submit">Weiter</button>
                </div>
            </form>
        </div>
    </section>

    <!---- BOX POWER CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.powerConnect">
        <h1 class="m-b-x">Bitte verbinden Sie die Hardware jetzt mit der Stromversorgung.</h1>
        <div class="device-video-container">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/onboarding_connect_power.mp4" type="video/mp4">
            </video>
        </div>
        <p>
            Für eine gute Verbindungsqualität platzieren Sie das Gerät bitte in W-LAN-Reichweite so nah wie möglich bei
            Ihrem Zähler.
        </p>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.box.lanConnect)">
                Hab ich gemacht
            </button>
        </div>
    </section>

    <!---- BOX LAN CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.lanConnect">
        <h1 class="m-b-x">Verbinden Sie die iONA Box über das mitgelieferte Netzwerkkabel mit Ihrem
            Router.</h1>
        <div class="device-video-container">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/onboarding_connect_lan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.box.macEntry)">
                Hab ich gemacht
            </button>
        </div>
    </section>

    <!---- BOX MAC ENTRY ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry">
        <div class="top-logo center-contents"></div>
        <h1>Geben Sie die MAC-ID von der Rückseite Ihrer iONA Box ein.</h1>
        <h2>Damit verknüpfen wir Ihre iONA Box mit Ihrem Account.</h2>
        <form class="login-form m-t-x" (submit)="setFirstMACAddress(mac.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #mac class="iona-input" type="text" name="mac"
                           [textMask]="macAddressMask"
                           [(ngModel)]="firstMac" [ngModelOptions]="{standalone: true}"
                           [placeholder]="macPlaceholder">
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">Weiter</button>
            </div>
        </form>
    </section>

    <!---- BOX MAC ENTRY 2 ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry2">
        <div class="top-logo center-contents">
        </div>
        <h1>Bitte überprüfen Sie die eingegebene Adresse erneut. Stimmt Sie mit dem Aufkleber auf der iONA-Box
            überein?</h1>
        <form class="login-form m-t-x" (submit)="registerDevice(mac2.value,true)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #mac2 class="iona-input" type="text" name="mac"
                           [textMask]="macAddressMask"
                           [(ngModel)]="secondMac" [ngModelOptions]="{standalone: true}"
                           [placeholder]="macPlaceholder">
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">Weiter</button>
            </div>
        </form>
    </section>

    <!---- BOX CONNECTION ---------------------------------------->
    <section class="connection white" *ngIf="currentStep === onboardingSteps.devices.box.connecting">
        <h1>Wir verbinden jetzt Ihre iONA Box mit Ihrem Smart Meter</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div class="progress-label">iONA Box vorbereiten</div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div class="progress-label">Smart Meter suchen</div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div class="progress-label">Mit Smart Meter verbinden</div>
                </li>
            </ul>
        </div>
        <ng-container *ngIf="meterStatus > 0">
            <div class="last-growing">
                <button class="iona-button dark"
                        (click)="isERNAUser ? setStep(onboardingSteps.tariffEntry) :
                                   setStep(onboardingSteps.accountSetup)">
                    Schon ab ins Dashboard
                </button>
            </div>
        </ng-container>
    </section>

    <!---- PLUG NOTE MAC ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.noteMac">
        <h1>Bitte notieren Sie sich die MAC-Adresse von der Rückseite Ihres PowerCheckers.</h1>
        <div class="center-contents m-v-x">
            <img src="assets/img/onboarding/plug-mac.png" alt="" height="400px">
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.powerConnect)">
                Weiter
            </button>

        </div>
    </section>

    <!---- PLUG POWER CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.powerConnect">
        <h1>Bitte verbinden Sie den PowerChecker jetzt mit der Stromversorgung.</h1>
        <video class="m-v-x" width="100%" autoplay muted loop>
            <source src="assets/media/powerchecker-connect.mp4" type="video/mp4">
        </video>
        <p>
            Für eine gute Verbindungsqualität platzieren Sie das Gerät bitte in W-LAN-Reichweite so nah wie möglich bei
            Ihrem Zähler.
        </p>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.appSelect)">
                Weiter
            </button>
        </div>
    </section>

    <!---- APP SELECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.appSelect">
        <h1>Der PowerChecker muss nun mit Ihrem WLAN verbunden werden. Einfach und unkompliziert
            funktioniert dies über WPS.</h1>
        <div class="center-contents m-v-x">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.wpsConnect)">
                Weiter
            </button>

        </div>
        <div>
            <p>
                Sollte Ihr Router kein WPS unterstützen, so verwenden Sie bitte die iONA-App auf dem Smartphone/Tablet.
                Nähere Informationen zu WPS finden Sie in der Bedienungsanleitung Ihres Routers.
            </p>
            <div class="store-links m-b-l">
                <a target="_blank" class="center-contents"
                   href='https://play.google.com/store/apps/details?id=com.iona_energy.android&hl=en'>
                    <img class="p-r-l" alt='Jetzt bei Google Play' src='assets/images/badges/googleplay.png'/>
                </a>
                <a target="_blank" class="center-contents"
                   href="https://apps.apple.com/de/app/iona/id1434046824">
                    <img class="p-l-l" src="assets/images/badges/appstore.png" alt="Laden im AppStore">
                </a>
            </div>
        </div>

    </section>

    <!---- WPS CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.wpsConnect">
        <h1>Drücken Sie den Ein-Aus-Schalter des PowerCheckers ca. 10 Sekunden bis dieser schnell grün
            blinkt.</h1>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="assets/media/powerchecker-2.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.wpsRouter)">
                Weiter
            </button>

        </div>
    </section>

    <!---- WPS ROUTER ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.wpsRouter">
        <h1>Aktivieren Sie jetzt innerhalb von 2 Minuten die WPS-Funktion an Ihrem Router.</h1>
        <p class="info-white">
            In der Regel erfolgt dies durch längeres Drücken eines entsprechend bezeichneten Knopfes. Nähere
            Informationen zu WPS finden Sie in der Bedienungsanleitung Ihres Routers.
        </p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="assets/media/wlan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.wifiConnected)">
                Weiter
            </button>

        </div>
    </section>

    <!---- WPS CONNECTED---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.wifiConnected">
        <h1>Bitte warten Sie, bis der PowerChecker konstant blau leuchtet. Dies kann bis zu 3 Minuten
            dauern.</h1>
        <div class="m-v-x">
            <img src="assets/images/powerchecker-onboarding-final-state.png"
                 alt="Blau leuchtender Powerchecker" height="400px">
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.macEntry)">
                Weiter
            </button>

        </div>
    </section>

    <!---- PLUG MAC ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.macEntry">
        <div class="top-logo center-contents">
        </div>
        <h1>Geben Sie die MAC-ID von der Rückseite Ihres PowerCheckers ein.</h1>
        <h2>Damit verknüpfen wir Ihren PowerChecker mit Ihrem Account.</h2>
        <form class="login-form m-t-x" (submit)="setFirstPlugMACAddress(mac.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #mac type="text" class="iona-input"
                           [textMask]="macAddressMask"
                           [(ngModel)]="firstMac" [ngModelOptions]="{standalone: true}"
                           [placeholder]="macPlaceholder">
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">Weiter</button>
            </div>
        </form>
    </section>

    <!---- PLUG MAC ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.macEntry2">
        <div class="top-logo center-contents">
        </div>
        <h1>Bitte überprüfen Sie die eingegebene Adresse erneut. Stimmt Sie mit dem Aufkleber auf dem PowerChecker
            überein?</h1>
        <form class="login-form m-t-x"
              (submit)=" isERNAUser ? setStep(onboardingSteps.opticalReader.deviceSelection) : registerDevice(mac2.value)">
            <!--            <form class="login-form m-t-x" (submit)="registerDevice(mac2.value,true)">-->
            <div class="grow-space">
                <div class="btn-container">
                    <input #mac2 type="text" class="iona-input"
                           [textMask]="macAddressMask"
                           [(ngModel)]="secondMac" [ngModelOptions]="{standalone: true}"
                           [placeholder]="macPlaceholder">
                </div>
            </div>
            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">Weiter</button>
            </div>
        </form>
    </section>

    <!---- PLUG CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connecting"
             class="white connection">
        <h1>Wir verbinden jetzt Ihren PowerChecker mit Ihrem Smart Meter.</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div class="progress-label">PowerChecker vorbereiten</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div class="progress-label">Smart Meter suchen</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div class="progress-label">Mit Smart Meter verbinden</div>
                </li>
                <li class="progress-label-sub"></li>
                <ng-container *ngIf="isEnviamUser">
                    <li [ngClass]="{'finished': (meterStatus > 3), 'progress': (meterStatus == 3)}">
                        <div class="progress-indicator" [ngClass]="{done: meterStatus > 3}"></div>
                        <div class="progress-label">Ihr Zähler wird freigeschaltet</div>
                    </li>
                    <li class="progress-label-sub"></li>
                </ng-container>
            </ul>
        </div>
        <div class="last-growing">
            <ng-container *ngIf="meterStatus > 0">
                <button class="iona-button dark" type="submit" (click)="afterConnectionFinished()">
                    Schon ab ins Dashboard
                </button>
            </ng-container>
        </div>
    </section>

    <!---- PLUG CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connectingOR" class="white connection">
        <h1>
            Wir verbinden jetzt Ihren PowerChecker mit Ihrem Stromzähler. Dies kann einige Minuten in Anspruch
            nehmen.
        </h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div class="progress-label">PowerChecker vorbereiten</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div class="progress-label">Zähler suchen</div>
                </li>
                <li class="progress-label-sub">Knopf am Ausleser erneut drücken</li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div class="progress-label">Mit Zähler verbinden</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 3), 'progress': (meterStatus == 3)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 3}"></div>
                    <div class="progress-label">Ihr Zähler wird freigeschaltet</div>
                </li>
                <li class="progress-label-sub">{{meterStatus === 3 ? 'Dies kann bis zu eine Stunde dauern.' : ''}}</li>
            </ul>
            <ng-container *ngIf="meterStatus>=3">
                <div>
                    <button class="iona-button dark" type="submit"
                            (click)="setStep(onboardingSteps.accountSetup)">
                        Schon ab ins Dashboard
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <!---- ACCOUNT SETUP ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.accountSetup">
        <div class="top-logo center-contents">
        </div>
        <h1>Herzlich Willkommen!</h1>
        <h2>
            Wir haben jetzt ein bisschen Zeit und können uns um Ihr Benutzerprofil kümmern! <br><br>
            Damit wir Ihnen genauere Informationen zu Ihrem Stromverbrauch liefern können, möchten wir Sie gerne besser
            kennenlernen. Bitte beantworten Sie uns dazu ein paar Fragen rund um Ihren Haushalt.
        </h2>
        <div class="center-contents btn-container">
            <button class="iona-button dark" type="submit" routerLink="/mein-haushalt">
                Schon ab ins Dashboard
            </button>
        </div>
    </section>

    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.connectionError">
        <div class="top-logo center-contents">
        </div>
        <h1>Leider konnte iONA {{device == 'plug' ? 'den' : 'die'}}  {{device == 'plug' ? 'PowerChecker' : 'iONA-Box'}}
            nicht finden.</h1>
        <h2>Sie können die MAC-ID erneut eingeben oder erneut mit dem Router verbinden.</h2>
        <div class="last-growing">
            <div class="center-contents">
                <button class="iona-button dark" type="submit"
                        (click)="device == 'plug' ?
                         setStep(onboardingSteps.devices.plug.macEntry) :
                          setStep(onboardingSteps.devices.box.macEntry)">
                    MAC-ID erneut eingeben
                </button>
            </div>
        </div>
    </section>

    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.notFoundError">
        <div class="top-logo center-contents">
            <h1>iONA</h1>
        </div>
        <h1>Leider konnte iONA {{device == 'plug' ? 'den' : 'die'}}  {{device == 'plug' ? 'PowerChecker' : 'iONA-Box'}}
            nicht finden.</h1>
        <h2>Sie können die MAC-ID erneut eingeben. Andernfalls kontaktieren Sie bitte den Support.</h2>
        <div class="last-growing">
            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit"
                        (click)="device == 'plug' ?
                          setStep(onboardingSteps.devices.plug.macEntry) :
                          setStep(onboardingSteps.devices.box.macEntry)">
                    MAC-ID erneut eingeben
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" target="_self" (click)="setStep(onboardingSteps.support)"
                   angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_profile_cancel">
                    Support anfordern
                </a>
            </div>
        </div>
    </section>

    <!---- SOMETHING FAILED ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.detailFailed">
        <div class="top-logo center-contents"></div>
        <h1>{{statusError.title}}</h1>
        <h2>{{statusError.text}} </h2>
        <div class="last-growing">
            <button class="iona-button dark" (click)="retryMeterStatus()">Weiter Versuchen</button>
        </div>
    </section>

    <!---- SUPPORT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.support">
        <div class="top-logo center-contents">
        </div>
        <h1>Leider konnte iONA {{device == 'plug' ? 'den' : 'die'}}  {{device == 'plug' ? 'PowerChecker' : 'iONA-Box'}}
            nicht finden.</h1>
        <p>
            Kostenlose Hotline<br>
            0800 - 8888863
        </p>
        <div class="last-growing">
            <button class="iona-button dark">Hilfe</button>
        </div>
    </section>


    <!-- OPTICAL READER DEVICE SELECTION -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.deviceSelection">
        <h1>Aufbau</h1>
        <p class="overview-text">
            Der Optische Ausleser liest die Verbrauchsdaten des Zählers über die optische Schnittstelle aus und sendet
            diese mittels des PowerCheckers an das iONA-Rechenzentrum zur Darstellung in der App.
        </p>
        <div class="flow-image-container">
            <div>
                <img src="assets/img/graphics/optical-reader-flow.png" alt="" width="100%">
            </div>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.opticalReader.installation.step1)">
                Verstanden!
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 1 -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.installation.step1">
        <h1>Platzieren Sie den Auslesekopf auf der optischen Schnittstelle Ihres Zählers. Für eine korrekte Ausrichtung
            muss das Kabel gerade nach unten zeigen.</h1>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/optical_reader/erna-04.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.opticalReader.installation.step2)">
                Schritt 1 von 4 erledigt
            </button>
        </div>
    </section>

    <!-- OPTICAL READER INSTALLATION STEP 2 -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.installation.step2">
        <h1>Verbinden Sie das Kabel des Auslesekopfes mit der Sendeeinheit des optischen Auslesers</h1>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/optical_reader/erna-05.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.opticalReader.installation.step3)">
                Schritt 2 von 4 erledigt
            </button>
        </div>
    </section>

    <!-- OPTICAL READER INSTALLATION STEP 3 -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.installation.step3">
        <h1>Schalten Sie die Sendeeinheit des optischen Auslesers ein, indem Sie entweder 3 AA-Batterien einlegen oder
            ein 5V-USB-Netzteil verbinden.</h1>
        <div class="info-white">(nicht enthalten)</div>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/optical_reader/erna-06.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.opticalReader.installation.step4)">
                Schritt 3 von 4 erledigt
            </button>
        </div>
    </section>

    <!-- OPTICAL READER INSTALLATION STEP 4 -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.installation.step4">
        <h1>Drücken Sie den Knopf auf der Sendeeinheit des optischen Auslesers für ca. 1 Sekunde. Die blaue LED sollte
            einmal kurz aufblinken.</h1>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/optical_reader/erna-07.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="afterOrOnboardingComplete()">
                alle Schritte erledigt
            </button>
        </div>
    </section>

    <!-- OPTICAL READER PIN ENTRY -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.pinEntry">
        <h1>Bitte geben Sie Ihre Zähler-PIN ein</h1>
        <h2>Die PIN wird benötigt, damit alle relevanten Daten von Ihrem Zähler übertragen werden können. <br>
            Diese erhalten Sie in der Regel telefonisch von Ihrem Messstellenbetreiber. <br>
            Die Information, wer Ihr Messstellenbetreiber ist, finden Sie auf Ihrer Jahresabrechnung oder ggf. auf dem
            Stromzähler.
        </h2>
        <form class="login-form m-t-x" (ngSubmit)="setPIN(pin.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #pin type="text" name="pin" class="iona-input" [placeholder]="1234" [attr.maxlength]="4">
                </div>
            </div>
            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit"> Akzeptieren</button>
            </div>
            <div class="center-contents">
                <a class="" (click)="setPIN(null)">Überspringen</a>
            </div>
        </form>

    </section>

    <!-- TARIFF ENTRY -->
    <section class="white" *ngIf="currentStep === onboardingSteps.tariffEntry">
        <h1>Hier können sie Informationen zu Ihrem aktuellen Tarif hinterlegen.</h1>
        <form class="tariff-entry-form" #tariffForm="ngForm" (ngSubmit)="saveProviderInfo()"
              (change)="onFormChange($event)">
            <div class="form">
                <input name="name" type="text" class="iona-input" placeholder="Tarifname"
                       [(ngModel)]="tariffInfo.name">
            </div>
            <div class="formItem">
                <input #dateinput name="dateStart" type="text" placeholder="Tarifbeginn" class="iona-input formText"
                       [min]="'2018-01-01'"
                       [max]="moment().format('MM-DD-YYYY')"
                       (focus)="dateinput.type = 'date'"
                       [(ngModel)]="tariffInfo.dateStart">
            </div>
            <div class="formItem">
                <input name="basePrice" type="number" class="iona-input" placeholder="Grundpreis in €"
                       [(ngModel)]="tariffInfo.basePrice">
            </div>
            <div class="formItem">
                <input name="workPrice" type="number" class="iona-input" placeholder="Arbeitspreis in ct/kWh"
                       [(ngModel)]="tariffInfo.workPrice">
            </div>

            <div>
                <div>
                    <button type="submit" class="iona-button dark" [disabled]="saveTariffDisabled">Speichern</button>
                    <div class="skip-container">
                        <a href="javascript:;" type="button" (click)="skipTariffs()"
                           title="Überspringen">Weiter</a>
                    </div>
                </div>
            </div>
        </form>
    </section>
</main>
