import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {OptInService} from '../../services/opt-in.service';
import {ImprintService} from '../../services/imprint.service';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    viewProviders: []
})

export class ImprintComponent implements OnInit {

    @ViewChild('imprintContainer', {static: true})
    private imprintContainer: ElementRef<HTMLElement>;

    constructor(private title: Title,
                private user: UserService,
                private optInService: OptInService,
                private imprintService: ImprintService) {
    }

    ngOnInit() {
        this.title.setTitle('Impressum | iONA');
        this.optInService.checkStatus();

        this.loadImprintFile();
    }

    private loadImprintFile(): void {
        this.imprintService.getImprint().subscribe(
            (fileContents) => {
                this.imprintContainer.nativeElement.innerHTML = fileContents;
            }
        );
    }
}
