export enum ContactTexts {
    TITLE = 'Kontakt',
    SUBTITLE = 'Direktkontakt',
    SECTION_TITLE_PHONE = 'Telefon',
    SECTION_DESCRIPTION_PHONE = 'Rufen Sie uns auf unserer kostenlosen Hotline an.',
    SECTION_TITLE_FAX = 'Telefax',
    SECTION_DESCRIPTION_FAX = 'Senden sie uns ein Telefax unter der folgenden Nummer.',
    SECTION_TITLE_MAIL = 'E-Mail',
    SECTION_DESCRIPTION_MAIL = 'Schreiben Sie uns und teilen Sie uns Ihr Anliegen per E-Mail mit.',
    BUTTON_EMAIL_CONTACT = 'Kundenservice kontaktieren'
}
