import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ApplicationService} from '../../services/application.service';
import {UserService} from '../../services/user.service';
import {getProviderIconClass, getProviderMenuName} from '../../lib/ProviderUtil';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {Changelog} from '../../popovers/changelog/changelog.model';
import {Popover} from '../../popovers/popover/popover.service';
import {ChangelogComponent} from '../../popovers/changelog/changelog.component';
import {mergeMap} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {LogoutPopoverConfig} from '../../popovers/static.popover.config';
import {static_links} from '../../shared/constants/web-resources.constants';
import {VisibilityService} from '../../services/visibility.service';
import {MenuTexts} from '../../shared/texts/menu.texts';
import {Router} from '@angular/router';
import {of, throwError} from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: [
        './menu.component.scss',
        './menu-responsive.component.scss'
    ]
})
export class MenuComponent extends BaseComponent implements OnInit {

    readonly TEXTS = MenuTexts;

    private changelog: Changelog = null;

    @Input() activeElement = '';

    version = '0.0.0';
    private providername = 'e.on';
    providerMenuName = '';
    providerclass = '';
    privacyLink = '';

    @ViewChild('hamburger', {static: true}) hamburgerElement: ElementRef<HTMLElement>;

    constructor(public application: ApplicationService,
                private userService: UserService,
                private versionService: VersionService,
                private popover: Popover,
                private apiService: ApiService,
                private visibility: VisibilityService,
                private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.providername = this.userService.getActiveUserProvider();
        this.privacyLink = static_links.privacy[this.providername];

        if (this.application.isDemoMode()) {
            this.providerMenuName = 'Mein iONA';
            this.providerclass = 'demo';
        } else {
            this.providerMenuName = getProviderMenuName(this.providername);
            this.providerclass = getProviderIconClass(this.providername);
        }

        this.addSub(this.versionService.getChangelog().subscribe((res) => {
            this.version = res[0].version;
            this.changelog = res;
        }));
    }

    onShowMenu(): void {
        // $(document).on('click', 'a#hamburger', function(event) {
        //     $(this).toggleClass('open');
        //     $('.sidebar.lft').toggleClass('open');
        //     $('.sb-overlay').toggleClass('visible');
        //     event.preventDefault(event);
        // });
    }

    showChangelog() {
        const s = this.popover.open({
            content: ChangelogComponent,
            data: this.changelog,
            placement: 'center center',
            hasBackdrop: true,
        }).afterClosed$.subscribe(() => {
            s.unsubscribe();
        });
    }

    onLogoutClick(): void {
        const sub = this.popover.open(LogoutPopoverConfig).afterClosed$.pipe(
            mergeMap(res => {
                try {
                    return of(res.data);
                } catch (error) {
                    return throwError(error);
                }
            })
        ).subscribe(
            (res) => {
                if (res) {
                    this.apiService.logoutUser();
                    this.visibility.kill();
                }
            },
            (err) => console.log('error ', err),
            () => sub.unsubscribe()
        );
    }

    onPrivacyClick(): void {
        if (this.application.isDemoMode()) {
            this.router.navigate(['datenschutz']);
            return;
        }
        window.open(this.privacyLink, '_blank');
    }
}
