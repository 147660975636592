import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {AppliancesDiagramCallout, translateAppliance} from '../../tiles/appliances/appliances.utils';
import {SeriesPieOptions} from 'highcharts';

@Component({
    selector: 'app-appliance-chart',
    templateUrl: './appliance-chart.component.html',
    styleUrls: ['./appliance-chart.component.scss']
})
export class ApplianceChartComponent implements OnInit {

    private defaultCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', value: '', applianceProfileComplete: true
    };
    private readonly applianceImagePath = 'assets/img/graphics/appliances/';
    private readonly applianceImageType = 'png';
    private animateChartUpdate = true;

    @Input() isDetail = false;
    @Output() chartLoaded = new EventEmitter();

    chart: Chart = null;

    currentCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', value: '', applianceProfileComplete: true
    };

    calloutHidden = false;

    constructor() {
    }

    ngOnInit() {
        this.initializeChart();
    }

    /**
     * Adds a new series to the chart and triggers a redraw
     * @param series
     */
    addSeries(series: SeriesPieOptions): void {
        if (!this.chart) {
            return;
        }
        this.clearChart();
        this.chart.addSeries(series, true, this.animateChartUpdate);
        this.showLoadingState(false);
        this.animateChartUpdate = false;
    }


    showLoadingState(show: boolean = true): void {
        if (!this.chart) {
            return;
        }
        if (!this.chart.ref) {
            return;
        }
        this.chart.ref.hideLoading();
        if (show) {
            this.chart.ref.showLoading('Auswertung wird geladen…');
            this.calloutHidden = true;
        } else {
            this.chart.ref.hideLoading();
            this.calloutHidden = false;
        }
    }

    showErrorState(show: boolean = true): void {
        if (!this.chart) {
            return;
        }
        if (!this.chart.ref) {
            return;
        }
        this.chart.ref.hideLoading();
        if (show) {
            this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar…');
        } else {
            this.chart.ref.hideLoading();
        }
    }


    clearChart(): void {
        if (!this.chart) {
            return;
        }
        if (!this.chart.ref) {
            return;
        }
        const seriesCount = this.chart.ref.series.length;
        for (let i = 0; i < seriesCount; ++i) {
            this.chart.removeSeries(i);
        }
        this.showLoadingState();
        this.calloutHidden = true;
    }


    private onChartInit(): void {
        this.showLoadingState();
        this.chartLoaded.emit();
    }


    private determineApplianceImagePath(appliance: string): string {
        return `${this.applianceImagePath}${appliance}.${this.applianceImageType}`;
    }


    private determineCalloutData(chartRef): AppliancesDiagramCallout {
        const pointName = chartRef.point.name.toLowerCase();
        const value = Math.floor(chartRef.y);
        const price = chartRef.point.options.x;

        const name = translateAppliance(chartRef.point.name);
        // const percentage = Math.floor(chartRef.point.percentage).toString();

        const pointIdx = chartRef.point.index;
        const profileCompleteMapping = chartRef.series.options.custom;
        const callout: AppliancesDiagramCallout = {
            image: `url(${this.determineApplianceImagePath(pointName)})`,
            color: chartRef.point.color,
            label: this.isDetail ? `${chartRef.y} kWh` : `${name}`,
            value: this.isDetail ? `${price} €` : `${value} kWh`,
            applianceProfileComplete: profileCompleteMapping ? profileCompleteMapping[pointIdx] : true
        };
        return callout;
    }


    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [self.isDetail ? 25 : 10, 10, 25, 10],
                events: {
                    redraw() {
                        this.reflow();
                    },
                    render(event) {
                        if ('series' in event.target) {
                            if (event.target['series']['length'] > 0) {
                                const points = event.target['series'][0]['points'] as any[];
                                const sorted = points.sort((a, b) => {
                                    return b.percentage - a.percentage;
                                });
                                if (!sorted[0]) {
                                    return;
                                }
                                const name = translateAppliance(sorted[0].name);
                                const value = Math.floor(sorted[0].y).toString();
                                const image = `${sorted[0].name.toLowerCase()}`;
                                const imagePath = self.determineApplianceImagePath(image);
                                const price = sorted[0].y;
                                self.defaultCallout.color = sorted[0].color;
                                self.defaultCallout.image = `url(${imagePath})`;
                                self.defaultCallout.label = self.isDetail ? `${value} kWh` : `${name}`;
                                self.defaultCallout.value = self.isDetail ? `${price} €` : `${value} kWh`;
                                self.currentCallout = self.defaultCallout;


                                ///
                                // if (!('series' in event.target)) {
                                //     return;
                                // }
                                // if (event.target['series']['length'] <= 0) {
                                //     return;
                                // }
                                // const points = event.target['series'][0]['points'] as any[];
                                // const sorted = points.sort((a, b) =>
                                //     b.percentage - a.percentage);
                                // if (!sorted[0]) {
                                //     return;
                                // }
                                // const name = translateAppliance(sorted[0].name);
                                // const percentage = Math.floor(sorted[0].percentage).toString();
                                // const value = Math.floor(sorted[0].y);
                                // self.defaultCallout.color = sorted[0].color;
                                // self.defaultCallout.image = `url(/assets/img/graphics/appliances/${sorted[0].name.toLowerCase()}.svg)`;
                                // self.defaultCallout.label = `${value} kWh`;
                                // self.defaultCallout.value = `${sorted[0].x} €`;
                                // self.currentCallout = self.defaultCallout;
                            }
                        }
                    },
                    load(ref) {
                        self.onChartInit();
                    }
                }
            },
            title: {text: null},
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner(boxWidth: number, boxHeight: number) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter() {
                    self.currentCallout = self.determineCalloutData(this);
                    self.calloutHidden = false;
                    return '';
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter() {
                            const value = Math.ceil(this.percentage);
                            const style = `style="color:${this.color}"`;
                            return `<div class="label" ${style}> ${value} %</div>`;
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 0,
                        connectorColor: 'white',
                        softConnector: false,
                        style: {
                            fontSize: '20px',
                            fontFamily: 'Innogy light, sans-serif',
                            color: '#39393a'
                        }
                    },
                    innerSize: '100%',
                    borderWidth: 10,
                    borderColor: null,
                    slicedOffset: 0,
                    startAngle: -180,
                    states: {hover: {brightness: 0}},
                    point: {
                        events: {
                            mouseOut() {
                                self.currentCallout = self.defaultCallout;
                            },
                        }
                    }
                }
            },
            series: [],
            credits: {enabled: false}
        });

    }

}
