<aside class="sidebar lft">
    <a #hamburger href="javascript:;" id="hamburger" title="Menü" class="mobi"
       (click)="onShowMenu()">
        <span class="burger">
            <i class="top"></i>
            <i class="middle"></i>
            <i class="bottom"></i>
        </span>
        <span class="text">Menü</span>
    </a>
    <div class="menu-container">
        <div class="menu-header">
            <div [ngClass]="{'title-image': !application.isDemoMode(), 'title-image-demo' : application.isDemoMode()}">
            </div>
        </div>
        <div class="menu-nav">
            <ul class="menu-entries">
                <li>
                    <a class="menu-item" routerLink="/" title="Übersicht"
                       [ngClass]="{active : activeElement == 'dashboard'}">
                        {{TEXTS.DASHBOARD}}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/meine-daten" title="Meine Daten"
                       [ngClass]="{active : activeElement == 'profile'}">
                        {{TEXTS.PROFILE}}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/mein-haushalt" title="Mein Haushalt"
                       [ngClass]="{active: activeElement === 'household'}">
                        {{TEXTS.HOUSEHOLD}}
                    </a>
                </li>
<!--                <li>-->
<!--                    <a class="menu-item" routerLink="/mein-kundenkonto" title="{{ providerMenuName }}"-->
<!--                       [ngClass]="{active: activeElement === 'account'}"-->
<!--                       angulartics2On="click" angularticsCategory="Meine Daten"-->
<!--                       angularticsAction="my_account_enter">-->
<!--                        {{providerMenuName}}-->
<!--                    </a>-->
<!--                </li>-->
                <li>
                    <a class="menu-item" routerLink="/einstellungen" title="Einstellungen"
                       [ngClass]="{active: activeElement === 'settings'}">
                        {{TEXTS.SETTINGS}}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/kontakt" title="Kontakt"
                       [ngClass]="{active: activeElement === 'contact'}">
                        {{TEXTS.CONTACT}}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/hilfe" title="Hilfe"
                       [ngClass]="{active: activeElement === 'help'}">
                        {{TEXTS.HELP}}
                    </a>
                </li>
                <li>
                    <a class="menu-item" href="javascript:;"
                       title="{{ application.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
                       (click)="onLogoutClick()">
                        {{ application.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="pre-footer">
            <div>
                <a href="javascript:;" class="menu-link version"
                   (click)="showChangelog()">
                    {{version}}
                </a>
            </div>
            <div class="provider-menu-log-container">
                <div class="provider-menu-logo" [ngClass]="providerclass"></div>
            </div>
        </div>
        <div class="menu-footer">
            <a href="javascript:;" class="menu-link" routerLink="/impressum" title="Impressum"
               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_imprint">
                {{TEXTS.IMPRINT}}
            </a>
            <a (click)="onPrivacyClick()" class="menu-link" target="_blank" title="Datenschutz"
               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy">
                {{TEXTS.PRIVACY}}
            </a>
        </div>
    </div>
</aside>
