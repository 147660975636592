export enum MenuTexts {
    DASHBOARD = 'Übersicht',
    PROFILE = 'Meine Daten',
    HOUSEHOLD = 'Mein Haushalt',
    SETTINGS = 'Einstellungen',
    CONTACT = 'Kontakt',
    HELP = 'Hilfe',
    IMPRINT = 'Impressum',
    PRIVACY = 'Datenschutz'
}
