import {
    BinaryConfirmPopoverComponent
} from './binary-confirm-popover/binary-confirm-popover.component';
import {ValueEntryPopoverComponent} from './value-entry-component/value-entry-popover.component';
import {UpdatePopoverComponent} from './update-popover/update-popover.component';
import {MfaCodePopoverComponent} from './mfa/mfa-code-popover/mfa-code-popover.component';
import {
    MfaCodeEntryPopoverComponent
} from './mfa/mfa-code-entry-popover/mfa-code-entry-popover.component';
import {MfaLoginPopoverComponent} from './mfa/mfa-login-popover/mfa-login-popover.component';

export const RemoveTilePopoverConfig = {
    content: BinaryConfirmPopoverComponent,
    data: {
        title: 'Bereich entfernen',
        text: 'Wollen Sie diesen Bereich wirklich entfernen?',
        positive: 'Ja',
        negative: 'Nein'
    },
    hasBackdrop: true
};


export const LogoutPopoverConfig = {
    content: BinaryConfirmPopoverComponent,
    data: {
        title: 'Abmelden',
        text: 'Wollen Sie sich wirklich abmelden?',
        positive: 'Ja',
        negative: 'Nein'
    },
    hasBackdrop: true
};


export const OnboardingNoContactPopoverConfig = {
    content: BinaryConfirmPopoverComponent,
    data: {
        title: 'Keine Daten vom optischen Ausleser',
        text: 'Möglicherweise ist der optische Ausleser nicht richtig positioniert. Bitte ' +
            'überprüfen Sie die Positionierung.',
        positive: 'Habe ich gemacht',
    },
    hasBackdrop: true
};


export const OnboardingWrongSerialPopoverConfig = {
    content: BinaryConfirmPopoverComponent,
    data: {
        title: 'Falscher Zähler',
        text: '',
        positive: 'Habe ich gemacht'
    },
    hasBackdrop: true
};


export const PinFailedPopoverConfig = {
    content: BinaryConfirmPopoverComponent,
    hasBackdrop: true,
    data: {
        nullableBackdrop: true,
        title: 'PIN-Eingabe fehlgeschlagen',
        text: 'Leider konnte die PIN nicht erfolgreich eingegeben werden. Sie haben nun die ' +
            'Möglichkeit die Übertragung erneut zu versuchen oder die PIN manuell einzugeben.',
        positive: 'Neu Versuchen',
        negative: 'Manuelle Eingabe',
        turquoise: true,
        infoLink: 'https://www.iona-energy.com/pin'
    }
};


export const ManualPinEntryPopoverConfig = {
    content: BinaryConfirmPopoverComponent,
    hasBackdrop: true,
    data: {
        nullableBackdrop: true,
        title: 'Manuelle PIN-Eingabe',
        text: 'Je nach Zählertyp können Sie die PIN über einen Taster oder per Leuchtsignal am Lichtsensor eingeben. Hierzu navigieren Sie durch das auf dem Display des Zählers angezeigte Menü bis zur PIN-Eingabe. Darüber hinaus muss im Menü die INFO-Schnittstelle aktiviert werden. Nähere Informationen entnehmen Sie der Dokumentation zu Ihrem Zähler. Bitte installieren Sie den optischen Ausleser im Anschluss wieder korrekt auf Ihrem Zähler und drücken den Knopf auf der Sendeeinheit.',
        positive: 'Erledigt',
        turquoise: true,
        infoLink: 'https://www.iona-energy.com/pin'
    }
};


export const PinEntryPopoverConfig = {
    content: ValueEntryPopoverComponent,
    hasBackdrop: true,
    data: {
        nullableBackdrop: true,
        type: 'number',
        title: 'Manuelle PIN-Eingabe',
        text: 'Bitte geben Sie erneut Ihre Zähler-PIN ein. Die Freischaltung kann bis zu eine ' +
            'Stunde in Anspruch nehmen.',
        positive: 'Erledigt',
        turquoise: true,
        hasSkip: true,
        maxlength: 4
    }
};


export const ManualPinEntryInfoPopoverConfig = {
    content: BinaryConfirmPopoverComponent,
    hasBackdrop: true,
    data: {
        nullableBackdrop: true,
        title: 'Manuelle PIN-Eingabe',
        text: 'Bei dem bei Ihnen verbauten Zählertyp müssen Sie die PIN manuell über einen Taster oder per Leuchtsignal am Lichtsensor eingeben.  Hierzu navigieren Sie durch das auf dem Display des Zählers angezeigte Menü bis zur PIN-Eingabe. Darüber hinaus muss im Menü die INFO-Schnittstelle aktiviert werden. Nähere Informationen entnehmen Sie der Dokumentation zu Ihrem Zähler. Bitte installieren Sie den optischen Ausleser im Anschluss wieder korrekt auf Ihrem Zähler und drücken den Knopf auf der Sendeeinheit.',
        positive: 'Erledigt',
        turquoise: true
    }
};


export const FirmwareUpdatePopover = {
    content: UpdatePopoverComponent,
    hasBackdrop: true,
    data: {}
};


export const FirmwareUpdateAvailablePopover = {
    content: BinaryConfirmPopoverComponent,
    hasBackdrop: true,
    data: {
        nullableBackdrop: true,
        title: 'Software-Update verfügbar',
        text: 'Für Ihren Optischen Ausleser ist ein neues Update verfügbar. Dieses kann bis zu 60 ' +
            'Minuten in Anspruch nehmen. Bitte lassen Sie in dieser Zeit sowohl den PowerChecker ' +
            'als auch den Optischen Ausleser verbunden. Möchten Sie es in Kürze oder später ' +
            '(heute Nacht) installieren?',
        positive: 'in Kürze starten',
        negative: 'Später'
    }
};


export const RadioLinkLostPopover = {
    content: BinaryConfirmPopoverComponent,
    hasBackdrop: true,
    data: {
        title: 'Funkverbindung unterbrochen',
        text: 'Die Funkverbindung zum optischen Ausleser wurde unterbrochen. Bitte prüfen Sie ' +
            'folgende Möglichkeiten: 1) PowerChecker und optischen Ausleser neustarten 2) ' +
            'Batterien des optischen Auslesers ersetzen 3) Die Entfernung zwischen Stecker und ' +
            'optischem Ausleser reduzieren',
        positive: 'Okay'
    }
};

export const MFACodePopover = {
    content: MfaCodePopoverComponent,
    hasBackdrop: true,
    data: {
        setupCode: ''
    }
};

export const MFACodeEntryPopover = {
    content: MfaCodeEntryPopoverComponent,
    hasBackdrop: true,
    data: {
        setupCode: ''
    }
};

export const DisableMFAConfirmationPopover = {
    content: BinaryConfirmPopoverComponent,
    hasBackdrop: true,
    data: {
        title: '2-Faktor-Authentifizierung deaktivieren',
        text: 'Möchten Sie die 2-Faktor-Authentifizierung wirklich deaktivieren?',
        positive: 'Deaktivieren',
        negative: 'Abbrechen'
    }
};

export const MFALoginPopover = {
    content: MfaLoginPopoverComponent,
    hasBackdrop: true,
    data: {

    }
};
