export enum StorageAttributes {
    APP_MODE = 'app-mode',
    APP_EXPIRY = 'app-expire',
    OPT_IN_REQUIRED = 'optInRequired',
    SEND_ANALYTICS_DATA = 'sendAnalyticsData',
    MANUAL_PIN_ENTRY_MODE = 'manualPinEntryMode',
    LAST_UPDATE_INFO = 'lastUpdateInfo',
    RADIO_LINK_LOST_INFO = 'radioLinkLostInfo',
    IS_METER_CONNECTED = 'isMeterConnected',
    USERS = 'users',
    USER_ACTIVE = 'user-active',
    LAST_PROFILE_UPDATE_REMINDER = 'lastProfileUpdateReminder',
    SHOW_PROFILE_UPDATE_REMINDER = 'showProfileUpdateReminder',
    IS_FIRST_DETAILS_VIEW = 'isFirstDetailsView',
    ACCOUNT_REWRITE_ENABLED = 'accountRewriteEnabled',
}
