import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {DisaggregationService} from '../../../services/disaggregation.service';
import {NilmService} from '../../../services/nilm.service';
import {getMonthName} from '../../../lib/DateUtil';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {AppliancesTileTexts} from '../../../shared/texts/tiles/appliances-tile.texts';
import {ApplianceChartComponent} from '../../../charts/appliance-chart/appliance-chart.component';
import {mergeMap, tap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {ApplianceCategories} from '../../../shared/constants/appliances.constants';
import {SeriesPieOptions} from 'highcharts';
import {ProfileUpdateService} from '../../../services/profile-update.service';


@Component({
    selector: 'app-appliances-tile',
    templateUrl: './appliances-tile.component.html',
    styleUrls: ['./appliances-tile.component.scss'],
    providers: [Globals]
})

export class AppliancesTileComponent extends BaseComponent implements OnInit, OnDestroy {
    private readonly type: TILE_TYPE = TILE_TYPE.APPLIANCES;
    private nilmStatusSub: Subscription = null;
    private showIndicatorSub: Subscription = null;
    private colors = ['#c81e82', '#780a5f', '#808080', '#cccccc'];

    @ViewChild('chart', {static: true}) private applianceChart: ApplianceChartComponent;

    readonly TEXTS = AppliancesTileTexts;

    currentMonth: string = null;

    showDiagrams = true;
    nilmDataAvailable = false;
    showAttentionIndicatorProfile = false;
    showAttentionIndicatorNilm = false;

    constructor(private globals: Globals,
                private disaggregation: DisaggregationService,
                private nilm: NilmService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private profileUpdate: ProfileUpdateService,
                private application: ApplicationService) {
        super();
    }

    ngOnInit(): void {
        this.currentMonth = getMonthName(0);
        this.profileUpdate.onShowProfileIndicator.subscribe(result => {
            this.showAttentionIndicatorProfile = result;
        });
        this.profileUpdate.checkProfileUpdateIndicatorDisplayDue();
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.nilmStatusSub) {
            this.nilmStatusSub.unsubscribe();
            this.nilmStatusSub = null;
        }
    }


    /**
     * Handle on tile click
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * On Detail opened
     */
    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    /**
     * On Chart initialized and loaded callback
     */
    onChartLoaded(): void {
        this.initializeTile();
    }


    /**
     * Initializer for the tile
     */
    private initializeTile(): void {
        this.nilmStatusSub = this.nilm.onNewNilmStatusUpdate.subscribe(
            (status) => {
                if (status === null) {
                    return;
                }
                this.nilmDataAvailable = status;
                this.requestElectricalAppliances();
            });
    }


    /**
     * Requests disaggregation data for appliances to be displayed on the tile
     */
    private requestElectricalAppliances(): void {
        const s = this.disaggregation.getDisaggregationDataForAppliancesTile().pipe(
            mergeMap((categories: ApplianceCategories) =>
                this.alignCategoryData(categories)),
            tap((categories: AlignedApplianceCategoryData[]) => {
                const mappedCategories = categories.map(el => el.name);
                if (!this.showAttentionIndicatorNilm) {
                    this.showAttentionIndicatorNilm =
                        !this.nilm.isProfileComplete(mappedCategories);
                }
            }),
            mergeMap((alignedCategories: AlignedApplianceCategoryData[]) =>
                this.generateDiagramSeries(alignedCategories))
        ).subscribe({
            next: (seriesData) => {
                // console.log('tile series data', seriesData);
                // need to cast to any since pie charts don't support parameter x
                const series = seriesData.series as any;
                const finalSeries = {
                    name: 'Series',
                    data: series,
                    type: 'pie',
                    custom: seriesData.nilm
                } as SeriesPieOptions;
                this.applianceChart.addSeries(finalSeries);
            },
            error: () => {
                this.applianceChart.showLoadingState();
                this.showDiagrams = false;
            },
            complete: () => {
                s.unsubscribe();
            }
        });
    }


    /**
     * Align the raw categories - ultimately this function remaps the response to an array-like
     * @param categories
     */
    private alignCategoryData(categories: ApplianceCategories)
        : Observable<AlignedApplianceCategoryData[]> {
        return new Observable((observer) => {
            try {
                const tempData: AlignedApplianceCategoryData[] = [];
                for (const appliance of Object.keys(categories)) {
                    if (categories[appliance].usage > 0) {
                        tempData.push({
                            name: appliance,
                            usage: categories[appliance].usage
                        });
                    }
                }
                tempData.sort((a, b) => b.usage - a.usage);
                observer.next(tempData);
            } catch (error) {
                observer.error(error);
            }
        });
    }


    /**
     * Generates a diagram series by extracting the categories with the highest values
     * Other categories are summed up and displayed as 'Others'
     * @param alignedCategories
     */
    private generateDiagramSeries(alignedCategories: AlignedApplianceCategoryData[])
        : Observable<{ series: ApplianceDiagramSeriesData[], nilm: boolean[] }> {
        return new Observable((observer) => {
            try {
                const series: ApplianceDiagramSeriesData[] = [];
                const customNilmValues: boolean[] = [];

                // process categories with the highest values
                for (const appliance of alignedCategories.slice(0, 3)) {
                    let categoryComplete = true;
                    if (this.nilmDataAvailable) {
                        categoryComplete = this.nilm.nilmCategoryIsComplete(
                            appliance.name.toLowerCase()
                        );
                        customNilmValues.push(categoryComplete);
                    }
                    series.push({
                        name: appliance.name,
                        y: appliance.usage,
                        color: null,
                        sliced: true
                    });
                }

                // process others category
                let other = 0;
                const otherCustomNilmValues: boolean[] = [];
                for (const appliance of alignedCategories.slice(3)) {
                    other += appliance.usage;
                    let categoryComplete = true;
                    if (this.nilmDataAvailable) {
                        categoryComplete = this.nilm.nilmCategoryIsComplete(
                            appliance.name.toLowerCase()
                        );
                        otherCustomNilmValues.push(categoryComplete);
                    }
                }
                if (other > 0) {
                    series.push({
                        name: 'Other',
                        y: other,
                        color: null,
                        sliced: true
                    });
                    const nilmStateOthers = otherCustomNilmValues.every(el => el === true);
                    customNilmValues.push(nilmStateOthers);
                }

                // assign colors
                let colorCounter = 0;
                for (const element of series) {
                    element.color = this.colors[colorCounter];
                    ++colorCounter;
                }

                observer.next({series, nilm: customNilmValues});
            } catch (error) {
                observer.error(error);
            }
        });
    }


    /*
     * TRACKING
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Appliances'
            }
        });
    }


    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Consumer-Details'
            }
        });
    }
}

export interface ApplianceCategories {
    AlwaysOn: ApplianceCategoryValue;
    Cooking: ApplianceCategoryValue;
    ElectricVehicle: ApplianceCategoryValue;
    Entertainment: ApplianceCategoryValue;
    Laundry: ApplianceCategoryValue;
    Lighting: ApplianceCategoryValue;
    Other: ApplianceCategoryValue;
    PoolOrSauna: ApplianceCategoryValue;
    Refrigeration: ApplianceCategoryValue;
    SpaceHeating: ApplianceCategoryValue;
    WaterHeating: ApplianceCategoryValue;
}

export interface ApplianceCategoryValue {
    usage: number;
    cost: number;
}

export interface AlignedApplianceCategoryData {
    name: string;
    usage: number;
}

export interface ApplianceDiagramSeriesData {
    name: string;
    y: number;
    x?: string;
    color: string;
    sliced: boolean;
}
