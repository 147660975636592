<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>Möchten Sie Ihr <br> Passwort zurücksetzten?</h1>
        <p>
            Geben Sie hier Ihr neues Passwort ein.
        </p>
        <form [formGroup]="form" (ngSubmit)="submitChange()">
            <input type="password" class="iona-input m-b-x"
                   formControlName="oldPassword" placeholder="Altes Passwort">
            <input type="password" class="iona-input m-b-m"
                   formControlName="newPassword1" placeholder="Neues Passwort">
            <p class="password-info">
                Das Passwort muss aus 8 Stellen bestehen und mindestens einen Großbuchstaben sowie eine Zahl
                enthalten.
            </p>
            <div class="password-strength">
                        <span *ngFor="let i of [0,1,2,3,4]"
                              [class.active]="i <= currentPasswordScore"></span>
                <div class="password-score-label">
                    Passwort: <span>{{determinePasswordScoreRatingText()}}</span>
                </div>

            </div>
            <input type="password" class="iona-input m-b-m"
                   formControlName="newPassword2" placeholder="Neues Passwort (wiederholen)">
            <button class="iona-button orange" type="submit" [disabled]="submitDisabled">Ändern</button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close(false)"></button>
    </div>
</div>
