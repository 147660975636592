import * as moment from 'moment';

/**
 * Determine whether a certain overlay should be displayed
 *    based on a timestamp stored upon last displayal
 * @param item
 * @param timeframe
 * @param time
 */
export const shouldTriggerTimeBasedOverlay =
    (item: string, timeframe: any, time: number): boolean => {
        const lastTriggered = localStorage.getItem(item);
        if (!lastTriggered) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(time, timeframe).toDate()) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }
        return false;
    };
