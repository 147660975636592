import {Injectable, OnDestroy} from '@angular/core';

import {BehaviorSubject, Observable, Subject, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';

import {ToastrService} from 'ngx-toastr';
import {Md5} from 'ts-md5/dist/md5';
import {environment} from '../../environments/environment';
import {constants} from '../shared/constants/constants';
import {User} from '../classes/user';
import {UserService} from './user.service';
import {Url} from '../lib/Url';

import * as moment from 'moment';
import {ApplicationService} from './application.service';
import {TrackAnalyticsService} from './track-analytics.service';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';
import {AccountRewriteService} from './account-rewrite.service';
import {ProfileUpdateService} from './profile-update.service';

@Injectable()
export class ApiService implements OnDestroy {

    /**
     * API-Base URL's. Sollte sich die Schnittstellen URL ändern, ist hier die zentrale Stelle dafür
     */
    private AUTH_BASE_URL = environment.urls.auth;
    private API_BASE_URL = environment.urls.api;

    private checkMeterStatusInterval: any = [];

    private countLoginError = 0;

    public loggedOut: BehaviorSubject<string> = new BehaviorSubject(null);
    public onLoggedIn: Subject<boolean> = new Subject();

    /**
     * MINE
     */
    // private app_state = constants.application.states.none;

    constructor(private _httpClient: HttpClient,
                private _router: Router,
                private _notification: ToastrService,
                private analytics: TrackAnalyticsService,
                private _userService: UserService,
                private application: ApplicationService,
                private accountRewrite: AccountRewriteService,
                private profileUpdate: ProfileUpdateService
    ) {
    }

    ngOnDestroy(): void {
        clearInterval(this.checkMeterStatusInterval);
    }

    /**
     * Get Meter-Informations
     */
    public getInitialization(): any {
        let headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.initialization, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error_response: any) => {
                if ('error' in error_response) {
                    if ('error' in error_response.error) {
                        if ('code' in error_response.error.error) {
                            if (error_response.error.error.code === 125) {
                                this._router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                            }
                        }
                    }
                }

                if ('_body' in error_response) {
                    let jsonError;

                    try {
                        jsonError = JSON.parse(error_response._body);
                    } catch (e) {
                        return observableThrowError(error_response);
                    }

                    if ('error' in jsonError) {
                        if ('code' in jsonError.error && jsonError.error.code === 125) {
                            // user not yet onboarded -> Zum Onboarding springen
                            this._router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                        }
                    }
                }

                return observableThrowError(error_response);
            }));
    }

    public setPlugStatus(value): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());

        const payload = {
            on_off: value
        };

        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.plug.relay, payload, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );

    }

    public getStoredUserSettings(): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.configuration.dashboard, {headers})
            .pipe(
                map((res: any) => {
                    if ('data' in res) {
                        if (typeof res.data === 'string') {
                            return JSON.parse(res.data);
                        } else {
                            return res.data;
                        }
                    } else {
                        return null;
                    }
                }),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );

    }

    public setPlugAttributes(): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());
        const payload = {
            config: this._userService.getLocalKeyValueStore()
        };
        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.configuration.dashboard, payload, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );

    }


    /**
     * Validate Voucher-Code
     *
     * @param voucher Voucher-Code
     * @param email Email-Address
     */
    public validateVoucher(voucher: string, email: string): any {
        let headers = this.getDefaultHeaders();

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.registration.voucher + '/' + voucher + '/' + email, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Register new User
     *
     * @param user User-ID
     */
    public registerUser(user: any): any {
        let headers = this.getDefaultHeaders();

        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.registration.noGateway, user, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Register new Device
     *
     * @param mac MAC-Address
     */
    public registerDevice(mac: string): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.onboard + '/' + mac, {}, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Gateway Registration
     */
    public optInDevice(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        const body = {opt_in: 1};
        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.optIn, body, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Gateway Registration
     */
    public getDeviceStatus(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.meter.status, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    /**
     * Login User
     *
     * @param username Username
     * @param password Password
     */
    public loginUser(username: string, password: string, first_call = false) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        let params = new HttpParams();
        params = params.append('method', 'login');
        params = params.append('username', username);
        params = params.append('password', password);

        const body = {method: 'login', username, password};

        this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers: headers}).pipe(
            // this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers: headers}).pipe(
            // this._httpClient.post(this.AUTH_BASE_URL, body, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error) => {
                return observableThrowError(error);
            }))
            .subscribe(
                (response: any) => {
                    if (response === null || response === undefined) {
                        return;
                    }
                    if ('access_token' in response) {
                        let expires: any = new Date();

                        expires.setSeconds(response.expires_in);
                        const u: User = {
                            email: username,
                            provider: null,
                            access_token: response.access_token,
                            refresh_token: response.refresh_token,
                            tokenExpires: expires,
                            tiles: null,
                            nilmStatus: null,
                            storage: null,
                            device: null
                        };

                        // console.log('token expires: ', response.expires_in, expires);

                        this._userService.setCurrentUser(u);

                        if (!first_call) {
                            this.getInitialization().subscribe(
                                (data: any) => {
                                    // console.log('init data', data);
                                    if ('data' in data) {
                                        if ('profile' in data.data) {
                                            const providername = data.data.profile.labelpartner;
                                            this._userService.setActiveUserProvider(providername);
                                            if (this._router.routerState.snapshot.url === '/login') {
                                                this.trackUser(this._userService.getActiveUserName(), data.data.profile);
                                                this._router.navigate(['/']);
                                            }
                                        }
                                    }
                                },
                                (error) => {
                                    console.log('Error:', error);
                                }
                            );
                        } else {
                            this.onLoggedIn.next(true);
                        }

                    } else {
                        this._notification.error('Es ist ein Problem aufgetreten!');
                    }
                },
                (response: HttpErrorResponse) => {
                    const error: any = response.error;
                    this.countLoginError++;

                    if (this.countLoginError >= 3) {
                        this._notification.error('Möchten Sie Ihr Passwort zurücksetzen? Sie erhalten zunächst eine Email mit einer Anleitung zum weiteren Vorgehen. <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                            disableTimeOut: true,
                            enableHtml: true
                        });
                    }

                    if (response.status === 125) {
                        console.log('User appears to not be onboarded yet');
                    }

                    switch (error.error) {
                        case 'invalid_request': {
                            this._notification.error('E-Mail-Adresse oder Passwort fehlt!');
                            break;
                        }
                        case 'invalid_grant': {
                            this._notification.error('E-Mail-Adresse oder Passwort fehlerhaft!');
                            break;
                        }
                        case 'Maximum number of failed attempts reached. The account is now blocked.': {
                            this._notification.error(
                                'Ihr Account wurde nach der 10-maligen Eingabe eines falschen Passworts gesperrt. Möchten Sie Ihr Passwort zurücksetzen? <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                                    disableTimeOut: true,
                                    enableHtml: true
                                });
                            break;
                        }
                        default: {
                            this._notification.error('Es ist ein Problem aufgetreten!');
                        }
                    }
                }
            );
    }

    /**
     * Logout current User
     */
    public logoutUser() {
        this._userService.logoutActiveUser();

        let logout_system = 'live';
        if (this.application.isDemoMode()) {
            this.analytics.trackEvent({
                action: 'login_demo_mode_end',
                properties: {
                    category: 'Login'
                }
            });
            logout_system = 'demo';
        }

        // remove set local storage items
        localStorage.removeItem(StorageAttributes.APP_EXPIRY);
        localStorage.removeItem(StorageAttributes.APP_MODE);
        localStorage.removeItem(StorageAttributes.LAST_PROFILE_UPDATE_REMINDER);

        this.profileUpdate.removeAllFlags();

        this._router.navigate(['/login']);
        this.application.setApplicationState(constants.application.states.none);

        this.loggedOut.next(logout_system);

        // clear all notifications
        this._notification.clear();
    }


    /**
     * Standard-Header für API-Calls
     *
     * @param accessToken
     */
    private getDefaultHeaders(accessToken: string = '') {
        const headers = new HttpHeaders({
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        if (typeof accessToken != 'undefined' && accessToken !== null && accessToken.length > 0) {
            const header_token = new HttpHeaders({'Authorization': 'Bearer ' + accessToken});
            return header_token;
        }

        return headers;
    }

    /** * Get current Token
     */
    public getToken(): string {
        // console.log('getToken() - token from service', this._userService.getActiveUserAccessToken());
        return this._userService.getActiveUserAccessToken();
    }


    /**
     * Send User-Data to Google Analytics
     *
     * @param user Email-Address
     * @param profile Profile-Informations
     */
    private trackUser(user: string, profile: any) {
        this.analytics.trackEvent({
            action: 'user_id',
            properties: {
                category: 'User Properties',
                label: new Md5().appendStr(user).end()
            }
        });

        this.analytics.trackEvent({
            action: 'device_id',
            properties: {
                category: 'User Properties',
                label: profile.labelpartner_id
            }
        });

        this.analytics.trackEvent({
            action: 'labelpartner',
            properties: {
                category: 'User Properties',
                label: profile.labelpartner
            }
        });

        this.analytics.trackEvent({
            action: 'gateway_id',
            properties: {
                category: 'User Properties',
                label: profile.smartbridge_mac
            }
        });
    }

}
