import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[matchParentHeight]'
})
export class MatchParentHeightDirective {

    @Input() matchParentHeight: ElementRef;

    constructor(private el: ElementRef) {
    }

}
