import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-battery-load',
    templateUrl: './battery-load.component.html',
    styleUrls: ['./battery-load.component.scss']
})
export class BatteryLoadComponent implements OnInit, OnChanges {

    @Input() currentBatteryState = -1;
    @Input() coloredWarning = false;
    @Input() smallBars = false;
    @Input() boldText = false;
    @Input() isAvailable = false;

    batteryStates = [1, 2, 3, 4, 5];

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }
}
