<div class="fw interaction-space">
    <button class="iona-icon-only-button minus"
            [disabled]="value === 0 || value === undefined"
            (click)="lowerValue()">
    </button>

    <div class="value">
        <div>{{value}}</div>
    </div>

    <button class="iona-icon-only-button plus"
            (click)="raiseValue()">
    </button>
</div>

