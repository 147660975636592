import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-opt-in-popover',
    templateUrl: './opt-in-popover.component.html',
    styleUrls: ['./opt-in-popover.component.scss']
})
export class OptInPopoverComponent implements OnInit {

    showFeatures = false;

    imagePath = 'assets/img/graphics/feature-icons/';
    imageExtension = '.webp';
    features = [
        {
            title: 'Live',
            text: 'Anzeige Ihres Stromverbrauchs in Echtzeit.',
            image: 'live'
        },
        {
            title: 'Vergleich',
            text: 'Verbrauchsstatistik und Analysen',
            image: 'comparison'
        },
        {
            title: 'Abschlags-Check',
            text: 'Gutschrift- oder Nachzahlungsprognose Schutz vor Nachzahlungen am Jahresende',
            image: 'finance'
        },
        {
            title: 'Geräte',
            text: 'Anzeige des Verbrauchs einzelner Geräte in Ihrem Haushalt und der dabei entstandenen Kosten',
            image: 'appliances'
        },
        {
            title: 'Verbrauchsalarm',
            text: 'Alarmfunktion bei hohem Stromverbrauch',
            image: 'consumption-alert'
        },
        {
            title: 'Zählerstand',
            text: 'Anzeige Ihres Zählerstands.',
            image: 'meter'
        },
        {
            title: 'Heute',
            text: 'Gutschrift- oder Nachzahlungsprognose Schutz vor Nachzahlungen am Jahresende',
            image: 'today'
        },
    ];

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    createImageUrl(element: { title: string, text: string, image: string }): string {
        return `url(${this.imagePath}${element.image}${this.imageExtension})`;
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    private initializePopoverData(): void {
        console.log('initializing popover data');
        this.showFeatures = this.popoverRef.data.showFeatures === true;
    }

}
