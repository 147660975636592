import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-value-entry-popover',
    templateUrl: './value-entry-popover.component.html',
    styleUrls: ['./value-entry-popover.component.scss']
})
export class ValueEntryPopoverComponent implements OnInit {
    title = '';
    text = '';
    type = 'text';
    positive = '';
    negative = '';
    turquoise = false;
    hasSkip = false;

    maxlength = null;
    hasValidation = false;
    submitDisabled = false;

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    close(value: boolean | any = false): void {
        this.popoverRef.close(value);
    }

    valueChange(event: KeyboardEvent): void {
        if (!this.hasValidation) {
            return;
        }
        const target = event.target as HTMLInputElement;
        const currentValue = target.value;
        this.submitDisabled = currentValue.length < 1;
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(null);
        });
        this.title = this.popoverRef.data.title;
        this.text = this.popoverRef.data.text;
        this.positive = this.popoverRef.data.positive;
        this.turquoise = this.popoverRef.data.turquoise;
        if (this.popoverRef.data.type) {
            this.type = this.popoverRef.data.type;
        }
        if (this.popoverRef.data.negative) {
            this.negative = this.popoverRef.data.negative;
        }
        if (this.popoverRef.data.hasSkip) {
            this.hasSkip = this.popoverRef.data.hasSkip;
        }
        if (this.popoverRef.data.maxlength) {
            this.maxlength = this.popoverRef.data.maxlength;
            this.hasValidation = true;
            this.submitDisabled = true;
        }
    }

}
