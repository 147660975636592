import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {ChangeEmailTexts} from '../../shared/texts/change-email.texts';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GTMWrapperService} from '../../services/gtmwrapper.service';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit, AfterViewInit {
    readonly TEXTS = ChangeEmailTexts;

    submitDisabled = true;
    firstEmail = '';
    secondEmail = '';

    form = new UntypedFormGroup({
        email1: new UntypedFormControl('', [Validators.required]),
        email2: new UntypedFormControl('', [Validators.required]),
    });

    constructor(public popoverRef: PopoverRef,
                private gtm: GTMWrapperService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit(): void {
        this.form.valueChanges.subscribe((changes) => {
            this.firstEmail = changes.email1;
            this.secondEmail = changes.email2;
            this.checkEmailsMatching();
        });
    }

    ngAfterViewInit(): void {
        this.trackViewAppearedEvent();
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    submitChange(): void {
        this.popoverRef.close({success: true, value: this.firstEmail});
    }

    private checkEmailsMatching(): void {
        if (this.firstEmail === '' || this.secondEmail === '') {
            this.submitDisabled = true;
            return;
        }

        if (this.firstEmail === this.secondEmail) {
            this.submitDisabled = false;
            return;
        }

        this.submitDisabled = true;
        return;

    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
    }

    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackViewAppearedEvent(): void {
        this.gtm.trackEvent({
            event: 'start',
            eventCategory: 'interaction',
            eventAction: 'start',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit email address'
        });
    }


}
