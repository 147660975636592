<div class="overlay-functional">
    <div class="overlay-container-cdk">
        <h1>Energiesparen</h1>
        <p>
            Der Energiesparmodus hat deutlichen Einfluss auf die Lebensdauer der Batterien des optischen Auslesers sowie
            auf die Häufigkeit der Datenübertragung. Insbesondere in ruhigen Nachtphasen kann die Laufzeit somit
            deutlich gesteigert werden.
        </p>
        <ul>
            <li>Energiesparmodus niedrig: Datenübertragung, sobald Lastveränderung > 25W. Batterielaufzeit ca. 6
                Monate.
            </li>
            <li>Energiesparmodus mittel: Datenübertragung, sobald Lastveränderung > 50W. Batterielaufzeit ca. 1 Jahr.
            </li>
            <li>Energiesparmodus hoch: Datenübertragung, sobald Lastveränderung > 150W. Batterielaufzeit > 1 Jahr.</li>
        </ul>

        <div class="slider-container">
            <span class="battery-empty-icon"></span>
            <app-eon-slider [value]="currentThreshold" [range]="'0 2'" [step]="1"
                            (onValueChange)="onSliderChange($event)">
            </app-eon-slider>
            <span class="battery-charged-icon"></span>
        </div>

        <div class="slider-labels font-medium">
            <span>niedrig</span>
            <span>mittel</span>
            <span>hoch</span>
        </div>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close(false)"></button>
    </div>
</div>
