<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>2-Faktor-Authentifizierung</h1>
        <p>
            Bitte geben Sie das Einmalkennwort aus der Authenticator-App ein, das beim Anlegen des Accounts generiert
            wurde.
        </p>
        <form [formGroup]="form" (ngSubmit)="submitCode()">
            <div>
                <input id="code-input" class="iona-input" type="text"
                       placeholder="Code eingeben"
                       (keydown)="onKeyDown($event)"
                       (keyup)="onKeyUp($event)"
                       formControlName="code">
                <label for="code-input"></label>
                <div class="loading-indicator"
                     [class.hidden]="!isLoading">
                    <ng-lottie class="loading-spinner" height="45px"
                               [options]="loadingAnimation"></ng-lottie>
                </div>
            </div>
            <button class="iona-button orange"
                    type="submit"
                    [disabled]="submitDisabled || isLoading">
                Absenden
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close()"></button>
    </div>
</div>
