<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Meine Geräte - Monatsübersicht; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <div class="tile-header-row">
                <h3 class="tile-heading">
                    {{TEXTS.TITLE}}
                </h3>
                <div *ngIf="showAttentionIndicatorProfile || showAttentionIndicatorNilm"
                     class="attention-indicator-tile">
                </div>
            </div>
            <div class="tile-content">

                <div class="appliance-chart">
                    <div class="tile-chart-abs">
                        <app-appliance-chart #chart (chartLoaded)="onChartLoaded()"></app-appliance-chart>
                    </div>
                </div>

                <div class="appliance-timeframe center-contents font-medium">
                    <span [hidden]="!showDiagrams">
                        {{TEXTS.LABEL_CONSUMPTION_IN}}
                        {{currentMonth}}
                    </span>
                </div>

            </div>
        </div>
    </div>
</div>
