<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">
            <section #headContainer class="detail-head">
                <header class="detail-header">
                    <h1 #titleContainer class="detail-heading">{{config.title}}</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="MVP"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
            </section>
            <section #detailContainer class="detail-content detail-content-override">
                <iframe #frame class="mvp-details-iframe inflate" frameborder="0" (load)="onIFrameLoaded()"></iframe>
            </section>
        </div>
    </div>
</div>
