<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: E.ON Smart Control Stecker - Details; previous_screen: Übersicht"
         (click)="onTileClicked($event)">

        <div class="tile-inner">
            <h3 class="tile-heading ">PowerChecker</h3>
            <div class="tile-content col powerchecker-tile-content">
                <div class="splitrow">
                    <div class="center-contents-v">
                        <h4 class="powerchecker-name font-light">{{plugName}}</h4>
                        <h4 class="powerchecker-room font-light">{{plugRoom}}</h4>
                    </div>
                    <div class="center-contents powerchecker-toggle">
                        <app-power-switch id="switch"
                                          [style]="{containerWidth: 100, elementSize: 50, darkTheme: false}"
                                          [state]="state"
                                          (stateChange)="onStateChange($event)">
                        </app-power-switch>
                    </div>
                </div>

                <div class="powerchecker-info-text font-regular">
                    An- und ausschalten ist noch längst nicht alles! <br>
                    Erlebe was dein PowerChecker zusammen mit <strong>iONA</strong> kann!
                </div>

            </div>
        </div>
    </div>
</div>

