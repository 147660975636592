import {Component, OnInit, ViewChild} from '@angular/core';
import {NilmService} from '../../../services/nilm.service';
import {DisaggregationService} from '../../../services/disaggregation.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ApplicationService} from '../../../services/application.service';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import {translateAppliance} from '../appliances.utils';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ConsumptionService} from '../../../services/consumption.service';
import {ApplianceCategories} from '../../../shared/constants/appliances.constants';
import * as moment from 'moment';
import {StorageAttributes} from '../../../shared/constants/storage-attributes.constants';
import {Router} from '@angular/router';
import {ApplianceChartComponent} from '../../../charts/appliance-chart/appliance-chart.component';
import {ApplianceDiagramSeriesData} from '../appliances-tile/appliances-tile.component';
import {MONTHS} from '../../../lib/DateUtil';
import {ProfileUpdateService} from '../../../services/profile-update.service';

@Component({
    selector: 'app-appliances-details',
    templateUrl: './appliances-detail.component.html',
    styleUrls: ['appliances-detail.component.scss'],
    viewProviders: []
})

export class AppliancesDetailsComponent extends BasePopover implements OnInit {
    @ViewChild('applianceChart', {static: true}) private applianceChart: ApplianceChartComponent;

    private readonly colors = ['#C81E82', '#780A5F', '#808080', '#CCCCCC'];
    private months = MONTHS;
    private showAppliances = true;

    translateAppliance = translateAppliance;
    ComponentModes = AppliancesDetailMode;
    defaultApplianceCategories = ApplianceCategories;

    mode: AppliancesDetailMode = AppliancesDetailMode.MONTH;

    date = '';
    offsetMonths = 0;

    applianceCategories: AppliancesDetailListElement[] = [];

    interactionDisabled = true;
    infoVisible = false;
    showProfileUpdateReminder = false;
    profileUpdateReminderVisible = true;

    constructor(protected popoverRef: PopoverRef,
                private analytics: TrackAnalyticsService,
                private mockDataService: MockDataService,
                private disaggregation: DisaggregationService,
                private nilm: NilmService,
                private application: ApplicationService,
                private consumptionService: ConsumptionService,
                private router: Router,
                private profileUpdate: ProfileUpdateService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.date = this.months[new Date().getMonth()] + ' ' + new Date().getFullYear();
        this.getElectricalAppliances();
        // this.determineLastProfileUpdateReminderNecessary();
        this.profileUpdate.onShowProfileIndicator.subscribe(show => {
            this.showProfileUpdateReminder = show;
        });
        this.profileUpdate.checkProfileUpdateIndicatorDisplayDue(true);
    }


    // private determineRequestToSend(): Observable<any> {
    //     this.showAppliances = true;
    //     switch (this.mode) {
    //         case AppliancesDetailMode.MONTH:
    //             return this.application.isDemoMode() ?
    //                 this.mockDataService.getElectricalAppliances(this.offsetMonths) :
    //                 this.disaggregation.getDisaggregationHistoryByOffset(this.offsetMonths);
    //         case AppliancesDetailMode.YEAR:
    //             return this.disaggregation.getDisaggregationHistoryForMonth(
    //                 this.offsetMonths, month
    //             ).pipe(
    //                 catchError(err => of(null))
    //             );
    //             return;
    //     }
    // }


    /**
     * Set data display mode
     * @param mode
     */
    setMode(mode: AppliancesDetailMode) {
        if (mode === this.mode) {
            return;
        }

        this.mode = mode;
        this.offsetMonths = 0;
        this.resetChart();
        this.triggerModeTrackingEvent();
    }


    /**
     * Skip a position forward until the logical end is reached
     */
    positionForward() {
        if (this.interactionDisabled) {
            return;
        }
        if (this.offsetMonths > 0) {
            --this.offsetMonths;
            this.resetChart();
        }
    }


    /**
     * Skip a position back
     */
    positionBack() {
        if (this.interactionDisabled) {
            return;
        }
        this.offsetMonths++;
        this.resetChart();
    }


    /**
     * Reset the chart
     */
    resetChart() {
        this.updateDate();
        this.applianceChart.clearChart();
        this.interactionDisabled = true;
        this.applianceCategories = [];

        if (this.mode === AppliancesDetailMode.YEAR) {
            this.getElectricalAppliancesYear();
        } else {
            this.getElectricalAppliances();
        }
    }


    /**
     * Hide profile update reminder
     */
    hideReminder(): void {
        this.profileUpdateReminderVisible = false;
        this.profileUpdate.removeProfileUpdateIndicatorFlag();
    }


    /**
     * Route to 'profile' component
     */
    routeToProfile(): void {
        this.close();
        this.router.navigate(['mein-haushalt']);
        this.profileUpdate.removeProfileUpdateIndicatorFlag();
    }


    /**
     * Datum zurücksetzen
     */
    updateDate() {
        switch (this.mode) {
            case AppliancesDetailMode.MONTH: {
                const date = new Date();
                date.setMonth(date.getMonth() - this.offsetMonths, 1);
                this.date = this.months[date.getMonth()] + ' ' + date.getFullYear();
                break;
            }
            case AppliancesDetailMode.YEAR: {
                const date = new Date();
                date.setFullYear(date.getFullYear() - this.offsetMonths);
                this.date = date.getFullYear().toString();
                break;
            }
        }
    }


    /**
     * Toggle an accordion element
     */
    toggleAccordion(element): void {
        element.accordionOpen = !element.accordionOpen;
    }


    /**
     * Determine the icon path for an appliance category
     * @param iconName
     */
    determineApplianceCategoryItemPath(iconName): string {
        return `url(/assets/img/icons/household/${iconName}.png)`;
    }


    /**
     * Request all appliance data
     */
    private getElectricalAppliances() {
        this.showAppliances = true;
        const s = of(this.application.isDemoMode()).pipe(
            mergeMap(demo => {
                return demo ?
                    this.mockDataService.getElectricalAppliances(this.offsetMonths) :
                    this.disaggregation.getDisaggregationHistoryByOffset(this.offsetMonths);
            }),
            mergeMap((data: any) => {
                return this.alignApplianceData([data]);
            }),
            mergeMap(aligned => {
                const date = moment().subtract(this.offsetMonths, 'months').toDate();
                return this.consumptionService.getConsumptionForMonths(date, date).pipe(
                    map(res => {
                        return {appliances: aligned, consumptions: res};
                    }),
                    catchError(err => {
                        return of({appliances: aligned, consumptions: null});
                    })
                );
            }),
            map(data => {
                return this.generateDiagramSeries(data.appliances, data.consumptions);
            }),
        ).subscribe({
            next: (finalSeries: any) => {
                console.log('detail series data', finalSeries);
                this.drawDiagram(finalSeries.series);
            },
            error: () => {
                this.applianceChart.showErrorState();
                this.showAppliances = false;
                this.interactionDisabled = false;
            },
            complete: () => {
                s.unsubscribe();
            }
        });
    }


    /**
     * Request appliance & category data for an entire year
     */
    private getElectricalAppliancesYear() {
        this.showAppliances = true;
        const months = Array.from(Array(12).keys()).map(el => el += 1);
        const s = of(months).pipe(
            mergeMap(month => month),
            mergeMap(month => {
                if (!this.application.isDemoMode()) {
                    return this.disaggregation.getDisaggregationHistoryForMonth(
                        this.offsetMonths, month
                    ).pipe(
                        catchError(err => of(null))
                    );
                }
                return this.mockDataService.getElectricalAppliancesForMonth(
                    this.offsetMonths,
                    month
                );
            }),
            toArray(),
            mergeMap(rawDisaggregationData =>
                this.alignApplianceData(rawDisaggregationData)),
            mergeMap(disaggregationData =>
                this.requestAppliancesCategoryData(disaggregationData)),
            map(data => {
                return this.generateDiagramSeries(data.appliances, data.consumptions);
            })
        ).subscribe({
            next: (finalSeries) => {
                this.drawDiagram(finalSeries.series);
            },
            error: (error) => {
                this.applianceChart.showErrorState();
                this.showAppliances = false;
                this.interactionDisabled = false;
            },
            complete: () => {
                s.unsubscribe();
            }
        });
    }


    /**
     * Align appliances disaggregation data
     * @param rawData
     */
    private alignApplianceData(rawData: any[]): Observable<any> {
        return new Observable<any>((observer) => {
            try {
                const unifiedData = {};
                for (const monthlyData of rawData) {
                    if (!monthlyData) {
                        continue;
                    }
                    const electricity = this.application.isDemoMode() ?
                        monthlyData.data.electricity :
                        monthlyData.electricity;
                    for (const appliance of Object.keys(electricity.used_budget.categories)) {
                        const usage = electricity.used_budget.categories[appliance].usage ?
                            electricity.used_budget.categories[appliance].usage :
                            0;
                        const cost = electricity.used_budget.categories[appliance].cost ?
                            electricity.used_budget.categories[appliance].cost :
                            0;

                        if (!unifiedData[appliance]) {
                            unifiedData[appliance] = {usage, cost};
                            continue;
                        }
                        unifiedData[appliance].usage += usage;
                        unifiedData[appliance].cost += cost;
                    }
                }
                const mapped = Object.keys(unifiedData).map(element => {
                    return {
                        name: element,
                        cost: unifiedData[element].cost,
                        usage: unifiedData[element].usage
                    };
                });
                mapped.sort((a, b) => b.usage - a.usage);
                observer.next(mapped);
            } catch (e) {
                console.log('error in aligning data', e);
                observer.error(e);
            }
        });
    }


    /**
     * Generates a diagram series from temporary data
     * @param applianceData
     * @param categoryData
     */
    private generateDiagramSeries(applianceData: any[], categoryData: any = null):
        { series: ApplianceDiagramSeriesData[], nilm: any[] } {
        const series: ApplianceDiagramSeriesData[] = [];
        try {
            for (const applianceCategory of applianceData) {
                const applianceCategoryElement =
                    this.generateInitialApplianceListElement(applianceCategory);

                const currentCategory = this.defaultApplianceCategories[applianceCategory.name];
                let text = 'default';
                if (currentCategory) {
                    text = this.defaultApplianceCategories[applianceCategory.name].text;
                    if (categoryData) {
                        const cat = categoryData.appliances[0].energy_per_appliance;
                        for (const appliance of currentCategory.appliances) {
                            const found = cat.find(
                                el => el.appliance_instance_id === appliance.name);
                            if (found) {
                                applianceCategoryElement.appliances.push(found);
                                if (!this.application.isDemoMode()) {
                                    found.energy_ws = found.energy_ws / 3600000;
                                    found.cost = found.cost.toFixed(2);
                                } else {
                                    found.energy_ws = (applianceCategory.usage / 3).toFixed(2);
                                    found.cost = (applianceCategory.cost / 3).toFixed(2);
                                }
                                found.icon = appliance.icon;
                            }
                        }
                    }
                }
                applianceCategoryElement.description = text;

                if (applianceCategory.usage > 0) {
                    this.applianceCategories.push(applianceCategoryElement);
                    series.push({
                        name: applianceCategory.name,
                        y: Math.round(applianceCategory.usage),
                        x: applianceCategory.cost.toLocaleString(),
                        color: null,
                        sliced: true
                    });
                }
            }

            // assign colors
            let colorCounter = 0;
            for (let i = 0; i < series.length; ++i) {
                let color = this.colors[colorCounter];
                if (!color) {
                    color = this.colors.last();
                }
                series[i].color = color;
                this.applianceCategories[i].color = color;
                ++colorCounter;
            }
        } catch (e) {
            console.log('error', e);
        }
        return {series, nilm: []};
    }


    /**
     * Wrapper for creating a base appliance list element
     * @param applianceCategory - raw appliance data from API
     */
    private generateInitialApplianceListElement(applianceCategory: any):
        AppliancesDetailListElement {
        return {
            name: applianceCategory.name,
            kwh: Math.round(applianceCategory.usage).toLocaleString('de-DE'),
            cost: applianceCategory.cost.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR'
            }),
            appliances: [],
            description: 'default',
            accordionOpen: false,
            color: null,
            categoryProfileComplete: this.nilm.nilmCategoryIsComplete(applianceCategory.name)
        };
    }


    /**
     * Determine whether to show an info banner based on the last profile update reminder shown
     */
    private determineLastProfileUpdateReminderNecessary(): void {
        const showReminder = localStorage.getItem(
            StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER
        );
        const parsed = showReminder === '1';
        if (parsed) {
            this.showProfileUpdateReminder = true;
            localStorage.removeItem(StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER);
        }
    }


    /**
     * Draw diagram with new data
     * @param series
     */
    private drawDiagram(series: any): void {
        this.interactionDisabled = false;
        this.showAppliances = this.applianceCategories.length !== 0;
        this.applianceChart.addSeries({
            name: 'Series',
            data: series,
            type: 'pie'
        });
    }


    /**
     * Request category data for available appliances
     * @param disaggregationData
     */
    private requestAppliancesCategoryData(disaggregationData): Observable<any> {
        const t = moment().subtract(this.offsetMonths, 'year');
        const dateStart = moment(t).month(0).toDate();
        const dateEnd = moment(t).month(11).toDate();
        return this.consumptionService.getConsumptionForMonths(dateStart, dateEnd).pipe(
            mergeMap(response => {
                const aligned = {};
                for (const monthlyDataset of response.appliances) {
                    const currentElement = monthlyDataset.energy_per_appliance;
                    for (const appliance of currentElement) {
                        const id = appliance.appliance_instance_id;
                        const cost = appliance.cost;
                        const energy_ws = appliance.energy_ws;
                        if (!aligned[id]) {
                            aligned[id] = {cost, energy_ws};
                            continue;
                        }
                        aligned[appliance.appliance_instance_id].cost += cost;
                        aligned[appliance.appliance_instance_id].energy_ws += energy_ws;
                    }
                }
                const mapped = Object.keys(aligned).map(el => {
                        return {
                            appliance_instance_id: el,
                            energy_ws: aligned[el].energy_ws,
                            cost: aligned[el].cost
                        };
                    }
                );
                return of({appliances: [{energy_per_appliance: mapped}]});
            }),
            map(mappedData => {
                return {appliances: disaggregationData, consumptions: mappedData};
            })
        );
    }


    /*
     * TRACKING
     * =============================================================================================
     */
    private triggerModeTrackingEvent(): void {
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: Meine Geräte - ' +
                    (this.mode === AppliancesDetailMode.YEAR ? 'Jahresübersicht' : 'Monatsübersicht') +
                    '; previous_screen: Meine Geräte - ' +
                    (this.mode === AppliancesDetailMode.MONTH ? 'Monatsübersicht' : 'Jahresübersicht')
            }
        });
    }
}


enum AppliancesDetailMode {
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

interface AppliancesDetailListElement {
    name: string;
    kwh: string;
    cost: string;
    appliances: ApplianceDetailListItem[];
    description: string;
    accordionOpen: boolean;
    color: string | null;
    categoryProfileComplete: boolean;
}

interface ApplianceDetailListItem {
    energy_ws: number;
    appliance_instance_id: string;
    cost: string;
    icon: string;
}
