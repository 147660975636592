<div class="overlay">
    <div class="detail-overlay" style="overflow: hidden">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">Vergleich</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Vergleich"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="info-icon"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">Vergleich: Was ist das?</h2>
                    <p>
                        iONA liebt Statistiken. Vergleichen Sie doch einfach mal Ihren Energieverbrauch mit dem Vortag,
                        der Vorwoche, dem Vormonat oder dem Vorjahr. iONA vergleicht Ihnen alles - nur nicht Äpfel mit
                        Birnen.
                    </p>
                </div>
            </section>

            <section class="tariff-warning" *ngIf="isErnaUser && !tariffsAvailable">
                <div class="heading" (click)="toggleWarning()">
                    <div class="collapse">
                        <div class="title"> Keine Tarifdaten angegeben</div>
                        <i [class]="warningCollapsed ? 'collapsed' : 'expand'"></i>
                    </div>
                </div>
                <ng-container *ngIf="!warningCollapsed">
                    <div class="content-wrapper">
                        <div class="content">
                            <div>
                                <p>Die Werte werden aktuell anhand eines Beispieltarifs berechnet.</p>
                                <button class="iona-icon-button-after internal-link white" (click)="routeToProfile()">
                                    Meine Daten
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </section>

            <div class="tab-bar">
                <div class="cursor-pointer"
                     [class.active]="currentMode === comparisonMode.STATIC"
                     (click)="setMode(comparisonMode.STATIC)">
                    Statisch
                </div>
                <div class="cursor-pointer"
                     [class.active]="currentMode === comparisonMode.DYNAMIC"
                     (click)="setMode(comparisonMode.DYNAMIC)">
                    Anpassbar
                </div>
                <div class="grow-space"></div>
            </div>

            <section class="detail-content">
                <div class="upper-timeframe-select">
                    <div *ngFor="let s of seriesLegend" class="center-contents legend-item">
                        <span class="color-dot" [style.background]="s.color"></span> {{s.name}}
                    </div>

                    <select class="iona-select small" (change)="setValueMode($event.target.value)">
                        <option value="consumption"
                                [selected]="currentDisplayMode === comparisonDisplayMode.CONSUMPTION">
                            Verbrauch
                        </option>
                        <option value="cost"
                                [selected]="currentDisplayMode === comparisonDisplayMode.COST">
                            Kosten
                        </option>
                        <ng-container *ngIf="!userService.isBoxUser()">
                            <ng-container
                                *ngIf="!application.isDemoMode() && currentDataMode !== comparisonDataMode.HAPPY_HOUR">
                                <option [value]="comparisonDisplayMode.FEEDIN"
                                        [selected]="currentDisplayMode === comparisonDisplayMode.FEEDIN">
                                    Einspeisung
                                </option>
                            </ng-container>
                        </ng-container>
                    </select>
                    <div class="p-h-s"></div>
                    <select class="iona-select small" (change)="setTimeframe($event.target.value)"
                            angulartics2On="change"
                            angularticsCategory="Detailansichten"
                            angularticsAction="detail_time_interval_change"
                            angularticsLabel="Vergleich - {{ currentMode === comparisonMode.STATIC ? 'Statisch' : 'Anpassbar' }}">
                        <option [value]="comparisonTimeframe.DAY">Tag</option>
                        <option [value]="comparisonTimeframe.WEEK">Woche</option>
                        <option [value]="comparisonTimeframe.MONTH" selected>Monat</option>
                        <option [value]="comparisonTimeframe.YEAR">Jahr</option>
                    </select>

                    <ng-container *ngIf="userHasHappyHour && currentDisplayMode !== comparisonDisplayMode.FEEDIN">
                        <div class="switch">
                            <div class="center-contents"
                                 [ngClass]="{'active' : currentDataMode === comparisonDataMode.REGULAR}"
                                 (click)="setDataMode(comparisonDataMode.REGULAR)">
                                Gesamt
                            </div>
                            <div class="center-contents"
                                 [ngClass]="{'active' : currentDataMode !== comparisonDataMode.REGULAR}"
                                 (click)="setDataMode(comparisonDataMode.HAPPY_HOUR)">
                                Happy Hour
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div [chart]="chart"></div>

                <div class="diagram-controls" [ngSwitch]="currentMode">
                    <div *ngSwitchCase="comparisonMode.STATIC" class="comparison-static-diagram-controls">
                        <div>
                            <button class="iona-icon-only-button prev"
                                    [disabled]="disabled"
                                    (click)="positionBack()">
                            </button>
                        </div>
                        <div class="base-price-display">
                            <div *ngIf="currentDisplayMode === comparisonDisplayMode.COST">
                                Kosten zzgl. Grundpreis: <strong>{{determineCurrentBasePrice()}}</strong>
                            </div>
                        </div>
                        <div class="right-controls">
                            <button class="iona-icon-only-button next"
                                    [disabled]="disabled || position === 1"
                                    (click)="positionForward()">
                            </button>
                            <div class="m-h-s"></div>
                            <div class="button-constraint-container">
                                <button class="iona-button small outline"
                                        [disabled]="disabled || position === 1"
                                        (click)="positionNow()">
                                    Jetzt
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngSwitchCase="comparisonMode.DYNAMIC">
                        <div class="comparison-dynamic-diagram-controls">
                            <div>
                                <select *ngIf="currentTimeframe === comparisonTimeframe.DAY"
                                        class="iona-select small" id="left_day"
                                        (change)="setCompare('left_day', $event.target.value)">
                                    <option *ngFor="let day of loop(1, 31)" value="{{ day }}"
                                            [selected]="comparisonDates.left_day === day">{{ day }}</option>
                                </select>
                                <select *ngIf="currentTimeframe === comparisonTimeframe.WEEK"
                                        class="iona-select small" id="left_kw"
                                        (change)="setCompare('left_kw', $event.target.value)">
                                    <option *ngFor="let kw of loop(1, 52)" value="{{ kw }}"
                                            [selected]="comparisonDates.left_kw === kw">
                                        KW {{ kw }}</option>
                                </select>
                                <select
                                    *ngIf="(currentTimeframe === comparisonTimeframe.DAY) || (currentTimeframe === comparisonTimeframe.MONTH)"
                                    class="iona-select small" id="left_month"
                                    (change)="setCompare('left_month', $event.target.value)">
                                    <option *ngFor="let month of validMonths; let i = index" value="{{ i + 1 }}"
                                            [selected]="comparisonDates.left_month === (i + 1)">{{ month }}</option>
                                </select>
                                <select class="iona-select small" id="left_year"
                                        (change)="setCompare('left_year', $event.target.value)">
                                    <option *ngFor="let year of loop(year - 4, 5)" value="{{ year }}"
                                            [selected]="comparisonDates.left_year === year">{{ year }}</option>
                                </select>
                            </div>

                            <div>
                                <select *ngIf="currentTimeframe === comparisonTimeframe.DAY"
                                        class="iona-select small" id="right_day"
                                        (change)="setCompare('right_day', $event.target.value)">
                                    <option *ngFor="let day of loop(1, 31)" value="{{ day }}"
                                            [selected]="comparisonDates.right_day === day">{{ day }}</option>
                                </select>
                                <!--                            <label for="right_kw">Kalenderwoche</label>-->
                                <select *ngIf="currentTimeframe === comparisonTimeframe.WEEK"
                                        class="iona-select small" id="right_kw"
                                        (change)="setCompare('right_kw', $event.target.value)">
                                    <option *ngFor="let kw of loop(1, 52)" value="{{ kw }}"
                                            [selected]="comparisonDates.right_kw === kw">
                                        KW {{ kw }}</option>
                                </select>
                                <!--                            <label for="right_month">Monat</label>-->
                                <select
                                    *ngIf="(currentTimeframe === comparisonTimeframe.DAY) || (currentTimeframe === comparisonTimeframe.MONTH)"
                                    class="iona-select small" id="right_month"
                                    (change)="setCompare('right_month', $event.target.value)">
                                    <option *ngFor="let month of validMonths; let i = index" value="{{ i + 1 }}"
                                            [selected]="comparisonDates.right_month === (i + 1)">{{ month }}</option>
                                </select>
                                <!--                            <label for="right_year">Jahr</label>-->
                                <select class="iona-select small" id="right_year"
                                        (change)="setCompare('right_year', $event.target.value)">
                                    <option *ngFor="let year of loop(year - 4, 5)" value="{{ year }}"
                                            [selected]="comparisonDates.right_year === year">{{ year }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="base-price-display">
                            <div *ngIf="currentDisplayMode === comparisonDisplayMode.COST">
                                Kosten zzgl. Grundpreis: <strong>{{determineCurrentBasePrice()}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>

