<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Zählerstand; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading ">
                {{TEXTS.TITLE}}
            </h3>
            <div class="tile-content col">

                <div class="meter-data">
                    <div class="meter-value center-contents font-regular">
                        <div> {{meterStats.kwh}} kWh</div>
                    </div>

                    <div *ngIf="meterStats.id && meterStats.id.length > 0"
                         class="col-centered meter-info">
                        <div class="label font-light">
                            {{TEXTS.LABEL_METER_ID}}
                        </div>
                        <div class="meter-id font-medium">
                            {{meterStats.id}}
                        </div>
                    </div>
                </div>

                <div class="meter-connection">
                    <div class="connection-container">
                        <div class="p-r-s">
                            <app-connection-indicator [value]="connection_quality"
                                                      container_height="24px"
                                                      [disabled]="meter_status!== 'connected'"
                                                      [invert_colors]="false"
                                                      [config]="connectionConfig">
                            </app-connection-indicator>

                        </div>
                        <h4 class="connection-text font-light">
                            <!--                                                        [ngClass]="{connected: meter_status === 'connected', disconnected: meter_status !== 'connected'}"-->
                            {{determineMeterStatusMessage()}}
                        </h4>
                    </div>
                </div>

            </div>

        </div>

    </div>

</div>
