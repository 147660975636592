<div id="container">
    <app-menu activeElement="help"></app-menu>
    <div id="inner">
        <main class="page">
            <div class="page-card">
                <iframe #frame class="help-iframe"
                (load)="onFrameLoaded()"></iframe>
                <!--                <h1>{{TEXTS.TITLE}}</h1>-->
                <!--                <h2>{{TEXTS.SUBTITLE}}</h2>-->
                <!--                <section>-->
                <!--                    <h3>{{TEXTS.SECTION_TITLE_PHONE}}</h3>-->
                <!--                    <p>{{TEXTS.SECTION_DESCRIPTION_PHONE}}</p>-->
                <!--                    <button class="iona-button orange" (click)="openPhone()">{{phone}}</button>-->
                <!--                </section>-->
                <!--                <section>-->
                <!--                    <h3>{{TEXTS.SECTION_TITLE_FAX}}</h3>-->
                <!--                    <p>{{TEXTS.SECTION_DESCRIPTION_FAX}}</p>-->
                <!--                    <button class="iona-button orange" (click)="openFax()">{{fax}}</button>-->
                <!--                </section>-->
                <!--                <section>-->
                <!--                    <h3>{{TEXTS.SECTION_TITLE_MAIL}}</h3>-->
                <!--                    <p>{{TEXTS.SECTION_DESCRIPTION_MAIL}}</p>-->
                <!--                    <button class="iona-button orange" (click)="openMail()">{{TEXTS.BUTTON_EMAIL_CONTACT}}</button>-->
                <!--                </section>-->
            </div>
        </main>
    </div>
</div>
