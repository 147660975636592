import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import * as moment from 'moment';
import {ApplicationService} from './application.service';
import {UserService} from './user.service';
import {MetaResponse} from '../shared/interfaces/meta-response';

@Injectable({
    providedIn: 'root'
})
export class InstantaneousService extends BaseService {
    onInstantaneousUpdate = new Subject<any>();
    onConsumptionAlertDataUpdate = new BehaviorSubject<MetaResponse>(null);

    private timerSub: Subscription = null;
    private consumptionAlertTimerSub: Subscription = null;

    private updateRate = 10000;
    private latestConsumptionAlertData = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
        if (this.consumptionAlertTimerSub) {
            this.consumptionAlertTimerSub.unsubscribe();
            delete this.consumptionAlertTimerSub;
        }
    }


    /**
     * Start cyclic consumption alert value update
     * @param maxOffset maximum offset to fetch the data from
     */
    startConsumptionAlertValueLiveUpdate(maxOffset): void {
        if (this.consumptionAlertTimerSub) {
            return;
        }
        const now = moment();
        const to = moment(now)
            .add(5, 'minutes')
            .toDate().toUTCString().toString();
        const from = moment(now)
            .subtract(maxOffset + 1, 'days')
            .toDate().toUTCString().toString();
        this.consumptionAlertTimerSub = timer(0, this.updateRate).pipe(
            mergeMap(() => this.requestDataForTimeframe(from, to))
        ).subscribe(
            (res: MetaResponse) => {
                this.onConsumptionAlertDataUpdate.next(res);
            }
        );
    }

    getLatestConsumptionAlertData(): void {
        if (this.latestConsumptionAlertData) {
            this.onConsumptionAlertDataUpdate.next(this.latestConsumptionAlertData);
        }
    }


    /**
     * Oh boy can I delete this please?
     * @param start
     * @param end
     * @param ignoreError
     */
    getPhase(start: Date, end: Date, ignoreError = false): Observable<any> {
        const s = moment(start).format('YYYY-MM-DD');
        const e = moment(end).format('YYYY-MM-DD');
        const suffix = `/${s}/${e}`;

        let url = this.API_BASE_URL + constants.api.routes.phase + suffix;
        if (start === null && end === null) {
            url = `assets/data/demo/${constants.demo.files.instantaneous_Phases}.json`;
        }

        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                if (!this.responseValid(res)) {
                    console.log('Response invalid', res);
                    return of(null);

                }
                const r = res['data'];
                if ('L1' in r && 'L2' in r && 'L3' in r) {
                    return r;
                }
                return null;
            }),
            catchError((error: HttpErrorResponse) => {
                if (ignoreError) {
                    return of(
                        {
                            total: {power_min: 0, power_max: 0, power_avg: 0},
                            L1: {power_min: 0, power_max: 0, power_avg: 0},
                            L2: {power_min: 0, power_max: 0, power_avg: 0},
                            L3: {power_min: 0, power_max: 0, power_avg: 0}
                        }
                    );
                }
                return this.handleError(error);
            })
        );
    }


    /**
     * Request instantaneous data for a specific timeframe
     * @param start start timestamp
     * @param end end timestamp
     */
    private requestDataForTimeframe(start: string, end: string): Observable<MetaResponse> {
        let url = this.API_BASE_URL + constants.api.routes.instantaneousPower +
            `/${start}/${end}/900/max`;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.instantaneousPower}.json`;
        }
        return this.http.get(url).pipe(
            // tap(data => console.log('data after call', data)),
            mergeMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            mergeMap((mappedResponse: any) => {
                if (!this.application.isDemoMode()) {
                    if (!mappedResponse) {
                        return this.mapToMetaResponse(
                            mappedResponse,
                            true
                        );
                    }
                    this.latestConsumptionAlertData = mappedResponse;
                    return this.mapToMetaResponse(
                        mappedResponse,
                        false
                    );
                }
                const data = this.alignConsumptionAlertPowerDataForDemoMode(mappedResponse);
                return this.mapToMetaResponse(
                    data,
                    false
                );
            }),
            catchError(error => {
                return this.mapToMetaResponse(error, true);
            })
        );
    }


    /**
     * Send Instantaneous base request
     */
    private requestInstantaneous(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.instantaneous;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.instantaneous}.json`;
        }
        return this.http.get(url).pipe(
            mergeMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            mergeMap((mapped: any) => {
                if (this.validateInstantaneousResponse(mapped)) {
                    return of(mapped);
                }
                return throwError(
                    {msg: 'Error validating instantaneous response after initial mapping'});
            }),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Mapping function for demo mode
     * @param res
     */
    private alignConsumptionAlertPowerDataForDemoMode(res): any {
        const now = moment();
        const nearest = Math.floor(now.minutes() / 15) * 15;
        const tsStart = moment(now).minutes(nearest).second(0).millisecond(0);
        const reversed = res.results.slice().reverse();
        reversed.forEach((element, idx) => {
            const newTimestamp = moment(tsStart).subtract(idx * 15, 'minutes');
            element.timestamp = newTimestamp.toDate();
        });
        const results = reversed.slice().reverse();
        return {results};
    }


    private validateInstantaneousResponse(mappedResponse: any): boolean {
        if ('electricity' in mappedResponse) {
            if ('current_summation' in mappedResponse.electricity) {
                return true;
            }
        }
        return false;
    }

}
