<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">Geräte</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
            </section>

            <!-- INFO TEXT -->
            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">Geräte: Was ist das?</h2>
                    <p>
                        Gibt es einen Stromfresser in Ihrem Haushalt? Finden Sie es mit iONA heraus. Erkennen Sie, wie
                        viel Ihre einzelnen Geräte verbrauchen und welche Kosten dabei entstehen. Wagen Sie einen Blick
                        in die Vergangenheit mit der zweiten Detailansicht.
                    </p>
                    <p>
                        Der Algorithmus nutzt die angegebenen Profil- und Geräteinformationen, um im Gesamtverbrauch die
                        typischen Verläufe einzelner Verbraucher zu identifizieren. Insbesondere größere Verbraucher,
                        wie Waschmaschinen, Trockner, Backöfen oder Spülmaschinen können sehr gut identifiziert werden.
                        Darüber hinaus werden statistische Daten herangezogen, um den Stromverbrauch einzelner
                        Kategorien zu ermitteln.
                    </p>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <section class="profile-update-reminder" *ngIf="showProfileUpdateReminder && profileUpdateReminderVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="banner-content">
                    <p>
                        Bitte prüfen Sie Ihr Haushaltsprofil auf Aktualität.
                    </p>
                    <button class="iona-button white"
                            (click)="routeToProfile()">
                        Mein Haushaltsprofil prüfen
                        <i class="chevron-right-s"></i>
                    </button>
                </div>
                <div class="close">
                    <button id="hide-button" class="iona-icon-only-button close" (click)="hideReminder()"></button>
                </div>
            </section>

            <!-- TAB BAR -->
            <div class="tab-bar">
                <div class="cursor-pointer"
                     [class.active]="mode === ComponentModes.MONTH"
                     (click)="setMode(ComponentModes.MONTH)">
                    Monatsübersicht
                </div>
                <div class="cursor-pointer"
                     [class.active]="mode === ComponentModes.YEAR"
                     (click)="setMode(ComponentModes.YEAR)">
                    Jahresübersicht
                </div>
                <div class="grow-space"></div>
            </div>

            <section class="detail-content">
                <div class="diagram-navigation">
                    <button class="iona-icon-only-button prev"
                            (click)="positionBack()" [disabled]="interactionDisabled">
                    </button>
                    <div class="current-timeframe">{{date}}</div>
                    <button class="iona-icon-only-button next"
                            (click)="positionForward()" [disabled]="interactionDisabled || offsetMonths === 0">
                    </button>
                </div>

                <div class="appliances-details-diagram-container">
                    <div class="appliance-diagram-new">
                        <app-appliance-chart #applianceChart
                                             [isDetail]="true">
                        </app-appliance-chart>
                    </div>
                </div>

                <ul class="appliances-list">
                    <li *ngFor="let category of applianceCategories" [style.border-color]="category.color"
                        (click)="toggleAccordion(category)">
                        <div class="li-content-wrapper" [style.background]="category.color">
                            <div class="list-icon-wrapper">
                                <div class="list-icon"
                                     [ngStyle]="{
                                         'mask-image': 'url(/assets/img/graphics/appliances/'+ category.name.toLowerCase() + '.png)',
                                        '-webkit-mask-image': 'url(/assets/img/graphics/appliances/'+ category.name.toLowerCase() + '.png)',
                                        'background-color': '#fff'
                                     }">
                                </div>
                                <div *ngIf="!category.categoryProfileComplete"
                                     class="attention-indicator">
                                </div>
                            </div>
                            <div class="grow-space font-bold">
                                {{ translateAppliance(category.name) }}
                            </div>
                            <div class="font-bold">{{ category.kwh }} kWh</div>
                            <div class="cost font-bold">{{ category.cost }}</div>
                            <div class="accordion-indicator">
                                <i [ngClass]="{rotated: category.accordionOpen}"></i>
                            </div>
                        </div>
                        <!-- ACCORDION CONTENT-->
                        <div class="accordion" [hidden]="!category.accordionOpen">
                            <div class="category-list">
                                <div *ngFor="let item of category.appliances">
                                    <div class="sub-icon"
                                         [style.mask-image]="determineApplianceCategoryItemPath(item.icon)"
                                         [style.-webkit-mask-image]="determineApplianceCategoryItemPath(item.icon)">
                                    </div>
                                    <div class="sub-name font-bold">{{translateAppliance(item.appliance_instance_id)}}</div>
                                    <div class="sub-value"> kWh <br> {{item.energy_ws}} </div>
                                    <div class="sub-value"> € <br> {{item.cost}} </div>
                                    <div class="spacer"></div>
                                </div>
                            </div>
                            <p>
                                {{category.description}}
                            </p>
<!--                            <div class="boeppel"></div>-->
                        </div>
                    </li>
                </ul>

            </section>

        </div>
    </div>
</div>
