<div class="overlay">
    <div class="info-view-main">
        <h1>iONA wird E.ON Smart Control!</h1>
        <div class="banner-image">
            <img src="/assets/images/iona_goes_esc.svg" alt="">
        </div>
        <p>iONA bekommt ein neues Gewand - die Funktionen bleiben wie gewohnt</p>
        <p>Hierzu ist es notwendig, die neue E.ON Smart Control WebApp zu verwenden.</p>
        <div class="esc-links">
            <div>
                <button class="eon-button" (click)="openESC()">E.ON Smart Control</button>
            </div>
            <div>
                <a href="https://smartcontrol.eon.de" target="_blank">https://smartcontrol.eon.de</a>
            </div>
        </div>
        <p>
            Anschließend kann diese mit den bekannten Benutzerdaten geöffnet werden. <br>
            <ng-container *ngIf="showContinue">
                Die iONA WebApp können Sie noch bis zum 19.01.2021 weiter verwenden.
            </ng-container>
        </p>
        <button *ngIf="showContinue" class="continue-button" (click)="close()">Weiter</button>
    </div>
</div>
